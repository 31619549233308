import { Component, OnInit, OnDestroy } from '@angular/core';
import { take } from 'rxjs';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';

import { User } from 'src/app/utilities/models/user/user';
import { UsersService } from 'src/app/services/users.service';
import { UploadsService } from 'src/app/services/uploads.service';

import { NotificationPreferencesComponent } from 'src/app/components/mainApp/profile/notificationPreferences/notificationPreferences.component';
import { TwoFactorAuthenticationComponent } from 'src/app/components/mainApp/profile/twoFactorAuthentication/twoFactorAuthentication.component';

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ['./profile.component.scss'],
    standalone: false
})
export class ProfileComponent implements OnInit, OnDestroy {
  isUploading = false;
  fileList: NzUploadFile[] = [];
  notificationsModal: NzModalRef;
  tfaModal: NzModalRef;
  user: User;

  constructor(private modal: NzModalService,
              private msg: NzMessageService,
              private userService: UsersService,
              private uploadsService: UploadsService) {}

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    this.userService.userSubject.subscribe(user => {
      if (user?.id) {
        this.user = user;
      }
    })
  }

  ngOnDestroy() {
    if(this.tfaModal) this.tfaModal.destroy();
  }

  openManageNotificationsModal() {
    this.notificationsModal = this.modal.create({
      nzClassName: 'profile-notifications-modal',
      nzContent: NotificationPreferencesComponent,
      nzFooter: null,
      nzCentered: true,
      nzWidth: '640px',
    });
  }

  openManage2FAModal() {
    this.tfaModal = this.modal.create({
      nzClassName: 'profile-2fa-modal',
      nzContent: TwoFactorAuthenticationComponent,
      nzFooter: null,
      nzCentered: true,
      nzWidth: '640px',
    });
  }

  onBeforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.handleUpload();

    return false;
  };

  private handleUpload() {
    if (this.fileList.length) {
      this.isUploading = true;
      const payload = this.prepareUploadPayload();

      this.uploadsService.create(payload)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isUploading = false;
            this.fileList = [];
            this.userService.getCurrentUser();
            this.msg.success('Avatar was uploaded successfully', { nzDuration: 5000, nzPauseOnHover: true });
          },
          error: (error) => {
            this.msg.success('There was an error, please try again', { nzDuration: 5000, nzPauseOnHover: true });
            this.isUploading = false;
          },
        });
      }
  }

  private prepareUploadPayload(){
    const payload = new FormData();

    payload.append('used_for', 'avatar');
    payload.append('uploadable_type', this.userService.loggedInUser.attributes.type);
    payload.append('uploadable_id', this.userService.loggedInUser.id.toString());

    // this WILL NOT work: formData.append('uploaded_file', this.fileList[0]);
    this.fileList.forEach((file: any) => {
      payload.append('uploaded_file', file);
    });

    return payload;
  }
}
