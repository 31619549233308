import { Component, computed, Input, signal } from '@angular/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { User } from 'src/app/utilities/models/user/user';

@Component({
    selector: 'app-avatar',
    imports: [NzAvatarModule],
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
  @Input() user: any;
  @Input() size: number = 32;

  _user = signal<any>(null);

  ngOnInit() {
    this._user.set(this.user);
  }

  userInitials = computed((): string => {
    if (this?.user instanceof User) {
      return this.user.initials();
    } else {
      return (this.user?.attributes?.firstname?.charAt(0)?.toUpperCase() + this._user()?.attributes?.lastname?.charAt(0)?.toUpperCase()) || 'N/A';
    }
  });
}
