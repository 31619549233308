import { Component, DestroyRef, OnInit } from '@angular/core';
import { UsersService } from "src/app/services/users.service";
import { LoaderService } from "src/app/services/loader.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { User } from "src/app/utilities/models/user/user";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Invoice } from "src/app/utilities/models/invoice/invoice";
import { InvoicesService } from "src/app/services/invoices.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ChangeRequestCreateDto } from "../../../../utilities/models/dto/changeRequestCreateDto";

@Component({
    selector: 'app-change-request',
    templateUrl: './invoice-support-request.component.html',
    styleUrls: ['./invoice-support-request.component.scss'],
    standalone: false
})
export class InvoiceSupportRequestComponent implements OnInit {
  loggedInUser: User;
  permission: Permission | undefined;
  editorOptions: Object;
  invoiceRequestForm: FormGroup;
  loaderVisible: boolean = false;
  invoice: Invoice;

  constructor(private userService: UsersService,
              private loaderService: LoaderService,
              private invoiceService: InvoicesService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.editorOptions = {
      heightMin: 200,
      heightMax: 500
    };
    this.loggedInUser = this.userService.loggedInUser;
    this.permission = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');
    this.invoiceRequestForm = this.getFormGroup();
    this.setForm();

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      });

    this.activateRoute.params
      .subscribe({
          next: (params: Params) => {
            if(params['id'] && params['id'] > 0) {
              this.get_invoice(params['id']);
            }
          }
        }
      );
  }

  onSubmit(){
    if(!this.invoiceRequestForm.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your ticket is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your ticket was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: ChangeRequestCreateDto = this.preparePayload();
    this.invoiceService.billingQuestion(payload).subscribe({
      next: (response: any) => {
        this.invoiceRequestForm.reset();
        this.loaderService.setProcessing(false);
        if(response.data.attributes.code){
          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
            this.router.navigate(['/app','support','tickets',response.data.attributes.code]).then( _ => console.log(''));
          }, 2000)
        }
      }
    });
  }

  getFormGroup(): FormGroup {
    return new FormGroup({
      'subject': new FormControl<string>({ value: '', disabled: this.disableByProperty('subject') }, Validators.required),
      'description': new FormControl<string>({ value: '', disabled: this.disableByProperty('description') }, Validators.required),
      'account': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('account_id') }, Validators.required),
      'requester': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('requester_id') }, Validators.required),
    });
  }

  private setForm(){
    if(this.loggedInUser){
      this.invoiceRequestForm.get('requester')?.setValue(this.loggedInUser.id);
      if(this.loggedInUser.relationships?.account){
        const accountId = this.loggedInUser.relationships.account.id;
        this.invoiceRequestForm.get('account')?.setValue(accountId);
      }
    }
  }

  disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private preparePayload(): ChangeRequestCreateDto{
    const ticketCreateAttributes = {
      subject: this.invoiceRequestForm.get('subject')?.value,
      description: this.invoiceRequestForm.get('description')?.value,
    }

    return new ChangeRequestCreateDto(ticketCreateAttributes);
  }

  private get_invoice(id: number) {
    this.invoiceService.get_invoice(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.invoice = new Invoice(response.data, response.included);
          this.invoiceRequestForm.get('subject')?.setValue('Billing Support Request for Invoice: ' + this.invoice?.attributes?.uniq_number);
        },
      })
  }
}
