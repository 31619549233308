import { Component, OnInit } from "@angular/core";
import { PostsService } from "src/app/services/posts.service";
import { UsersService } from "src/app/services/users.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { UploadsService } from "src/app/services/uploads.service";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { User } from "src/app/utilities/models/user/user";
import { Post } from "src/app/utilities/models/post/post";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { take } from "rxjs";
import { PostCreateDto, PostCreateDtoAttributes, PostCreateDtoRelationships } from "src/app/utilities/models/dto/postCreateDto";
import { Router } from "@angular/router";
import { Upload } from "src/app/utilities/models/upload/upload";

@Component({
    selector: 'app-new-post',
    templateUrl: './newPost.component.html',
    styleUrls: ['./newPost.component.scss'],
    standalone: false
})
export class NewPostComponent implements OnInit {
  isUploading = false;
  fileList: NzUploadFile[] = [];
  user: User;
  post: Post;
  permission: Permission | undefined;
  postForm: FormGroup;
  loading: boolean = false;
  upload: Upload;

  constructor(private postService: PostsService,
              private userService: UsersService,
              private uploadService: UploadsService,
              private router: Router,
              private msg: NzMessageService) {}

  ngOnInit() {
    this.permission = this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'create');
    this.initForm();
  }

  private initForm(){
    this.postForm = new FormGroup({
      'title': new FormControl<string>({ value: '', disabled: this.disableByProperty('title') }, Validators.required ),
      'description': new FormControl<string>({ value: '', disabled: this.disableByProperty('description') }, Validators.required),
      'content': new FormControl<string>({ value: '', disabled: this.disableByProperty('content') }, Validators.required),
      'sticky': new FormControl<boolean | undefined>({ value: undefined, disabled: this.disableByProperty('sticky') }, Validators.required),
    })
  }

  private disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  onSubmit() {
    this.loading = true;
    const payload: PostCreateDto = this.prepareCreatePayload();

    this.postService.createPost(payload)
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.router.navigate(['/app/news/'+response.data.id]).then( _ => console.log('redirect to logins'));
        },
        error:(err) => {
          this.loading = false;
          this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
        }
      });

    return true;
  }

  private prepareCreatePayload() {
    const postCreateAttributes:PostCreateDtoAttributes = {
      title: this.postForm.get('title')?.value,
      description: this.postForm.get('description')?.value,
      content: this.postForm.get('content')?.value,
      sticky: this.postForm.get('sticky')?.value
    };
    const accountId = this.userService.loggedInUser.relationships?.account?.id || 0;
    const authorId = this.userService.loggedInUser.id;
    const postCreateRelationships: PostCreateDtoRelationships = {
      author: this.prepareData("users", authorId),
      account: this.prepareData( "accounts", accountId)
    }
    if(this.upload?.id){
      postCreateRelationships.uploads = {
        data: [{
          id: this.upload.id,
          type: "uploads"
        }]
      }
    }

    return new PostCreateDto('posts', postCreateAttributes, postCreateRelationships);
  }

  private prepareData(type: string, valueId: number){
    return { data: { id: valueId, type: type }};
  }

  onBeforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.handleUpload();

    return false;
  };

  private handleUpload() {
    if (this.fileList.length) {
      this.isUploading = true;
      const payload = this.prepareUploadPayload();

      this.uploadService.create(payload).pipe(take(1))
        .subscribe({
          next: (response: any) => {
            this.fileList = [];
            this.msg.success('Image was uploaded successfully', { nzDuration: 5000, nzPauseOnHover: true });
            this.upload = new Upload(response.data, response.included);
          },
          error: () => {
            this.msg.success('There was an error, please try again', { nzDuration: 5000, nzPauseOnHover: true });
            this.isUploading = false;
          },
          complete: () => {
            this.isUploading = false;
          }
        });
    }
  }

  private prepareUploadPayload(){
    const payload = new FormData();

    payload.append('used_for', 'featured_image');
    payload.append('uploadable_type', 'Digcore::Post');
    // payload.append('uploadable_id', this.post.id.toString());

    // this WILL NOT work: formData.append('uploaded_file', this.fileList[0]);
    this.fileList.forEach((file: any) => {
      payload.append('uploaded_file', file);
    });

    return payload;
  }
}
