export const environment = {
  production: true,
  local: false,
  api_url: 'https://emp-api.deskware.com/',
  integration_api_url: 'https://integrations-api.empist360.com/',
  local_url: 'https://empist360.com',
  oauth_client_id: '0NuLxOVaGf4r1TdGTPyPtTrIm-DaJQtSi9suwcJGBro',
  oauth_tenant: 'empist',
  oauth_url: 'https://emp-auth.deskware.com/',
  enableAppcues: true,
  appcuesAccountId: '49055',
};
