import { Component } from '@angular/core';
import { AuthService } from "src/app/auth/auth.service";

@Component({
    selector: 'app-reset-password-set-mobile',
    templateUrl: './resetPasswordSetMobile.component.html',
    styleUrls: ['./resetPasswordSetMobile.component.scss'],
    standalone: false
})
export class ResetPasswordSetMobileComponent {
  constructor(private authService: AuthService) {
  }

  login(): void {
    this.authService.login();
  }
}