import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";
import { AuthComponent } from "./components/auth/auth.component";
import { TicketComponent } from "./components/mainApp/ticket/ticket.component";
import { HomepageComponent } from "./components/homepage/homepage.component";
import { Intro } from "./components/homepage/intro/intro.component";
import { About } from "./components/homepage/about/about.component";
import { Solution } from "./components/homepage/solution/solution.component";
import { InstantSupport } from "./components/homepage/instant-support/instantSupport.component";
import { AuthGuard } from "./auth.guard";
import { MainAppComponent } from "./components/mainApp/mainApp.component";
import { TicketPageComponent } from "./components/mainApp/ticket/ticketPage/ticketPage.component";
import { SolutionComponent } from "./components/mainApp/solution/solution.component";
import { ProfileComponent } from "./components/mainApp/profile/profile.component";
import { DashboardComponent } from './components/mainApp/dashboard/dashboard.component';
import { SupportComponent } from './components/mainApp/support/support.component';
import { TicketNewComponent } from "./components/mainApp/ticket/ticketNew/ticketNew.component";
import { PostComponent } from "./components/mainApp/post/post.component";
import { EditPostComponent } from "./components/mainApp/post/editPost/editPost.component";
import { NewPostComponent } from "./components/mainApp/post/newPost/newPost.component";
import { PostListComponent } from "./components/mainApp/post/postList/postList.component";
import { UidComponent } from "./components/uid/uid.component";
import { AssetListComponent } from "./components/mainApp/assetList/assetList.component";
import { AssetComponent } from "./components/mainApp/asset/asset.component";
import { LoginComponent } from "./components/login/login.component";
import { InstalledServiceComponent } from "./components/mainApp/services/installedService.component";
import { InstalledServiceDetailsComponent } from "./components/mainApp/services/installedServicesDetails/installedServiceDetails.component";
import { ServicesAuditComponent } from "./components/mainApp/services-audit/services-audit.component";
import { InvoiceListComponent } from "./components/mainApp/invoice/invoice-list/invoice-list.component";
import { InvoiceComponent } from "./components/mainApp/invoice/invoice/invoice.component";
import { InvoiceSupportRequestComponent } from "./components/mainApp/invoice/invoice-support-request/invoice-support-request.component";
import { AdditionalServiceComponent } from "./components/mainApp/additional-services/additional-service/additional-service.component";
import { AdditionalServiceListComponent } from "./components/mainApp/additional-services/additional-service-list/additional-service-list.component";
import { AdditionalServiceMetricListComponent } from "./components/mainApp/additional-services/additional-service-metric-list/additional-service-metric-list.component";
import { AdditionalServiceMetricComponent } from "./components/mainApp/additional-services/additional-service-metric/additional-service-metric.component";
import { ImpersonateComponent } from "./components/mainApp/impersonate/impersonate.component";
import { DetailsRequestComponent } from "./components/mainApp/services/detailsRequest/detailsRequest.component";
import { ResetPasswordSetMobileComponent } from "./components/homepage/reset-password-set-mobile/resetPasswordSetMobile.component";
import { AddOnServiceRequestComponent } from "./components/mainApp/services/addOnServiceRquest/addOnServiceRequest.component";
import { FlowListComponent } from './components/mainApp/flows/flow-list/flow-list.component';
import { FlowComponent } from './components/mainApp/flows/flow/flow.component';
import { FlowCategoryComponent } from './components/mainApp/flows/flow-category/flow-category.component';
import { FlowCategoryListComponent } from './components/mainApp/flows/flow-category-list/flow-category-list.component';
import { FlowCategoryNewComponent } from './components/mainApp/flows/flow-category-new/flow-category-new.component';
import { FlowNewComponent } from './components/mainApp/flows/flow-new/flow-new.component';
import { AccountFlowsComponent } from './components/mainApp/flows/account-flows/account-flows.component';
import { ServicesHistoryComponent } from './components/mainApp/services-history/services-history.component';

import { AccountManagementComponent } from './components/mainApp/account-management/account-management.component';
import { AccountManagementBillingComponent } from './components/mainApp/account-management/billing/billing.component';
import { AccountManagementContractsComponent } from './components/mainApp/account-management/contracts/contracts.component';
import { AccountManagementEscalatationComponent } from './components/mainApp/account-management/escalatation/escalatation.component';
import { AccountManagementFeedbackComponent } from './components/mainApp/account-management/feedback/feedback.component';
import { AccountManagementIssueComponent } from './components/mainApp/account-management/issue/issue.component';
import { AccountManagementMeetingsComponent } from './components/mainApp/account-management/meetings/meetings.component';
import { AccountManagementReturnsComponent } from './components/mainApp/account-management/returns/returns.component';
import { AccountManagementUpgradesComponent } from './components/mainApp/account-management/upgrades/upgrades.component';

import { MeetingsSolutionComponent } from './components/mainApp/meetings/meetings.component';
import {IncidentListComponent} from "./components/mainApp/incident/incident-list/incident-list.component";
import {IncidentShowComponent} from "./components/mainApp/incident/incident-show/incident-show.component";

import { PeopleListComponent } from './components/mainApp/people/people-list/people-list.component';
import { PeopleCreateComponent } from './components/mainApp/people/people-create/people-create.component';
import { UserEditComponent } from './components/mainApp/people/people-edit/people-edit.component';

import {ChangelogListComponent} from "./components/mainApp/changelog/changelog-list/changelog-list.component";
import {ChangelogShowComponent} from "./components/mainApp/changelog/changelog-show/changelog-show.component";

const routes: Routes = [
  { path: "", redirectTo: 'info', pathMatch: "full" },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: 'reset-password-set-mobile', component: ResetPasswordSetMobileComponent },
  {
    path: "info", component: HomepageComponent, children: [
      { path: '', component: Intro },
      { path: 'instant-support', component: InstantSupport },
      { path: 'about', component: About },
      { path: 'solutions', component: Solution },
    ]
  },
  { path: "oauth", component: AuthComponent },
  {
    path: "app", component: MainAppComponent, canActivate: [AuthGuard], children: [
      { path: "", redirectTo: 'dashboard', pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
      { path: "news", component: PostListComponent, canActivate: [AuthGuard] },
      { path: "news/new", component: NewPostComponent, canActivate: [AuthGuard] },
      { path: "news/:id", component: PostComponent, canActivate: [AuthGuard] },
      { path: "news/:id/edit", component: EditPostComponent, canActivate: [AuthGuard] },

      { path: "support", component: SupportComponent, canActivate: [AuthGuard] },
      { path: "support/tickets/new", component: TicketNewComponent, canActivate: [AuthGuard] },
      { path: "support/tickets/new/:id", component: TicketNewComponent, canActivate: [AuthGuard] },
      { path: "support/tickets", component: TicketComponent, canActivate: [AuthGuard] },
      { path: "support/tickets/:id", component: TicketPageComponent, canActivate: [AuthGuard] },
      { path: "solutions", component: SolutionComponent, canActivate: [AuthGuard] },
      { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
      { path: "meetings", component: MeetingsSolutionComponent, canActivate: [AuthGuard] },

      { path: "assets", component: AssetListComponent, canActivate: [AuthGuard] },
      { path: "assets/:id", component: AssetComponent, canActivate: [AuthGuard] },

      { path: "account-management", component: AccountManagementComponent, canActivate: [AuthGuard] },
      { path: "account-management/biling-and-invoice-assistance", component: AccountManagementBillingComponent, canActivate: [AuthGuard] },
      { path: "account-management/biling-and-invoice-assistance/:invoiceId", component: AccountManagementBillingComponent, canActivate: [AuthGuard] },
      { path: "account-management/contract-management", component: AccountManagementContractsComponent, canActivate: [AuthGuard] },
      { path: "account-management/escalation", component: AccountManagementEscalatationComponent, canActivate: [AuthGuard] },
      { path: "account-management/issue-resolution-and-management", component: AccountManagementIssueComponent, canActivate: [AuthGuard] },
      { path: "account-management/meetings", component: AccountManagementMeetingsComponent, canActivate: [AuthGuard] },
      { path: "account-management/return-an-order", component: AccountManagementReturnsComponent, canActivate: [AuthGuard] },
      { path: "account-management/share-feedback", component: AccountManagementFeedbackComponent, canActivate: [AuthGuard] },
      { path: "account-management/upgrades", component: AccountManagementUpgradesComponent, canActivate: [AuthGuard] },

      { path: "services-audit", component: ServicesAuditComponent, canActivate: [AuthGuard]},
      { path: "services-change-history", component: ServicesHistoryComponent, canActivate: [AuthGuard]},
      { path: "services", component: InstalledServiceComponent, canActivate: [AuthGuard]},
      { path: "services/:id", component: InstalledServiceDetailsComponent, canActivate: [AuthGuard]},
      { path: "services/:id/:isid/details/:mid", component: InstalledServiceDetailsComponent, canActivate: [AuthGuard]},
      { path: "services/:id/request-details", component: DetailsRequestComponent, canActivate: [AuthGuard], pathMatch: "full"},
      { path: "services/:id/add-on-service-request", component: AddOnServiceRequestComponent, canActivate: [AuthGuard], pathMatch: "full"},

      { path: "additional-services", component: AdditionalServiceListComponent, canActivate: [AuthGuard]},
      { path: "additional-services/:id", component: AdditionalServiceComponent, canActivate: [AuthGuard]},
      { path: "additional-services/:id/metrics", component: AdditionalServiceMetricListComponent, canActivate: [AuthGuard]},
      { path: "additional-services/:id/metrics/:mid", component: AdditionalServiceMetricComponent, canActivate: [AuthGuard]},

      { path: "invoices", component: InvoiceListComponent, canActivate: [AuthGuard] },
      { path: "invoices/support", component: InvoiceSupportRequestComponent, canActivate: [AuthGuard] },
      { path: "invoices/:id", component: InvoiceComponent, canActivate: [AuthGuard] },
      { path: "invoices/:id/support", component: InvoiceSupportRequestComponent, canActivate: [AuthGuard] },

      { path: "flows", component: FlowListComponent, canActivate: [AuthGuard] },
      { path: "flows/account", component: AccountFlowsComponent, canActivate: [AuthGuard] },
      { path: "flows/new", component: FlowNewComponent, canActivate: [AuthGuard] },
      { path: "flows/:id", component: FlowComponent, canActivate: [AuthGuard] },
      { path: "flow-categories/account/:id", component: AccountFlowsComponent, canActivate: [AuthGuard] },
      { path: "flow-categories/account", component: AccountFlowsComponent, canActivate: [AuthGuard] },
      { path: "flow-categories", component: FlowCategoryListComponent, canActivate: [AuthGuard] },
      { path: "flow-categories/new", component: FlowCategoryNewComponent, canActivate: [AuthGuard] },
      { path: "flow-categories/:id", component: FlowCategoryComponent, canActivate: [AuthGuard] },

      { path: "incidents", component: IncidentListComponent, canActivate: [AuthGuard] },
      { path: "incidents/:id", component: IncidentShowComponent, canActivate: [AuthGuard] },

      { path: "changes", component: ChangelogListComponent, canActivate: [AuthGuard] },
      { path: "changes/:id", component: ChangelogShowComponent, canActivate: [AuthGuard] },

      { path: "people", component: PeopleListComponent, canActivate: [AuthGuard] },
      { path: "people/new", component: PeopleCreateComponent, canActivate: [AuthGuard] },
      { path: "people/:id/:type", component: UserEditComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: "uid/:base64", component: UidComponent
  },
  {
    path: "impersonate", component: ImpersonateComponent
  },
  { path: '**', redirectTo: "info" }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
