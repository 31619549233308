import { Component, DestroyRef, OnInit } from '@angular/core';
import { NavFilter } from "src/app/utilities/models/navFilters/navFilter";
import { UsersService } from "src/app/services/users.service";
import { TicketsService } from "src/app/services/tickets.service";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { BehaviorSubject, distinctUntilChanged, skip, skipWhile, switchMap } from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";
import { debounceTime } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: 'ticketList',
    host: { 'class': 'tickets-list-wrapper' },
    templateUrl: './ticketList.component.html',
    styleUrls: ['./ticketList.component.scss'],
    standalone: false
})
export class TicketListComponent implements OnInit {
  navFilter: NavFilter;
  selectedNavFilter: any;
  tickets: Ticket[] = [];
  loading = false;
  filters: ReportParam[];
  pageIndex: number = 1;
  pageSize: number = 20;
  total: number;
  totalPages: number;
  previousSelectedNavFilter: any;
  searchString: string = '';
  searchSubject$ = new BehaviorSubject<string>('');

  constructor(private userService: UsersService,
              private ticketService: TicketsService,
              private msg: NzMessageService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.previousSelectedNavFilter = this.ticketService.getPreviousSelectedNavFilter();
    this.navFilter = new NavFilter(this.userService.loggedInUser.id);
    this.selectedNavFilter = this.previousSelectedNavFilter ? this.previousSelectedNavFilter : 2;
    this.navFilterChange(this.pageSize, this.pageIndex);
    this.setSearchSubject();
  }

  navFilterChange(pageSize: number, pageIndex: number) {
    this.loading = true;
    this.filters = this.navFilter.filters(this.selectedNavFilter, this.userService.loggedInUser.id);
    pageSize = pageSize ? pageSize : 20;
    pageIndex = pageIndex ? pageIndex : 1;
    if(pageSize*pageIndex > 10000) {
      this.loading = false;
      this.msg.error('The request cannot be completed as the request limit exceeds the 10000 records', { nzDuration: 3000, nzPauseOnHover: false });
      return false;
    }

    return this.ticketService.getTicketListFromView(this.filters, pageSize, pageIndex)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.tickets = [];
          this.pageIndex = response.meta.current_page;
          this.pageSize = pageSize;
          this.total = response.meta.total_count;
          this.totalPages = response.meta.total_pages;
          response.data.forEach((ticket: any) => {
            let tmpTicket = new Ticket(ticket, response.included);
            tmpTicket.relationships!.status!.attributes!.status_data = { background_color: this.userService.statusArray.find(status => status.id == tmpTicket.relationships?.status?.id)?.background_color || 'Black' }
            this.tickets.push(tmpTicket);
          });
          this.loading = false;
          this.ticketService.setPreviousSelectedNavFilter(this.selectedNavFilter);
        },
        error: err => {
          this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
          console.log(err);
          this.loading = false;
        }
      })
  }

  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.navFilterChange(this.pageSize, this.pageIndex);
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.navFilterChange(this.pageSize, this.pageIndex);
  }

  searchTickets() {
    if(this.searchString.length == 0 || this.searchString.length > 2) {
      this.searchSubject$.next(this.searchString);
    }
  }

  private setSearchSubject() {
    this.tickets = [];

    this.searchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          this.loading = true;
          const query = { q: this.searchSubject$.getValue(), searchFields: ['code','subject','stripped_description'] };

          return this.ticketService.getTickets({
            pageIndex: 1,
            pageSize: this.pageSize,
            query,
            reportParams: this.filters,
          })
        })
      )
      .subscribe({
        next: (response: any) => {
          if(response.meta) {
            this.pageIndex = response.meta.current_page;
            this.total = response.meta.total_count;
            this.totalPages = response.meta.total_pages;
          }
          this.tickets = response.data.map((ticket: any) => {
            let tmpTicket = new Ticket(ticket, response.included);
            tmpTicket.relationships!.status!.attributes!.status_data = { background_color: this.userService.statusArray.find(status => status.id == tmpTicket.relationships?.status?.id)?.background_color || 'Black' }

            return tmpTicket;
          });
          this.loading = false;
        },
        error: err => {
          this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
          console.log(err);
          this.loading = false;
        }
      })
  }
}
