import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { ServicesService } from "src/app/services/services.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import Service from "src/app/utilities/models/service/service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import PermissionHelper from "src/app/utilities/helpers/permissionHelper";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { UsersService } from "src/app/services/users.service";
import { ServiceCreateDataAttributes, ServiceCreateDto } from "src/app/utilities/models/dto/serviceCreateDto";
import {
  ServiceUpdateDataAttributes,
  ServiceUpdateDto
} from "src/app/utilities/models/dto/serviceUpdateDto";
import { NzMessageService } from "ng-zorro-antd/message";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import InstalledService from "src/app/utilities/models/service/installedService";
import {
  InstalledServiceCreateDataAttributes,
  InstalledServiceCreateDataRelationships,
  InstalledServiceCreateDto
} from "src/app/utilities/models/dto/installedServiceCreateDto";
import { Account } from "src/app/utilities/models/account/account";
import { AccountsService } from "src/app/services/accounts.service";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip, switchMap, take } from "rxjs";
import { UploadsService } from "src/app/services/uploads.service";
import { InstalledServiceDetailUpdateDto } from "src/app/utilities/models/dto/installedServiceDetailUpdateDto";
import { InstalledServiceDetailCreateDto } from "src/app/utilities/models/dto/installedServiceDetailCreateDto";
import { InstalledServiceDetailsCount } from "src/app/utilities/models/service/InstalledServiceDetailsCount";
import InstalledServiceDetail from "src/app/utilities/models/service/installedServiceDetail";

interface InstalledServiceAccountData {
  account: Account;
  installedService: InstalledService,
  metricData: {
    [key: number]: {
      count: number,
      installedServiceDetailId: number | null
    }
  }
}

@Component({
    selector: 'app-manual-service',
    templateUrl: './additional-service.component.html',
    styleUrls: ['./additional-service.component.scss'],
    standalone: false
})
export class AdditionalServiceComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  service: Service;
  serviceMetrics: ServiceMetric[] = [];
  detailMetrics:  ServiceMetric[] = [];
  serviceForm: FormGroup;
  serviceCreatePermission: Permission | undefined;
  serviceUpdatePermission: Permission | undefined;
  id: number;
  installedServices: InstalledService[] = [];
  accounts: Account[] = [];
  addServiceToAccountForm: FormGroup;
  installedServiceDetailsCount: InstalledServiceDetailsCount[];
  installedServiceDetails: InstalledServiceDetail[];
  detailsPerInstalledService: any = {};
  sortedDetailsPerInstalledService: any[] = [];
  serviceLoading: boolean = false;
  installedServiceDetailsLoading: boolean = false;
  isUploading = false;
  fileList: NzUploadFile[] = [];
  accountIdsInstalled: number[] = [];
  filteredAccounts: Account[] = [];
  accountIdInEditMode: number | undefined;
  metricIdInEditMode: number | undefined;
  accountsLoading: boolean = false;
  searchSubject$ = new BehaviorSubject<string>('');

  constructor(private serviceService: ServicesService,
              private userService: UsersService,
              private accountService: AccountsService,
              private uploadService: UploadsService,
              private activatedRoute: ActivatedRoute,
              private msg: NzMessageService,
              private router: Router,
              private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.setPermissions();
    this.activatedRoute.params
      .subscribe({
        next: (params: Params) => {
          if(params['id'] > 0) {
            this.id = params['id'];
            this.fetchData();
            this.getAccounts();
            this.observeAccountSearch();
          }
          this.initForm();
        }
      }
    );
  }

  ngOnDestroy() {
    //this.serviceService.setAdditionalService(undefined);
  }

  fetchData() {
    this.getService(this.id);
  }

  get serviceHasDetailMetric() {
    return this.serviceMetrics.filter((metric => metric.attributes.metric_type == 'detail'))?.length > 0;
  }

  get isStaticService() {
    return this.service.attributes.system_key && this.serviceService.staticServiceKeys.includes(this.service.attributes.system_key);
  }

  onSubmit() {
    if(!this.serviceForm.valid) return;

    this.loading = true;
    if(this.service?.id > 0){
      const payload = this.prepareServiceUpdatePayload();
      this.serviceService.updateService(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.msg.success('Service was updated successfully', { nzDuration: 5000, nzPauseOnHover: true });
          },
          error:(err) => {
            this.loading = false;
            this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
          }
        });

      return true;
    }else{
      const payload = this.prepareServiceCreatePayload();
      this.serviceService.createService(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.msg.success('Service was created successfully', { nzDuration: 5000, nzPauseOnHover: true });
            this.router.navigate(['/app/additional-services/'+response.data.id]).then( _ => {});
          },
          error:(err) => {
            this.loading = false;
            this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
          }
        });

      return true;
    }
  }

  delete(): void {
    if(!this.service.attributes.external_identifier) {
      this.serviceService.deleteService(this.service.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.router.navigate(['/app/additional-services']).then(_ => {
            });
          },
          error: () => {
          }
        })
    }
  }

  addInstalledServiceToAccount(accountId: number) {
    const payload = this.prepareInstalledServicePayload(accountId);
    this.serviceService.addInstalledServiceToAccount(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(_response: any) => {
          this.getInstalledServices(this.service.id);
          this.addServiceToAccountForm.reset();
        },
        error: () => {}
      })
  }

  removeServiceFromAccount(installedServiceId: number) {
    this.serviceService.removeInstalledService(installedServiceId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(_response: any) => {
          this.getInstalledServices(this.service.id);
        },
        error: () => {}
      })
  }

  updateInstalledServiceMetricDetail(installedService: InstalledService, serviceMetricId: number) {
    const item = this.detailsPerInstalledService[installedService.id][serviceMetricId];
    if(item){
      if(item.installedServiceDetailId){
        // update installed service value
        const payload: InstalledServiceDetailUpdateDto = {
          data: {
            id: item.installedServiceDetailId,
            type: 'installed_service_details',
            attributes:{
              col1: item.count
            }
          }
        }
        this.serviceService.updateInstalledServiceDetail(payload)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (_response: any) => {
              this.getInstalledServiceDetails();
              this.msg.success('Service metric value was updated successfully', { nzDuration: 5000, nzPauseOnHover: true });
              this.accountIdInEditMode = undefined;
              this.metricIdInEditMode = undefined;
            }
          })
      }else{
        // create installed service value
        const payload: InstalledServiceDetailCreateDto = {
          data: {
            type: 'installed_service_details',
            attributes:{
              col1: item.count
            },
            relationships:{
              installed_service: { data: { id: installedService.id, type: 'installed_services' }},
              service_metric: { data: { id: serviceMetricId, type: 'service_metrics' }}
            }
          }
        }
        this.serviceService.createInstalledServiceDetail(payload)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (_response: any) => {
              this.getInstalledServiceDetails();
              this.msg.success('Service metric value was created successfully', { nzDuration: 5000, nzPauseOnHover: true });
              this.accountIdInEditMode = undefined;
              this.metricIdInEditMode = undefined;
            }
          })
      }
    }

  }

  onAddAccountSubmit() {
    const accountId = this.addServiceToAccountForm.get('account')?.value;
    if(accountId > 0) {
      this.addInstalledServiceToAccount(accountId);
    }
  }

  onBeforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.handleUpload();

    return false;
  };

  toggleEditMode(accountId: number | undefined, serviceMetricId: number, value: boolean) {
     if(value) {
       this.accountIdInEditMode = accountId;
       this.metricIdInEditMode = serviceMetricId;
     }else{
       this.accountIdInEditMode = undefined;
       this.metricIdInEditMode = undefined;
     }
  }

  observeAccountSearch() {
    this.accountsLoading = true;
    this.searchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          return this.accountService.getCustomerAccounts(this.searchSubject$.getValue())
        })
      )
      .subscribe({
        next: (response: any) => {
          this.accounts = response.data.map((row: any) => new Account(row, response.included));
          this.accountsLoading = false;
        },
        error: (error) => {
          this.accountsLoading = false;
          console.log(error);
        }
      });
  }

  setAccountSearchStr(value: string) {
    this.searchSubject$.next(value);
  }

  private prepareInstalledServicePayload(accountId: number) {
    const attributes: InstalledServiceCreateDataAttributes = { };
    const relationships: InstalledServiceCreateDataRelationships = {
      account: { data: { id: accountId, type: 'accounts' } },
      service: { data: { id: this.service.id, type: 'services' } },
    };
    const type = 'installed_services';

    return new InstalledServiceCreateDto({ "type": type, "attributes": attributes, "relationships": relationships });
  }

  private getAccounts() {
    this.accountsLoading = true;
    this.accountService.getCustomerAccounts()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.accounts = response.data.map((row: any) => new Account(row, response.included));
          this.accountsLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.accountsLoading = false;
        }
      })
  }

  private prepareServiceCreatePayload(): ServiceCreateDto {
    const serviceCreateAttributes: ServiceCreateDataAttributes = {
      title: this.serviceForm.get('title')?.value ?? '',
      description: this.serviceForm.get('description')?.value ?? ''
    };

    return new ServiceCreateDto({ type: 'services', attributes: serviceCreateAttributes });
  }

  private prepareServiceUpdatePayload(): ServiceUpdateDto {
    const serviceUpdateAttributes: ServiceUpdateDataAttributes = {
      title: this.serviceForm.get('title')?.value ?? '',
      description: this.serviceForm.get('description')?.value ?? '',
    };

    return new ServiceUpdateDto({ id: this.service.id, type: 'services', attributes: serviceUpdateAttributes });
  }

  private getService(id: number) {
    this.serviceLoading = true;
    this.serviceService.getService(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.service = new Service(response.data, response.included);
          this.serviceMetrics = response.included?.filter((incObj: any) => incObj.type == 'service_metrics')?.map((serviceMetric: any) => new ServiceMetric(serviceMetric, response.included)) ?? [];
          this.detailMetrics = this.serviceMetrics.filter((metric: any) => metric.attributes.metric_type == 'detail')
          this.setForm();
        },
        error:() => {},
        complete: () => {
          this.serviceLoading = false;
          this.getInstalledServices(this.id);
        }
      })
  }

  private setPermissions(): void {
    const serviceCreatePermission = this.userService.findPermission('Digcore::Service', 'ticketing/operator/v1/services', 'create');
    const serviceUpdatePermission = this.userService.findPermission('Digcore::Service', 'ticketing/operator/v1/services', 'update');

    if(serviceCreatePermission) this.serviceCreatePermission = serviceCreatePermission;
    if(serviceUpdatePermission) this.serviceUpdatePermission = serviceUpdatePermission;
  }

  private initForm() {
    const permission = this.service?.id > 0 ? this.serviceUpdatePermission : this.serviceCreatePermission;
    this.serviceForm = new FormGroup({
      'title': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(permission,'title') }, Validators.required),
      'description': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(permission,'description') }),
      'upload': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(permission,'upload') }),
    });

    this.addServiceToAccountForm = new FormGroup({
      'account': new FormControl<number | undefined>(undefined, Validators.required),
    })
  }

  private setForm() {
    if(this.service?.id > 0){
      this.serviceForm.get('title')?.setValue(this.service?.attributes.title);
      this.serviceForm.get('description')?.setValue(this.service?.attributes.description);
    }
  }

  private getInstalledServices(serviceId: number) {
    this.serviceService.getInstalledServiceAccounts(serviceId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.installedServices = response.data.map((row: any) => new InstalledService(row, response.included));
          this.accountIdsInstalled = this.installedServices.map((row: any) => row.relationships.account.id);
        },
        complete: () => {
          this.getInstalledServiceDetails();
        }
      })
  }

  getInstalledServiceDetails() {
    this.installedServiceDetailsLoading = true;
    const installedServiceIds = this.installedServices.map((installedService: any) => installedService.id);
    const serviceMetricIds = this.service?.relationships?.service_metrics?.filter((serviceMetric: any) => serviceMetric.attributes.metric_type == 'detail')?.map((serviceMetric: any) => serviceMetric.id) ?? [];
    let initialValueForInstalledService: { [key: string]: { count: number, installedServiceDetailId?: number } } = {};
    serviceMetricIds.forEach(metricId => {
      initialValueForInstalledService = {
        ...initialValueForInstalledService,
        [metricId]: { count: 0, installedServiceDetailId: null }
      };
    })
    this.installedServices.forEach((row: any) => {
      this.detailsPerInstalledService = {
        ...this.detailsPerInstalledService,
        [row.id]: { ...initialValueForInstalledService }
      }
    })

    if(serviceMetricIds.length > 0) {
      this.serviceService.getAllInstalledServiceDetails(installedServiceIds, serviceMetricIds)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.installedServiceDetails = (response.data.length > 0) ? response.data.map((row: any) => new InstalledServiceDetail(row, response.included)) : [];
            this.installedServiceDetails.forEach((row: any) => {
              if(row.attributes.col1) {
                this.detailsPerInstalledService[row.relationships.installed_service.id][row.relationships.service_metric.id] = {
                  count: row.attributes.col1,
                  installedServiceDetailId: row.id
                };
              }
            });
          },
          complete: () => {
            this.installedServiceDetailsLoading = false;
            this.sortDetailsPerInstalledService();
          }
        })
    }else{
      this.installedServiceDetailsLoading = false;
    }
  }

  private handleUpload() {
    if (this.fileList.length) {
      this.isUploading = true;
      const payload = this.prepareUploadPayload();

      this.uploadService.create(payload)
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            this.fileList = [];
            this.msg.success('Image was uploaded successfully', { nzDuration: 5000, nzPauseOnHover: true });
            this.deleteAllOtherUploads();
          },
          error: ( _ ) => {
            this.msg.success('There was an error, please try again', { nzDuration: 5000, nzPauseOnHover: true });
            this.isUploading = false;
          },
          complete: () => {
            this.isUploading = false;
          }
        });
    }
  }

  private prepareUploadPayload(){
    const payload = new FormData();

    payload.append('used_for', 'attachment');
    payload.append('uploadable_type', 'Digcore::Service');
    payload.append('uploadable_id', this.service.id.toString());

    this.fileList.forEach((file: any) => {
      payload.append('uploaded_file', file);
    });

    return payload;
  }

  private deleteAllOtherUploads() {
    if(!this.service.relationships?.uploads?.length){
      this.getService(this.service.id);
    }else{
      this.service.relationships?.uploads?.forEach(upload => {
        this.uploadService.delete(upload.id).subscribe({
          next: () => {
            this.getService(this.service.id);
          },
          error: () => {}
        });
      });
    }
  }

  private sortDetailsPerInstalledService() {
    const dataForDetails: any[] = [];
    Object.keys(this.detailsPerInstalledService).forEach((installedServiceId: string) => {
      const installedService = this.installedServices.find(installedService => installedService.id == +installedServiceId)
      if(!installedService || !installedService?.relationships?.account) return;

      const account = new Account(installedService.relationships.account, []);
      const obj: InstalledServiceAccountData = {
        account: account,
        installedService: installedService,
        metricData: this.detailsPerInstalledService[installedServiceId]
      }
      dataForDetails.push(obj)
    })

    this.sortedDetailsPerInstalledService = dataForDetails.sort(function(a,b) { return a.account.attributes.title.toLowerCase() < b.account.attributes.title.toLowerCase() ? -1 : 1 } );
  }
}
