import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { ServicesService } from "src/app/services/services.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UsersService } from "src/app/services/users.service";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import Service from "src/app/utilities/models/service/service";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import PermissionHelper from "src/app/utilities/helpers/permissionHelper";
import {
  ServiceMetricCreateDataAttributes,
  ServiceMetricCreateDto,
  ServiceMetricCreateRelationships
} from "src/app/utilities/models/dto/serviceMetricCreateDto";
import { ServiceMetricUpdateDataAttributes, ServiceMetricUpdateDto } from "src/app/utilities/models/dto/serviceMetricUpdateDto";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";

@Component({
    selector: 'app-manual-service-metric',
    templateUrl: './additional-service-metric.component.html',
    styleUrls: ['./additional-service-metric.component.scss'],
    standalone: false
})
export class AdditionalServiceMetricComponent implements OnInit{
  loading: boolean = false;
  serviceMetricForm: FormGroup;
  serviceMetricCreatePermission: Permission | undefined;
  serviceMetricUpdatePermission: Permission | undefined;
  service: Service;
  serviceMetric: ServiceMetric | undefined;
  serviceMetrics: ServiceMetric[];
  serviceMetricId: number = 0;

  //readonly #modal = inject(NzModalRef);
  //readonly nzModalData: { service: Service, serviceMetricId?: number} = inject(NZ_MODAL_DATA);

  constructor(private serviceService: ServicesService,
              private userService: UsersService,
              private msg: NzMessageService,
              private router: Router,
              private destroyRef: DestroyRef,
              private modalRef: NzModalRef,
              @Inject(NZ_MODAL_DATA) readonly nzModalData: { service: Service, serviceMetricId?: number}) {}

  ngOnInit() {
    this.setPermissions();
    this.initForm();
    this.setDataFromService();
  }

  setDataFromService() {
    this.service = this.nzModalData.service;
    this.serviceMetrics = this.service?.relationships?.service_metrics?.filter((incObj: any) => incObj.type == 'service_metrics')?.map((serviceMetric: any) => new ServiceMetric(serviceMetric, [])) ?? [];

    if(this.nzModalData.serviceMetricId) {
      this.serviceMetric = this.serviceMetrics.find((x: any) => x.id == this.nzModalData.serviceMetricId);
    }
    this.setForm();
  }

  onSubmit() {
    if(!this.serviceMetricForm.valid) return;

    this.loading = true;
    if(this.nzModalData.serviceMetricId){
      const payload = this.prepareServiceMetricUpdatePayload();
      this.serviceService.updateServiceMetric(payload)
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.msg.success('Service Metric was updated successfully', { nzDuration: 5000, nzPauseOnHover: true });
            this.modalRef.triggerOk();
          },
          error:(err) => {
            this.loading = false;
            this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
          }
        });

      return true;
    }else{
      const payload = this.prepareServiceMetricCreatePayload();
      this.serviceService.createServiceMetric(payload)
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.msg.success('Service Metric was created successfully', { nzDuration: 5000, nzPauseOnHover: true });
            this.modalRef.triggerOk();
          },
          error:(err) => {
            this.loading = false;
            this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
          }
        });

      return true;
    }
  }

  delete(): void {
    if(this.serviceMetric && !this.serviceMetric?.attributes.external_identifier && this.nzModalData.serviceMetricId) {
      this.serviceService.deleteServiceMetric(this.nzModalData.serviceMetricId)
        .subscribe({
          next: () => {
            this.router.navigate(['/app/additional-services/'+this.service.id]).then(_ => {
            });
            this.modalRef.triggerOk();
          },
          error: () => {
          }
        })
    }
  }

  private getService(id: number) {
    this.serviceService.getService(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.service = new Service(response.data, response.included);
          this.serviceMetrics = response?.included?.filter((incObj: any) => incObj.type == 'service_metrics')?.map((serviceMetric: any) => new ServiceMetric(serviceMetric, response.included)) ?? [];

          this.serviceMetric = this.serviceMetrics.find((x: any) => x.id == this.serviceMetricId);
          this.setForm();
        }
      })
  }

  private setPermissions(){
    const serviceMetricCreatePermission = this.userService.findPermission('Digcore::ServiceMetric', 'ticketing/operator/v1/service_metrics', 'create');
    const serviceMetricUpdatePermission = this.userService.findPermission('Digcore::ServiceMetric', 'ticketing/operator/v1/service_metrics', 'update');

    if(serviceMetricCreatePermission) this.serviceMetricCreatePermission = serviceMetricCreatePermission;
    if(serviceMetricUpdatePermission) this.serviceMetricUpdatePermission = serviceMetricUpdatePermission;
  }

  private initForm() {
    const permission = this.serviceMetricId > 0 ? this.serviceMetricUpdatePermission : this.serviceMetricCreatePermission;
    this.serviceMetricForm = new FormGroup({
      'title': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(permission,'title') }, Validators.required),
      'metric_type': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(permission,'metric_type') }, Validators.required),
    });
  }

  private setForm() {
    if(this.nzModalData.serviceMetricId && this.nzModalData.serviceMetricId > 0) {
      this.serviceMetricForm.get('title')?.setValue(this.serviceMetric?.attributes.title);
      this.serviceMetricForm.get('metric_type')?.setValue(this.serviceMetric?.attributes.metric_type);
    }else{
      this.serviceMetricForm.get('metric_type')?.setValue('detail');
    }
  }

  private prepareServiceMetricCreatePayload(): ServiceMetricCreateDto {
    const serviceMetricCreateAttributes: ServiceMetricCreateDataAttributes = {
      title: this.serviceMetricForm.get('title')?.value,
      metric_type: this.serviceMetricForm.get('metric_type')?.value,
    };

    const serviceMetricCreateRelationships: ServiceMetricCreateRelationships = {
      service: { data: { id: this.service.id, type: 'services' } }
    }

    return new ServiceMetricCreateDto({ type: 'service_metrics', attributes: serviceMetricCreateAttributes, relationships: serviceMetricCreateRelationships });
  }

  private prepareServiceMetricUpdatePayload(): ServiceMetricUpdateDto {
    const serviceMetricUpdateAttributes: ServiceMetricUpdateDataAttributes = {
      title: this.serviceMetricForm.get('title')?.value,
      metric_type: this.serviceMetricForm.get('metric_type')?.value,
    };

    return new ServiceMetricUpdateDto({ id: this.nzModalData.serviceMetricId ?? 0, type: 'service_metrics', attributes: serviceMetricUpdateAttributes });
  }
}
