import {  Component, DestroyRef, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FlowsService } from 'src/app/services/flows.service';
import { UsersService } from 'src/app/services/users.service';
import PermissionHelper from 'src/app/utilities/helpers/permissionHelper';
import { FlowCreateData, FlowCreateDto } from 'src/app/utilities/models/dto/flowCreateDto';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';

@Component({
    selector: 'app-flow-new',
    templateUrl: './flow-new.component.html',
    styleUrls: ['./flow-new.component.scss'],
    standalone: false
})
export class FlowNewComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  permission: Permission | undefined;
  flowCategories: FlowCategory[];
  platforms: {label: string, value: string}[] = [
    {label: 'Deskware', value: 'Deskware'},
    {label: 'Onspring', value: 'Onspring'},
  ];

  constructor(private userService: UsersService,
              private flowService: FlowsService,
              private destroyRef: DestroyRef,
              private router: Router,
              private msg: NzMessageService) {

  }

  ngOnInit(): void {
    this.permission = this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'create');
    this.initForm();
    this.getFlowCategories();
  }

  getFlowCategories() {
    this.flowService.getFlowCategories()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.flowCategories = response.data.map((row: any)=> new FlowCategory(row, response.included));
        },
        error: (error) => { console.log(error); },
        complete: () => {},
      })
  }

  onSubmit() {
    if(!this.form.valid) return;

    this.loading = true;
    const payload: FlowCreateDto = this.prepareCreatePayload();

    this.flowService.createFlow(payload)
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.router.navigate(['/app/flows/'+response.data.id]).then( _ => {});
        },
        error:(err) => {
          this.loading = false;
          this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
        }
      });

    return true;
  }

  initForm() {
    this.form = new FormGroup({
      'active': new FormControl<boolean>({ value: true, disabled: PermissionHelper.disabledByProperty(this.permission, 'active') }, Validators.required ),
      'title': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'title') }, Validators.required ),
      'platform_name': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'platform_name') }, Validators.required ),
      'subject': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'subject') }),
      'label': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'label') }),
      'flow_categories': new FormControl<number[]>({ value: [], disabled: PermissionHelper.disabledByProperty(this.permission, 'flow_categories') }),
    })
  }

  setDefaults() {
    this.form.get('platform_name')?.setValue('Deskware');
  }

  private prepareCreatePayload(): FlowCreateDto {
    const categories = this.form.get('flow_categories')?.value.map((id: number) => {
      return {
        id: id,
        type: 'flow_categories'
      }
    }) ?? [];

    const data: FlowCreateData = {
        type: 'flows',
        attributes: {
          title: this.form.get('title')?.value,
          platform_name: this.form.get('platform_name')?.value,
          subject: this.form.get('subject')?.value,
          label: this.form.get('label')?.value,
          active: this.form.get('active')?.value,
        },
        relationships: {
          flow_categories: {
            data: categories
          }
        },
    }

    return new FlowCreateDto(data);
  }
}
