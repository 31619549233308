import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Uid} from "src/app/utilities/models/uid/uid";
import {Router} from '@angular/router';
import {TokenService} from "src/app/auth/token.service";
import {UsersService} from "src/app/services/users.service";

@Component({
    selector: "uid-component",
    template: `<ng-content></ng-content>`,
    standalone: false
})
export class UidComponent implements OnInit{
  constructor(private route: ActivatedRoute,
              private userService: UsersService,
              private tokenService: TokenService,
              private router: Router) {}

  ngOnInit() {
    this.userService.userSubject.subscribe(user => {
      if (user?.id) {
        this.userService.setShowPasswordModal();
      }
    })
    this.parseTokenFromUrl();
    this.parseUid();
  }

  private parseTokenFromUrl() {
    this.route.queryParams
      .subscribe(async (params) => {
        const token = params['token'] || null;
        if(token) {
          // set new user token and fetch user information from scratch
          this.tokenService.saveToken(token);
          this.userService.prepareApp();
        }
      });
  }

  private parseUid() {
    const base64 = this.route.snapshot.paramMap.get('base64');
    if(!base64) return;

    const uidData = JSON.parse(window.atob(base64));
    if(uidData.object) {
      const uid = new Uid(uidData);
      let ticketCode: string | undefined;
      switch(uid.object.model.toLowerCase()){
        case "comment":
          const commentId = uid.object.id;
          const conversationId = uid.related_object?.object.id;
          ticketCode = uid.related_object?.related_object?.object.id;
          this.router.navigate(['app', 'support', 'tickets', ticketCode], { fragment: `${conversationId}_${commentId}` }).finally();
          break;
        case "ticket":
          ticketCode = uid.object.id;
          this.router.navigate(['app', 'support', 'tickets', ticketCode]).finally();
          break;
        case "servicehistory":
          const queryParams = this.route.queryParams.subscribe(async (params) => {
            this.router.navigate(['app', 'services-change-history'],{ queryParams: params}).finally();
          });
          break;
        case "meeting":
          this.router.navigate(['app', 'meetings'], { fragment: uid.object.id }).finally();
          break;
        default:
          // go to tickets by default if no matching uid is found
          this.router.navigate(['app', 'support', 'tickets']).finally();
      }
    }
  }
}
