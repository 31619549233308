<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Services</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-row class="services-wrapper">
      <div nz-col nzSpan="24">
        <div nz-row nzJustify="center">
          <div nz-col>
            <h1>Services</h1>
          </div>
        </div>

        <nz-divider></nz-divider>

        @if (loading || requestsLoading) {
          <nz-spin nzSimple></nz-spin>
        } @else {
          <nz-tabset [nzAnimated]="false" [nzSelectedIndex]="selectedTabIndex">
            <nz-tab nzTitle="Services">
              <p class="intro">
                Introducing the comprehensive list of services and subscriptions via the EMPIST 360 platform.<br>
                Gain visibility into subscribed services and conveniently request any necessary changes.
              </p>

              @if (installedServicesViewData && installedServicesViewData.length) {
                <ul class="services-list installed-services-list">
                  @for (installedServiceViewData of installedServicesViewData; track $index) {
                    @if (installedServiceViewData.visible) {
                      <li class="services-list-item"
                          [ngClass]="{ 'has-service-details': !!installedServiceViewData.hasInstalledServiceDetails }"
                          [routerLink]="(!!installedServiceViewData.hasInstalledServiceDetails) ? installedServiceViewData.routePath : null">
                        <div class="services-list-item-details">
                          <h2>{{ installedServiceViewData.title }}</h2>

                          <p *ngIf="shouldShowAccountInList"
                             class="account-title">{{ installedServiceViewData.account?.attributes?.title ?? 'All accounts' }}</p>
                          <p class="description">{{ installedServiceViewData.description }}</p>
                        </div>

                        <div class="services-list-item-logo">
                          <img [src]="installedServiceViewData.logo" [alt]="installedServiceViewData.title"/>
                        </div>

                        <ul class="services-list-item-metrics">
                          @for (metric of installedServiceViewData.detailMetrics; track $index) {
                            <li>
                              <div class="metric-title">{{ metric.title }}</div>
                              <div class="metric-value">{{ metric.count }}</div>
                            </li>
                          }
                        </ul>
                      </li>
                    }
                  }
                </ul>
              } @else {
                <nz-empty nzNotFoundImage="simple"></nz-empty>
              }
            </nz-tab>

            <nz-tab nzTitle="Add-on Services">
              <p class="intro">
                The list below is the list of services EMPIST is offering, but are not currently assigned to your account.
                <br>
                If you are interested on having any of these services, don't hesitate to contact us.
              </p>

              @if (addOnServices && addOnServices.length) {
                <ul class="services-list unavailable-services-list">
                  @for (addOnService of addOnServices; track addOnService.id) {
                    <li class="services-list-item has-service-details"
                        [routerLink]="['/app', 'services', addOnService.id, 'add-on-service-request']">
                      <div class="services-list-item-details">
                        <h2>{{ addOnService.getTitle() }}</h2>
                        <p class="description">{{ addOnService.getDescription() }}</p>
                      </div>

                      <div class="services-list-item-logo">
                        <img [src]="addOnService.getLogo()" [alt]="addOnService.getTitle()" />
                      </div>
                    </li>
                  }
                </ul>
              } @else {
                <nz-empty nzNotFoundImage="simple"></nz-empty>
              }
            </nz-tab>

            <nz-tab nzTitle="Request Support">
              <p class="intro">
                You can submit a request to perform a change in the provided services using the form below.
              </p>

              <form class="request-change-form"
                    nz-form
                    nzLayout="vertical"
                    [formGroup]="requestChangeForm"
                    (ngSubmit)="onSubmit()">
                <div nz-row>
                  <div nz-col nzSpan="24">
                      <nz-form-item class="ticket-create-form-item">
                        <nz-form-label nzFor="description" nzRequired>
                          Please describe the desired change
                        </nz-form-label>
                        <nz-form-control>
                          <froala-editor formControlName="description" [options]="editorOptions"></froala-editor>
                        </nz-form-control>
                      </nz-form-item>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="24" class="actions-container">
                    <button nz-button type="button" nzType="default" nzShape="round" nzSize="large" [routerLink]="['/app','services']">Cancel</button>
                    <button nz-button type="submit" nzType="primary" nzShape="round" nzSize="large" [disabled]="!requestChangeForm.valid">Send Request</button>
                  </div>
                </div>
              </form>
            </nz-tab>
          </nz-tabset>
        }
      </div>
    </div>
  </nz-content>
</nz-layout>

<custom-loader *ngIf="loaderVisible"></custom-loader>
