import { Component, EventEmitter, Input, Output, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CountryCodesWithFlag } from "./country-code-with-flag";
import parsePhoneNumber from 'libphonenumber-js';

interface IPhoneNumber {
  nationalNumber: string | undefined;
  countryCode: string | undefined;
  internationalNumber: string;
}

interface ICountrySelectOption{
  code: string;
  name: string;
  image: string;
  prefix: string;
}

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    standalone: false
})
export class PhoneInputComponent implements OnInit, OnDestroy, AfterViewInit{
  @Input() phone: string | undefined;
  @Output() phoneChanged: EventEmitter<string | null> = new EventEmitter<string | null>();
  @ViewChild('phoneNumber') input: ElementRef<HTMLInputElement>;

  selectedCountry: any;
  nationalNumber: string | undefined;
  countryCode: string | undefined;
  countries: ICountrySelectOption[] = [];
  phoneNumberValidationMessage: string = '';

  phoneNumber: IPhoneNumber = {
    nationalNumber: undefined,
    countryCode: undefined,
    internationalNumber: ''
  };

  ngOnInit() {
    this.phoneNumber = {
      internationalNumber: this.phone ? this.phone?.replace(/\s/g, '') : '',
      nationalNumber: undefined,
      countryCode: undefined
    }
    this.phoneNumber.nationalNumber = this.getNumberFromFullNumber();
    this.phoneNumber.countryCode = this.getCountryCodeFromFullNumber();

    const countryCodesWithFlag = new CountryCodesWithFlag();
    const allCountries: any = countryCodesWithFlag.allCountries;
    const preferredCountries = ['US','GR'];
    this.countries = [
      ...Object.keys(countryCodesWithFlag.allCountries).filter(key => !preferredCountries.includes(key)).map(key => {
        return {
          code: key,
          name: allCountries[key].name,
          image: allCountries[key].image,
          prefix: allCountries[key].phone[0],
        }
      })
    ]
    preferredCountries.reverse().forEach(key => {
      const countryData = {
        code: key,
        name: allCountries[key].name,
        image: allCountries[key].image,
        prefix: allCountries[key].phone[0],
      }
      this.countries.unshift(countryData)
    });

    this.nationalNumber = this.phoneNumber.nationalNumber;
    this.countryCode = this.phoneNumber.countryCode;

    const parsedPhoneNumber = parsePhoneNumber(this.phoneNumber.internationalNumber);
    let preselectedCountry = undefined;
    if(parsedPhoneNumber){
      preselectedCountry = this.countries.find((country: any) => country['code'] == parsedPhoneNumber?.country);
    }
    const defaultCountry = this.countries.find((country: any) => country['code'] == 'US');
    this.selectedCountry = preselectedCountry ?? defaultCountry;
    if(!preselectedCountry){
      this.phoneNumber.countryCode = defaultCountry?.prefix;
    }
  }

  ngAfterViewInit(){
    this.input.nativeElement.focus();
  }

  ngOnDestroy() {}

  getNumberFromFullNumber() {
    if(!this.phoneNumber.internationalNumber) return undefined;
    const parsedPhoneNumber = parsePhoneNumber(this.phoneNumber.internationalNumber);

    return parsedPhoneNumber?.nationalNumber;
  }

  getCountryCodeFromFullNumber() {
    if(!this.phoneNumber.internationalNumber) return undefined;
    const parsedPhoneNumber = parsePhoneNumber(this.phoneNumber.internationalNumber);

    return parsedPhoneNumber ? '+'+parsedPhoneNumber.countryCallingCode : undefined;
  }

  countryChange(){
    this.phoneNumber.countryCode = this.selectedCountry.prefix ? this.selectedCountry.prefix : undefined;
    this.phoneNumberChange();
    this.input.nativeElement.focus();
  }

  nationalNumberChange(){
    this.phoneNumber.nationalNumber = this.nationalNumber;
    this.phoneNumberChange();
  }

  phoneNumberChange(){
    this.setInternationalNumber();
  }

  setInternationalNumber(){
    if(!this.phoneNumber.countryCode || !this.phoneNumber.nationalNumber || this.phoneNumber.nationalNumber?.length < 2) {
      if(this.phoneNumber.nationalNumber?.length == 0) {
        this.phoneChanged.emit(null);
      }else{
        this.phoneChanged.emit(undefined);
      }
      return;
    }

    try {
      const parsedPhoneNumber = parsePhoneNumber(this.phoneNumber.countryCode + this.phoneNumber.nationalNumber);
      if (parsedPhoneNumber?.isValid() && parsedPhoneNumber?.number) {
        this.phoneNumber.internationalNumber = parsedPhoneNumber.number;
        this.phoneChanged.emit(this.phoneNumber.internationalNumber);
        this.phoneNumberValidationMessage = '';
      } else {
        this.phoneChanged.emit(undefined);
        this.phoneNumberValidationMessage = 'Please provide a valid phone number';
      }
    }catch(error){
      console.error(error)
    }
  }
}
