import {Component, DestroyRef, OnInit, signal} from '@angular/core';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {IncidentsService} from "src/app/services/incidents.service";
import {Incident} from "src/app/utilities/models/incident/incident";
import {FormGroup} from "@angular/forms";
import {FiltersService} from "src/app/services/filters.service";
import {AvailableFilterDetail} from "src/app/utilities/models/filters/availableFilterDetail";
import {FilterAggregationOption} from "src/app/utilities/models/filters/filterAggregationOption";
import {FilterDropdownOption} from "src/app/utilities/models/filters/filterDropdownOption";
import {ReportParam} from "src/app/utilities/models/parameters/reportParam/reportParam";
import {SortParam} from "src/app/utilities/models/parameters/sortParam/sortParam";
import {NzTableQueryParams} from "ng-zorro-antd/table";
import {Subject} from "rxjs";

interface INzTableSortOrder {
  order: string | null;
  attribute: string | null;
}

@Component({
    selector: 'app-incident-list',
    templateUrl: './incident-list.component.html',
    styleUrl: './incident-list.component.scss',
    standalone: false
})
export class IncidentListComponent implements OnInit{
  static pageName: string = 'incident_list';
  static filterLabel: { [key: string]: string } = {
    'incident_statuses.id': 'status is',
    'incident_statuses.state': 'state is',
    'incident_components.id': 'component is',
    'incident_severities.id': 'severity is',
    'title': 'title contains',
  };

  allowedFilters: string[] = [
    'title', 'incident_statuses.state', 'incident_statuses.id', 'incident_components.id', 'incident_severities.id'
  ];
  isSortable = signal<boolean>(true);
  loading: boolean = false;
  pageIndex: number = 1;
  pageSize: number = 20;
  sort: INzTableSortOrder = { order: 'descend', attribute: 'started_at' };
  total: number;
  totalPages: number;
  availableFilterOptions: FilterDropdownOption[] = [];
  filtersFormGroup: FormGroup;
  filtersCountFormGroup: number = 0;
  filterSelectOptionsByKey: { [key: string]: FilterAggregationOption[] } = {}
  incidents: Incident[] = [];
  addFilterEnabled: boolean = false;
  initialFilterSet: boolean = false;
  initialFetchDone: boolean = false;
  availableFiltersSet: boolean = false;
  availableFiltersSet$ = new Subject<boolean>();
  observeSelectedParamsWasSet: boolean = false;
  filtersResource = 'incidents';
  startNumber: number;
  endNumber: number;

  constructor(private incidentService: IncidentsService,
              private filterService: FiltersService,
              private destroyRef: DestroyRef) { }

  ngOnInit() {
    //this.filterService.resetFilters();
    this.filtersFormGroup = new FormGroup({});
    this.getAvailableFilters();

    this.availableFiltersSet$.subscribe({
      next: ( _value: boolean ) => {
        if (!this.availableFiltersSet) {
          const currentSelectedParams = this.filterService.getSelectedReportParams(this.filtersResource);
          if (currentSelectedParams?.length > 0) {
            this.initializeControlsByReportParams();
          } else {
            this.addStaticInitialFilters();
          }
          this.addFilterControl('title', '');

          if (!this.observeSelectedParamsWasSet) {
            this.observeSelectedReportParams();
          }
          this.availableFiltersSet = true;
        }
      }
    })
  }

  observeSelectedReportParams() {
    this.observeSelectedParamsWasSet = true;

    this.filterService.selectedReportParams$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (_selectedParams: { [key: string]: ReportParam[]}) => {
          this.getIncidents();
        }
      })

    this.filterService.selectedReportParamsChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: string) => {
          if (this.initialFilterSet) {
            this.calculateAllSelectControlsOptions(value);
          }
        }
      })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);

    this.sort.attribute = (currentSort?.key) ? currentSort.key : 'started_at';
    this.sort.order = currentSort?.value ?? 'descend';

    if (this.initialFetchDone) {
      this.getIncidents(pageSize, pageIndex);
    }
  }

  onSortOrderChange(order: string | null, attribute: string) {
    if (order) {
      this.sort.order = order;
      this.sort.attribute = attribute;
      this.getIncidents();
    }
  }

  getIncidents(pageSize: number = this.pageSize, pageIndex: number = 1) {
    const sort: SortParam = {
      order: (this.sort.order === 'ascend') ? 'asc' : 'desc',
      attribute: this.sort?.attribute ?? undefined
    };

    this.loading = true;
    this.initialFetchDone = true;
    this.checkIfAddFilterIsEnabled();

    const selectedFilters: ReportParam[] = this.filterService.getSelectedFilters(this.filtersResource);
    if (selectedFilters.some((param: ReportParam) => param.operator[0] == 'like')) {
      this.isSortable.set(false);
    } else {
      this.isSortable.set(true);
    }

    this.incidentService.getIncidents(pageSize, pageIndex, selectedFilters, sort)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (response.data) {
            this.incidents = response.data.map((asset: any) => new Incident(asset, response.included));
          }
          this.loading = false;
          this.total = response.meta.total_count;
          this.totalPages = response.meta.total_pages;
          this.startNumber = (pageIndex - 1)*pageSize + 1;
          const end = (pageIndex - 1)*pageSize + pageSize;
          this.endNumber = end > this.total ? this.total : end;
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  onSelect(value: any, filter: AvailableFilterDetail) {
    //console.log('onSelect', filter.computed_key, value)
    this.pageIndex = 1
    if (value?.length == 0) {
      this.filterService.removeFilterFromSelectedParams(this.filtersResource, filter);
    } else {
      switch (filter.type) {
        case 'text':
        case 'string':
          if (value?.length > 2)
            this.filterService.putFilterToSelectedParams(this.filtersResource, filter, value);
          break;
        case 'select':
        case 'static_select':
          const selVal = Array.isArray(value) ? value : [value];
          if (selVal.includes('0') && selVal.length > 1) {
            this.filtersFormGroup.controls[filter.computed_key]?.setValue(this.handleEmptyValue(selVal));
          } else {
            this.filterService.putFilterToSelectedParams(this.filtersResource, filter, selVal);
          }
          break;
        case 'date': // handle date as simple dates without time
          const dates = [new Date(value[0]).toISOString().slice(0, 10), new Date(value[1]).toISOString().slice(0, 10)];
          this.filterService.putFilterToSelectedParams(this.filtersResource, filter, dates);
          break;
        default:
          this.filterService.putFilterToSelectedParams(this.filtersResource, filter, value);
          break;
      }
    }
  }

  /*
  If the 0 (empty) is added after other selection we need to remove
  all other selections, if any other selection is added after 0 (empty)
  we remove 0
  */
  handleEmptyValue(value: string[]): string[] {
    const zeroIndex = value.indexOf('0');
    return zeroIndex == 0 ? value.slice(1, value.length) : ['0'];
  }

  getOptions(filter: AvailableFilterDetail): void {
    //console.log('get options', filter.computed_key)
    if (filter.type == 'static_select' && filter.options?.length) {
      this.filterSelectOptionsByKey[filter.computed_key] = filter.options.map(option => new FilterAggregationOption(option, option));
      return;
    }
    if(!filter.action) return;

    this.filterService.getFilterOptions(filter.action)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.filterSelectOptionsByKey[filter.computed_key] = response.data.map((row: any) => new FilterAggregationOption(row.id, row.attributes.title, 0));
        }
      })
  }

  addFilterControl(key: string, selectedValue: any = null) {
    const filter: AvailableFilterDetail | null = this.filterService.getFilterDetails(key);
    const singleValue = Array.isArray(selectedValue) ? selectedValue[0] : selectedValue;
    if (!filter || !this.allowedFilters.includes(filter.computed_key)) return;

    this.filtersFormGroup.addControl(key, AvailableFilterDetail.getFormControlByType(filter.type));
    this.getOptions(filter);
    this.filtersFormGroup.controls[key].setValue(singleValue);
    this.filtersCountFormGroup++;
    this.checkIfAddFilterIsEnabled();
    if (!this.filtersFormGroup.controls[key]) {
      this.initializeControlsByReportParams();
    }
  }

  removeFilterControl(filter: AvailableFilterDetail) {
    this.filtersFormGroup.removeControl(filter.computed_key);
    this.filtersCountFormGroup--;
    this.filterService.removeFilterFromSelectedParams(this.filtersResource, filter);
    //console.log('remove filter', filter.computed_key);
    this.checkIfAddFilterIsEnabled();
  }

  clearAllFilters(notifyChange: boolean = true) {
    this.filtersFormGroup = new FormGroup([]);
    this.filterService.clearAllFilters(this.filtersResource, notifyChange);
    this.filtersCountFormGroup = 0;
    this.checkIfAddFilterIsEnabled();
  }

  getFiltersByFormGroupControls(): AvailableFilterDetail[] {
    return Object.keys(this.filtersFormGroup.controls)
      .filter((key: string) => this.allowedFilters.includes(key))
      .map((key: string) => {
        return this.filterService.getFilterDetails(key);
      })
  }

  getFilterLabel(key: string, label: string): string {
    return IncidentListComponent.filterLabel[key] || label;
  }

  getAvailableFilters() {
    this.filterService.getAvailableFilters('incidents', 'index', IncidentListComponent.pageName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ((value: FilterDropdownOption[]) => {
          //console.log('received available filters')
          this.availableFilterOptions = value.filter((item: any) => this.allowedFilters.includes(item.key));
          this.availableFiltersSet$.next(true);
        })
      });
  }

  calculateAllSelectControlsOptions(changedControlKey: string = '') {
    for (let control in this.filtersFormGroup.controls) {
      if (control != changedControlKey) {
        this.getOptions(this.filterService.getFilterDetails(control));
      }
    }
  }

  checkIfAddFilterIsEnabled() {
    this.addFilterEnabled = this.filtersCountFormGroup == this.filterService.getSelectedFilters(this.filtersResource)?.length;
  }

  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.getIncidents(this.pageSize, this.pageIndex);
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.getIncidents(this.pageSize, this.pageIndex);
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  private initializeControlsByReportParams() {
    this.filterService.getSelectedReportParams(this.filtersResource).forEach((reportParam) => {
      if (!this.filtersFormGroup.controls[reportParam.key]) {
        this.addFilterControl(reportParam.key, reportParam.value);
      }else{
        this.getOptions(this.filterService.getFilterDetails(reportParam.key));
      }
    })
  }

  private addStaticInitialFilters() {
    const filter = this.filterService.getFilterDetails('incident_statuses.state');
    if (filter) {
      this.addFilterControl(filter.computed_key, 'open');
      this.initialFilterSet = true;
      this.filterService.putFilterToSelectedParams(this.filtersResource, filter, ['open']);
    }
  }
}
