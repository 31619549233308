<nz-layout class="users-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'people']">Manage People</a></nz-breadcrumb-item>
        @if (user && !isLoading) {
          <nz-breadcrumb-item>{{ user.fullname() }}</nz-breadcrumb-item>
        }
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app', 'people']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    @if (isLoading) {
      <nz-spin nzSimple></nz-spin>
    }

    @if (user && !isLoading) {
      <div class="people-edit-header">
        <div>
          <h1>
            <app-avatar [user]="user" [size]="48"></app-avatar>
            <span>{{ user.fullname() }}</span>
          </h1>
          <span>
            <button nz-button
                    [nzDanger]="(user.attributes.active == true)"
                    nzType="link"
                    type="button"
                    (click)="onToggleActive()">
              {{ (user.attributes.active == true) ? 'Deactivate' : 'Activate' }}
            </button>
          </span>
        </div>
        <nz-divider></nz-divider>
      </div>

      @if (formGroup) {
        <form nz-form
              nzLayout="vertical"
              class="emp-form-wrapper"
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit()">
          <nz-tabset [nzAnimated]="false"
                     [nzSelectedIndex]="selectedTabIndex"
                     (nzSelectedIndexChange)="onTabChange($event)">
            <nz-tab nzTitle="Personal information">
              <div class="box">
                @for (field of personalFields(); track field.name) {
                  <nz-form-item [ngClass]="'emp-form-form-item form-item-' + field.name">
                    <nz-form-label [nzFor]="field.name" [nzRequired]="field.required">
                      {{field.label}}
                    </nz-form-label>
                    <nz-form-control>
                      @switch (field.type) {
                        @case ('boolean') {
                          <nz-switch [formControlName]="field.name"></nz-switch>
                        }
                        @case ('password') {
                          <input nz-input [formControlName]="field.name" [name]="field.name" type="password" />
                        }
                        @case ('phone') {
                          <app-phone-input [phone]="field.value" (phoneChanged)="onSetPhoneNumber($event, field.name)" />
                        }
                        @case ('selector') {
                          <nz-select [formControlName]="field.name"
                                      [nzAllowClear]="field?.isClearable ?? null"
                                      [nzLoading]="isLoadingOptions === field.name"
                                      (nzOpenChange)="onSelectOpenChange($event, field.name)">
                            @for (option of field.options; track option.label) {
                              <nz-option [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                            }
                          </nz-select>
                        }
                        @case ('text') {
                          <input nz-input [formControlName]="field.name" [name]="field.name" />

                          @if (field.name === 'email') {
                            <extra-emails [user]="user" (extraEmailsUpdated)="getUser(user.id, user.type)" />
                          }
                        }
                        @case ('wysiwyg') {
                          <froala-editor [formControlName]="field.name" />
                        }
                      }
                    </nz-form-control>
                  </nz-form-item>
                }
              </div>
            </nz-tab>

            <nz-tab nzTitle="Security">
              <div class="box">
                @for (field of securityFields(); track field.name) {
                  <nz-form-item [ngClass]="'emp-form-form-item form-item-' + field.name">
                    <nz-form-label [nzFor]="field.name" [nzRequired]="field.required">
                      {{field.label}}
                    </nz-form-label>
                    <nz-form-control>
                      @switch (field.type) {
                        @case ('boolean') {
                          <nz-switch [formControlName]="field.name"></nz-switch>
                        }
                        @case ('password') {
                          <input nz-input [formControlName]="field.name" [name]="field.name" type="password" />
                        }
                        @case ('phone') {
                          <app-phone-input [phone]="field.value" (phoneChanged)="onSetPhoneNumber($event, field.name)" />
                        }
                        @case ('selector') {
                          <nz-select [formControlName]="field.name"
                                      [nzAllowClear]="field?.isClearable ?? null"
                                      [nzLoading]="isLoadingOptions === field.name"
                                      (nzOpenChange)="onSelectOpenChange($event, field.name)">
                            @for (option of field.options; track option.label) {
                              <nz-option [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                            }
                          </nz-select>
                        }
                        @case ('text') {
                          <input nz-input [formControlName]="field.name" [name]="field.name" />

                          @if (field.name === 'email') {
                            <extra-emails [user]="user" (extraEmailsUpdated)="getUser(user.id, user.type)" />
                          }
                        }
                        @case ('wysiwyg') {
                          <froala-editor [formControlName]="field.name" />
                        }
                      }
                    </nz-form-control>
                  </nz-form-item>
                }
              </div>
            </nz-tab>

            <nz-tab nzTitle="Notifications">
              <div class="box box-notifications">
                <nz-form-item ngClass="emp-form-form-item form-item-pref_incident_subscription">
                  <nz-form-label>Incident</nz-form-label>
                  <nz-form-control>
                    <nz-switch formControlName="pref_incident_subscription"></nz-switch>
                  </nz-form-control>
                </nz-form-item>

                <dl class="info-box">
                  <dt>ON</dt>
                  <dd>Enables notifications for incidents like system disruptions or critical issues, ensuring users are alerted promptly with updates and recommended actions.</dd>
                  <dt>OFF</dt>
                  <dd>Disables all incident notifications, which may delay awareness of urgent problems.</dd>
                </dl>

                <nz-form-item ngClass="emp-form-form-item form-item-pref_change_log_subscription">
                  <nz-form-label>Change</nz-form-label>
                  <nz-form-control>
                    <nz-switch formControlName="pref_change_log_subscription"></nz-switch>
                  </nz-form-control>
                </nz-form-item>

                <dl class="info-box">
                  <dt>ON</dt>
                  <dd>Activates notifications for system updates or changes, helping users stay informed about modifications that might affect their workflows.</dd>
                  <dt>OFF</dt>
                  <dd>Turns off change-related notifications, which may result in missed updates or announcements.</dd>
                </dl>
              </div>
            </nz-tab>
          </nz-tabset>

          <div class="actions-container">
            <button nz-button
                    type="submit"
                    nzType="primary"
                    nzShape="round"
                    nzSize="large"
                    [disabled]="formGroup && (!formGroup.valid || !formGroup.dirty)">
              Save & Close
            </button>
          </div>
        </form>
      }
    }
  </nz-content>
</nz-layout>

<custom-loader *ngIf="isLoaderVisible"></custom-loader>
