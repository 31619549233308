import { Component, DestroyRef, OnInit, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, forkJoin, skip, switchMap, take } from "rxjs";
import { AccountsService } from "src/app/services/accounts.service";
import { AssetResourcesService } from "src/app/services/assetResources.service";
import { DattoLicensesService } from "src/app/services/dattoLicenses.service";
import { ServiceHistoriesService } from "src/app/services/serviceHistories.service";
import { ServicesService } from "src/app/services/services.service";
import { UsersService } from "src/app/services/users.service";
import ServiceHelper from "src/app/utilities/helpers/serviceHelper";
import { Account } from "src/app/utilities/models/account/account";
import { AssetResource } from "src/app/utilities/models/assetResource/assetResource";
import { RansackParam } from "src/app/utilities/models/parameters/ransackParam/ransackParam";
import { DattoLicense } from "src/app/utilities/models/service/dattoLicense";
import InstalledServiceDetail from "src/app/utilities/models/service/installedServiceDetail";
import Service from "src/app/utilities/models/service/service";
import { ColumnItem, DataItem } from "src/app/utilities/models/service/serviceData";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import { ServiceHistory } from "src/app/utilities/models/serviceHistory/serviceHistory";
import { User } from "src/app/utilities/models/user/user";

@Component({
    selector: 'app-services-history',
    templateUrl: './services-history.component.html',
    styleUrls: ['./services-history.component.scss'],
    standalone: false
})
export class ServicesHistoryComponent implements OnInit {
  loading: boolean = false;
  dataLoading = signal(false);
  serviceHistories: ServiceHistory[];
  bucket: {[key:string]: number[]} = {
    users: [],
    asset_resources: [],
    datto_licenses: [],
    installed_service_details: []
  }
  accountsFetched: boolean = false;
  accounts: Account[] = [];
  selectedAccountId:number | undefined;
  dateRange: Date[];
  serviceChanges: {
    installed_service_details: { [key: number]: InstalledServiceDetail };
    users: { [key: number]: User };
    asset_resources: { [key: number]: AssetResource };
    datto_licenses: { [key: number]: DattoLicense };
  } = {
    installed_service_details: {},
    users: {},
    asset_resources: {},
    datto_licenses: {},
  }
  accountsLoading: boolean = false;
  serviceableInformation: {
    [key: string]: {
      data: {
          action: string;
          id: number;
          type: string;
          attributes: any;
          createdAt: string | undefined;
          className?: string;
      }[],
      keys: string[],
      columns: ColumnItem[],
      serviceMetric: ServiceMetric,
      service: Service | undefined,
    }
  } = {};
  services: Service[];
  user: User;
  isAgent: boolean = false;
  searchSubject$ = new BehaviorSubject<string>('');
  isForbidden: boolean = false;

  constructor(private serviceHistoryService: ServiceHistoriesService,
              private serviceService: ServicesService,
              private accountService: AccountsService,
              private userService: UsersService,
              private assetResourceService: AssetResourcesService,
              private dattoLicenseService: DattoLicensesService,
              private destroyRef: DestroyRef,
              private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    if(this.user.attributes.type == 'Ticketing::Agent'){
      this.isAgent = true;
      this.observeAccountSearch();
    }else{
      this.selectedAccountId = this.user.relationships?.account?.id;
    }
    this.getServices();

    this.activatedRoute.queryParamMap
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (params: ParamMap) => {
          const start_date = params.get('start_date') ?? params.get('date_start');
          const end_date = params.get('end_date') ?? params.get('date_end');

          if (start_date !== null && end_date !== null) {
            const startDate = new Date(start_date);
            const endDate = new Date(end_date);

            if (!isNaN(startDate.valueOf()) && !isNaN(endDate.valueOf())) {
              this.dateRange = [startDate, endDate];
              this.getServiceHistory();
            }
          }
        }
      }
    );
  }

  observeAccountSearch() {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          return this.accountService.getCustomerAccounts(this.searchSubject$.getValue())
        })
      )
      .subscribe({
        next: (response: any) => {
          this.accountsFetched = true;
          this.accounts = response.data.map((row: any) => new Account(row, response.included));
          this.accountsLoading = false;
        },
        error: (error) => {
          this.accountsLoading = false;
          console.log(error);
        }
      });
  }

  setAccountSearchStr(value: string) {
    this.searchSubject$.next(value);
  }

  getAccounts(value?: string) {
    if(this.accountsFetched) return;

    this.accountsLoading = true;
    this.accountService.getCustomerAccounts(value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.accountsFetched = true;
          this.accounts = response.data.map((row: any) => new Account(row, response.included));
          this.accountsLoading = false;
        },
        error:(error) => {
          this.accountsLoading = false;
          console.log(error);
        }
      })
  }

  getDateAttribute(d: Date) {
    return d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate();
  }

  getServiceHistory() {
    if(!this.selectedAccountId || !this.dateRange?.length) return;

    this.loading = true;
    const params = [
      new RansackParam('account_id','eq', this.selectedAccountId.toString())
    ];
    if(this.dateRange?.length > 1){
      params.push(
        new RansackParam('created_at','gteq', this.getDateAttribute(this.dateRange[0]))
      )
      params.push(
        new RansackParam('created_at','lteq', this.getDateAttribute(this.dateRange[1]))
      )
    }
    this.serviceHistoryService.getServiceHistories(10000, 1, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.serviceHistories = response.data.map((row: any) => new ServiceHistory(row, response.included));
          this.getHistoryDetails();
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;

          if (error?.status === 403) {
            this.isForbidden = true;
          }
        },
      })
  }

  private getHistoryDetails() {
    this.resetServiceChanges();
    this.serviceHistories.forEach(serviceHistory => {
      if(serviceHistory.relationships?.serviceable?.type){
        const type = serviceHistory.relationships.serviceable.type == 'installed_service_details' ? serviceHistory.relationships.serviceable.type : this.getSectionType(serviceHistory.relationships.serviceable.type, serviceHistory);
        if(!this.bucket[type].includes(serviceHistory.relationships?.serviceable?.id)){
          this.bucket[type].push(serviceHistory.relationships?.serviceable?.id);
        }
      }
    })

    this.loading = true;
    this.dataLoading.set(true);

    const requests = [];
    const requestKeys: string[] = [];
    if(this.bucket['installed_service_details']?.length) {
      requests.push(this.serviceService.getInstalledServiceDetailsByIds(this.bucket['installed_service_details']));
      requests.push(this.serviceService.getDeletedInstalledServiceDetailsByIds(this.bucket['installed_service_details']));
      requestKeys.push('installed_service_details');
      requestKeys.push('installed_service_details');
    }
    if(this.bucket['users']?.length) {
      requests.push(this.userService.getUserByIdsIncludeDeleted(this.bucket['users']));
      requestKeys.push('users');
    }
    if(this.bucket['asset_resources']?.length) {
      requests.push(this.assetResourceService.getAssetResourcesByIdsIncludeDeleted(this.bucket['asset_resources']));
      requestKeys.push('asset_resources');
    }
    if(this.bucket['datto_licenses']?.length) {
      requests.push(this.dattoLicenseService.getDattoLicensesByIdsIncludeDeleted(this.bucket['datto_licenses']));
      requestKeys.push('datto_licenses');
    }
    forkJoin(requests)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (responses: any[] ) => {
          responses.forEach((response: any, index: number) => {
            if(requestKeys[index] == 'installed_service_details') {
              response.data.forEach((row: any) => {
                this.serviceChanges['installed_service_details'][row.id] = new InstalledServiceDetail(row, response.included);
              });
            }
            if(requestKeys[index] == 'users') {
              response.data.forEach((row: any) => {
                this.serviceChanges['users'][row.id] = new User(row, response.included);
              });
            }
            if(requestKeys[index] == 'asset_resources') {
              response.data.forEach((row: any) => {
                this.serviceChanges['asset_resources'][row.id] = new AssetResource(row, response.included);
              });
            }
            if(requestKeys[index] == 'datto_licenses') {
              response.data.forEach((row: any) => {
                this.serviceChanges['datto_licenses'][row.id] = new DattoLicense(row, response.included);
              });
            }
          })

          this.loading = false;
          this.dataLoading.set(false);
        },
        error: (error) => {
          console.log(error)
        },
        complete: () => {
          this.bindDataToServiceHistories();
        }
      });
  }

  resetServiceChanges() {
    this.serviceChanges['installed_service_details'] = {};
  }

  getInstalledServiceDetails() {
    this.serviceService.getInstalledServiceDetailsByIds(this.bucket['installed_service_details'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          response.data.forEach((row: any) => {
            this.serviceChanges['installed_service_details'][row.id] = new InstalledServiceDetail(row, response.included);
          });
        },
        error: (error: any) => { console.log(error);},
        complete: () => {},
      });
  }

  getUsers() {
    this.userService.getUserByIds(this.bucket['users'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          response.data.forEach((row: any) => {
            this.serviceChanges['users'][row.id] = new User(row, response.included);
          });
        },
        error: (error: any) => { console.log(error);},
        complete: () => {},
      });
  }

  getAssetResources() {
    this.assetResourceService.getAssetResourcesByIds(this.bucket['deskware_tech_assets'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          response.data.forEach((row: any) => {
            this.serviceChanges['asset_resources'][row.id] = new AssetResource(row, response.included);
          });
        },
        error: (error: any) => { console.log(error);},
        complete: () => {},
      });
  }

  getDattoLicenses() {
    this.dattoLicenseService.getDattoLicensesByIds(this.bucket['datto_licenses'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          response.data.forEach((row: any) => {
            this.serviceChanges['datto_licenses'][row.id] = new DattoLicense(row, response.included);
          });
        },
        error: (error: any) => { console.log(error);},
        complete: () => {},
      });
  }

  private bindDataToServiceHistories() {
    this.loading = true;
    this.dataLoading.set(true);
    this.serviceHistories.forEach((serviceHistory: ServiceHistory) => {
      if(serviceHistory.relationships?.serviceable?.type == 'installed_service_details') {
        serviceHistory.relationships.serviceable = this.serviceChanges['installed_service_details'][serviceHistory.relationships?.serviceable?.id];
      }
      if(serviceHistory.relationships?.serviceable?.type == 'deskware_tech_assets') {
        serviceHistory.relationships.serviceable = this.serviceChanges['asset_resources'][serviceHistory.relationships?.serviceable?.id];
      }
      if(
        serviceHistory.relationships?.serviceable?.type == 'users' ||
        serviceHistory.relationships?.serviceable?.type == 'account_agents' ||
        serviceHistory.relationships?.serviceable?.type == 'contacts' ||
        serviceHistory.relationships?.serviceable?.type == 'agents'

      ) {
        serviceHistory.relationships.serviceable = this.serviceChanges['users'][serviceHistory.relationships?.serviceable?.id];
      }
      if(serviceHistory.relationships?.serviceable?.type == 'datto_licenses') {
        serviceHistory.relationships.serviceable = this.serviceChanges['datto_licenses'][serviceHistory.relationships?.serviceable?.id];
      }
    })
    this.loading = false;
    this.dataLoading.set(false);
    this.normalizeServiceableInformation();
  }

  private normalizeServiceableInformation() {
    this.serviceableInformation = {};
    this.loading = true;
    this.dataLoading.set(true);
    this.serviceHistories.forEach((serviceHistory: ServiceHistory) => {
      if(serviceHistory.relationships?.serviceable?.id && serviceHistory.relationships?.serviceable?.type) {
        const type = this.getSectionType(serviceHistory.relationships.serviceable.type, serviceHistory);
        if(!serviceHistory.relationships?.service_metric?.id){
          return;
        }
        if(!this.serviceableInformation[type]){
          const serviceMetric = new ServiceMetric({ ...serviceHistory.relationships.service_metric }, []);
          const { keys, columns } = serviceMetric.getColumnAndKeys();
          this.serviceableInformation[type] = {
            keys: keys,
            columns: columns,
            serviceMetric: serviceMetric,
            service: this.getServiceByServiceMetric(serviceMetric.id),
            data: [],
          };
        }
        const norm = this.getNormalizedData(type, serviceHistory);
        this.serviceableInformation[type].data.push({
          action: serviceHistory.attributes.action,
          id: serviceHistory.relationships.serviceable.id,
          type: serviceHistory.relationships.serviceable.type,
          attributes: norm?.attributes,
          createdAt: serviceHistory.attributes.created_at,
          className: this.getClassName(serviceHistory.attributes.action, serviceHistory.relationships.serviceable.type, norm?.attributes)
        })
      }
    });
    this.loading = false;
    this.dataLoading.set(false);
  }

  private getClassName(action: string, type: string, attributes: any) {
    if(type == 'users'){
      switch(action) {
        case 'create': return 'added';
        case 'destroy': return 'deleted';
        case 'update':
          if(attributes.active == true){
            return 'activated';
          }else {
            return 'deactivated';
          }
          break;
        default:
          return action === 'create' ? 'added' : 'deleted'
      }
    }else{
      return action === 'create' ? 'added' : 'deleted'
    }

  }

  private getNormalizedData(type: string, serviceHistory: ServiceHistory) {
    switch(type) {
      case 'users':
        return ServiceHelper.normalizeManagedUsersServiceData(serviceHistory.relationships?.serviceable as User);
      case 'asset_resources':
        return ServiceHelper.normalizeManagedEndpointsServiceData(serviceHistory.relationships?.serviceable as AssetResource);
      case 'datto_licenses':
        return ServiceHelper.normalizeSaasProtectionServiceData(serviceHistory.relationships?.serviceable as DattoLicense);
      case 'installed_service_details':
        return serviceHistory.relationships?.serviceable;
      default:
        return serviceHistory.relationships?.serviceable;
    }
  }

  displayValue(row: DataItem, attr: string, purpose: string = '', serviceMetric: ServiceMetric) {
    return this.serviceService.displayValue(row, attr, purpose, serviceMetric);
  }

  private getSectionType(type: string, serviceHistory: ServiceHistory): string {
    switch(type) {
      case 'users':
      case 'agents':
      case 'account_agents':
      case 'contacts':
        return 'users';
      case 'asset_resources':
      case 'deskware_tech_assets':
        return 'asset_resources';
      case 'installed_service_details':
        return serviceHistory.relationships?.service_metric?.attributes.title ?? '';
      default:
        return type;
    }
  }

  getSectionTitle(item: any) {
    return item.service?.attributes.title ?? item.type;
  }

  private getServices(pageSize: number = 1000, pageIndex: number = 1) {
    const params: RansackParam[] = [];
    this.serviceService.getServices(pageSize, pageIndex, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.services = response.data.map((bsData: any) => new Service(bsData, response.included));
        },
        error: (error) => {
          if (error?.status === 403) {
            this.isForbidden = true;
          }
        }
      })
  }

  private getServiceByServiceMetric(serviceMetricId: number) {
    return this.services.find((service: Service) => service.relationships?.service_metrics?.some(serviceMetric => serviceMetric.id == serviceMetricId))
  }
}