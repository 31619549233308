<nz-layout class="users-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'people']">Manage People</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Add New Person</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','people']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div class="people-create-header">
      <h1>Add new person</h1>
      <nz-divider></nz-divider>
    </div>

    @if (formGroup) {
      <form nz-form
            nzLayout="vertical"
            class="emp-form-wrapper"
            [formGroup]="formGroup"
            (ngSubmit)="onSubmit()">
        <div class="box">
          @for (field of fields; track field.name) {
            <nz-form-item [ngClass]="'emp-form-form-item form-item-' + field.name">
              <nz-form-label [nzFor]="field.name" [nzRequired]="field.required">
                {{field.label}}
              </nz-form-label>
              <nz-form-control>
                @switch (field.type) {
                  @case ('phone') {
                    <app-phone-input [phone]="field.value" (phoneChanged)="onSetPhoneNumber($event, field.name)" />
                  }
                  @case ('selector') {
                    <nz-select [formControlName]="field.name"
                                [nzLoading]="isLoadingOptions === field.name"
                                (nzOpenChange)="onSelectOpenChange($event, field.name)">
                      @for (option of field.options; track option.label) {
                        <nz-option [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                      }
                    </nz-select>
                  }
                  @case ('text') {
                    <input nz-input [formControlName]="field.name" [name]="field.name" />
                  }
                }
              </nz-form-control>
            </nz-form-item>
          }
        </div>

        <div class="actions-container">
          <button nz-button
                  type="submit"
                  nzType="primary"
                  nzShape="round"
                  nzSize="large"
                  [disabled]="formGroup && !formGroup.valid">
            Save & Close
          </button>
        </div>
      </form>
    }
  </nz-content>
</nz-layout>

<custom-loader *ngIf="isLoaderVisible"></custom-loader>
