import { Component, DestroyRef, OnInit } from '@angular/core';
import { AuthService } from "src/app/auth/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { environment } from "src/environments/environment";
import { NzModalService } from "ng-zorro-antd/modal";
import { SetPasswordFormComponent } from "./setPasswordForm/setPasswordForm.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import packageJson from '../../../../package.json';
import * as Sentry from '@sentry/angular';

declare global {
  interface Window { Appcues: any; }
}

@Component({
    selector: 'mainApp',
    templateUrl: './mainApp.component.html',
    styleUrls: ['./mainApp.component.scss'],
    standalone: false
})
export class MainAppComponent implements OnInit {
  appLoaded: boolean = false;
  appVersion: string = '';

  constructor(private authService: AuthService,
              private userService: UsersService,
              private router: Router,
              private modal: NzModalService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.appVersion = 'v'+packageJson.version.toString();
    if (this.userService.isAppReady()) {
      this.appLoaded = true;

      if (environment.production) {
        Sentry.setUser({
          id: this.userService.loggedInUser.id,
          email: this.userService.loggedInUser.attributes.email,
        });
      }
    } else {
      this.userService.prepareApp()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          {
            next: _ => {
              this.appLoaded = true;
              if(this.userService.showPasswordModal) {
                this.createSetPasswordModal();
              }

              if (environment.enableAppcues && environment.appcuesAccountId) {
                this.appCuesLoadUser();
              }
            }
          }
        );
    }

    if (environment.production) {
      this.addHotjarScript();
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/info']).then(_ => false);
  }

  private addHotjarScript() {
    const hotJarScript = document.createElement('script');
    hotJarScript.innerHTML = `(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:3411971,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.head.appendChild(hotJarScript);
  }

  private loadAppCuesScript() {
    return new Promise(function (resolve, reject) {
      const scriptUrl = `//fast.appcues.com/${environment.appcuesAccountId}.js`;
      const script = document.createElement('script');
      script.src = scriptUrl;

      script.addEventListener('load', function () {
        resolve(true);
      });

      script.addEventListener('error', function () {
        reject(false);
      });

      document.body.appendChild(script);
    });
  }

  private async appCuesLoadUser() {
    const loaded = await this.loadAppCuesScript();

    if (!loaded || !window.Appcues?.identify) return;

    const user = this.userService.loggedInUser;
    window.Appcues.identify(`empist-360-user-id_${user.id}`, {
      accountTitle: user.relationships?.account?.attributes.title,
      email: user.attributes.email,
      name: user.fullname(),
      otpEnabled: user.attributes.otp_enabled,
      timeZone: user.attributes.time_zone,
      userId: user.id,
      userRole: user.relationships?.role?.attributes.system_key,
      userType: user.attributes.type,
      preferences: {
        darkMode: user.attributes.pref_dark_mode,
      },
    });

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          window.Appcues && window.Appcues.page();
        }
      });
  }

  createSetPasswordModal() {
    this.modal.create({
      nzTitle: '',
      nzFooter: null,
      nzClassName: 'set-password-form',
      nzMaskClosable: false,
      nzClosable: true,
      nzContent: SetPasswordFormComponent,
    });
  }
}
