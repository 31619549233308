import { Component, OnInit, Input, Renderer2, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommentCreateDto } from "src/app/utilities/models/dto/commentCreateDto";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { CommentsService } from "src/app/services/comments.service";
import { UsersService } from "src/app/services/users.service";
import { ConversationsService } from "src/app/services/conversations.service";
import { Upload } from "src/app/utilities/models/upload/upload";

@Component({
    selector: 'commentForm',
    templateUrl: './commentForm.component.html',
    styleUrls: ['./commentForm.component.scss'],
    standalone: false
})
export class CommentFormComponent implements OnInit, OnDestroy {
  @Input() conversationId: number;
  @Input() ticketId: number;
  avatar: string | undefined;
  editorOptions: Object;
  commentForm: FormGroup;
  submitting: boolean = false;
  permission: Permission | undefined;
  editor: any;
  uploads: Upload[] = [];

  constructor(private commentService: CommentsService,
              private userService: UsersService,
              private conversationService: ConversationsService,
              private renderer: Renderer2,
              private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.avatar = this.userService.loggedInUser.attributes?.avatar;
    this.editorOptions = {
      events: {
        'keydown': (e: any) => {
          if (e.ctrlKey && e.key === 'Enter') {
            this.onSubmit();
          }
        }
      },
      toolbarButtons:['bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertTable', 'html', 'insertImage', 'insertFile'],
    };
    this.permission = this.userService.findPermission('Ticketing::Comment', 'ticketing/operator/v1/comments', 'create');
    this.commentForm = new FormGroup({
      'description': new FormControl<string | undefined>({ value: undefined, disabled: this.disableByProperty('description') }, Validators.required)
    });
    this.renderer.setAttribute(document.body, 'class', 'has-comment-form');
  }

  ngOnDestroy() {
    this.renderer.setAttribute(document.body, 'class', '');
  }

  preparePayload(): CommentCreateDto {
    return new CommentCreateDto(this.commentForm.value.description, this.conversationId, this.ticketId, this.userService.loggedInUser, this.uploads);
  }

  onFileUploaded(upload: Upload) {
    this.uploads.push(upload);
    this.ref.detectChanges();
  }

  onSubmit() {
    if (!this.commentForm.valid) return;

    this.submitting = true;
    const payload: CommentCreateDto = this.preparePayload();

    this.commentService.createComment(payload).subscribe({
      next: (_response: any) => {
        this.submitting = false;
        this.resetForm();
        this.resetUploads();
        this.conversationService.setActiveConversation(this.conversationId);
      }
    });
  }

  resetForm() {
    this.commentForm.reset();
  }

  resetUploads() {
    this.uploads = [];
    this.ref.detectChanges();
  }

  removeUpload(upload: Upload) {
    const uploadIndex = this.uploads.findIndex((item: Upload) => item.id == upload.id);
    this.uploads.splice(uploadIndex, 1);
    this.ref.detectChanges();
  }

  private disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }
}
