import { Component, DestroyRef, OnInit } from "@angular/core";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { TicketsService } from "src/app/services/tickets.service";
import { RelationshipData } from "src/app/utilities/models/relationshipData";
import { UploadRelationAttribute } from "src/app/utilities/models/ticket/uploadRelationAttribute";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: 'ticket-attachments',
    templateUrl: './ticketAttachments.component.html',
    styleUrls: ['./ticketAttachments.component.scss'],
    standalone: false
})
export class TicketAttachmentsComponent implements OnInit {
  ticket: Ticket;

  constructor(private ticketService: TicketsService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.ticket = this.ticketService.activeTicket;
    this.ticketService.ticket
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ticketResponse: Ticket) => {
          this.ticket = ticketResponse;
        }
      });
  }

  isImage(upload: RelationshipData<UploadRelationAttribute>) {
    let isImage = false;
    switch(upload.attributes.content_type) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/webp':
      case 'image/avif':
        isImage = true;
        break;
    }

    return isImage;
  }
}