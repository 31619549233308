<nz-layout class="users-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Manage People</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div class="people-list-header">
      <div class="page-title">
        <h1>Manage People</h1>
        <div>
          @if (userCreatePermission) {
            <button nz-button nzShape="round" class="btn-black" [routerLink]="['/app', 'people', 'new']">
              <span nz-icon nzType="plus"></span>
              Add a person
            </button>
          }
        </div>
      </div>

      <div class="page-subtitle">
        <div>
          <p>Manage users effortlessly with features like user list, detailed single-user pages, editing options, and the ability to create new users.</p>
        </div>
        <div>
          <div class="people-list-summary">
            <p>Showing {{ ((pageIndex * pageSize) + 1 - pageSize) }}-{{ (pageSize > total) ? total : (pageIndex * pageSize) }} of {{ total }} people</p>
            <div class="people-filter-active">
              <button type="button">
                <b>Active</b>
                <span>
                  <nz-switch nzSize="small" [(ngModel)]="filterActive" (ngModelChange)="getPeople()"></nz-switch>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <nz-input-group nzSearch
                      [nzPrefix]="prefixIcon"
                      [nzSuffix]="suffixIconButton"
                      nzSize="large"
                      class="people-search">
        <input type="text"
                nz-input
                placeholder="Search for people"
                [(ngModel)]="searchString"
                (ngModelChange)="onSearchPeople()" />
      </nz-input-group>

      <ng-template #prefixIcon>
        <span nz-icon nzType="search"></span>
      </ng-template>

      <ng-template #suffixIconButton>
        <button nz-button nzSearch nzShape="circle" nzType="primary">
          <span nz-icon nzType="right" (click)="getPeople()"></span>
        </button>
      </ng-template>
    </div>

    <div nz-row class="people-list-container">
      <div nz-col nzSpan="24">
        <nz-table [nzData]="users"
                  [nzFrontPagination]="false"
                  [nzLoading]="isLoading"
                  [nzTotal]="total"
                  [nzPageSize]="pageSize"
                  [nzPageIndex]="pageIndex"
                  [nzBordered]="false"
                  [nzShowSizeChanger]="true"
                  (nzPageIndexChange)="onPageIndexChange($event)"
                  (nzPageSizeChange)="onPageSizeChange($event)">
          <thead>
            <tr>
              <th colspan="2"
                  nzColumnKey="firstname"
                  [nzShowSort]="isSortable()"
                  [nzSortDirections]="['ascend', 'descend']"
                  [nzSortFn]="true"
                  [nzSortOrder]="(sort.attribute == 'firstname' ? sort.order : null)"
                  (nzSortOrderChange)="onSortOrderChange($event, 'firstname')">Name</th>
              <th nzColumnKey="role.title"
                  [nzShowSort]="isSortable()"
                  [nzSortDirections]="['ascend', 'descend']"
                  [nzSortFn]="true"
                  [nzSortOrder]="(sort.attribute == 'role.title' ? sort.order : null)"
                  (nzSortOrderChange)="onSortOrderChange($event, 'role.title')">Role</th>
              <th nzColumnKey="price_tier.title"
                  [nzShowSort]="isSortable()"
                  [nzSortDirections]="['ascend', 'descend']"
                  [nzSortFn]="true"
                  [nzSortOrder]="(sort.attribute == 'price_tier.title' ? sort.order : null)"
                  (nzSortOrderChange)="onSortOrderChange($event, 'price_tier.title')">Type</th>
            </tr>
          </thead>
          <tbody>
            @for (user of users; track user.id) {
              <tr>
                <td class="avatar">
                  <app-avatar [user]="user" [size]="40" />
                </td>
                <td>
                  <p>
                    <a [routerLink]="(canViewUser() ? ['/app', 'people', user.id, user.type] : null)">
                      {{ user.attributes.firstname }} {{ user.attributes.lastname }}
                    </a>
                  </p>
                  @if (user.attributes.job_title) {
                    <p class="job-title">{{ user.attributes.job_title }}</p>
                  }
                </td>
                <td>{{ user.relationships?.role?.attributes?.title ?? '-' }}</td>
                <td>{{ user.relationships?.price_tier?.attributes?.title ?? '-' }}</td>
              </tr>
            }
          </tbody>
        </nz-table>
      </div>
    </div>
  </nz-content>
</nz-layout>
