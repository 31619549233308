import { Component, DestroyRef, OnInit } from '@angular/core';
import { Subject, forkJoin } from "rxjs";
import Service from "src/app/utilities/models/service/service";
import { ServicesService } from "src/app/services/services.service";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import InstalledService from "src/app/utilities/models/service/installedService";
import InstalledServiceValue from "src/app/utilities/models/service/installedServiceValue";
import { ColumnItem, DataItem } from "src/app/utilities/models/service/serviceData";
import { UsersService } from "src/app/services/users.service";
import { AssetResourcesService } from "src/app/services/assetResources.service";
import { InstalledServiceUpdateDto } from "src/app/utilities/models/dto/installedServiceUpdateDto";
import { AccountServicesData, AccountDataInstalledService } from "src/app/utilities/models/accountServicesData";
import { AggregationData } from "src/app/utilities/models/aggregationData";
import { User } from "src/app/utilities/models/user/user";
import { StaticFilterOption } from "src/app/utilities/models/service/staticFilterOption";
import { AccountReviewUsersDto } from "src/app/utilities/models/dto/accountReviewUsersDto";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { IntegrationLastSync } from "src/app/utilities/models/integrationLastSync";
import { AccountReviewDattoLicensesDto } from "src/app/utilities/models/dto/accountReviewDattoLicensesDto";
import { DattoLicensesService } from "src/app/services/dattoLicenses.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import InstalledServiceDetail from "src/app/utilities/models/service/installedServiceDetail";
import installedService from "src/app/utilities/models/service/installedService";
import { AssetResource } from "src/app/utilities/models/assetResource/assetResource";
import * as XLSX from "xlsx";
import { AccountsService } from 'src/app/services/accounts.service';

interface ServiceMetricColumn {
  column: string;
  key: string;
  label: string;
  type: string;
}

@Component({
    selector: 'app-services-audit',
    templateUrl: './services-audit.component.html',
    styleUrls: ['./services-audit.component.scss'],
    standalone: false
})
export class ServicesAuditComponent implements OnInit {
  services: Service[];
  serviceMetrics: ServiceMetric[];
  installedServices: InstalledService[] = [];
  installedServiceValues: InstalledServiceValue[] = [];
  loading: boolean = false;
  detailsLoading: boolean = false;
  openDetailsMetricId: number | undefined;
  openDetailsInstalledServiceId: number | undefined;
  openDetailsServiceId: number | undefined;
  openMetricDetails: InstalledServiceDetail[] | [];
  openMetricTitle: string | undefined;
  columns: ColumnItem[];
  keys: string[];
  accountSearchInput: string = '';
  managedUsersService: Service;
  managedEndpointsService: Service;
  saasProtectionService: Service;
  openMetricAccountId: number | undefined;
  accountServicesList: Map<number, AccountServicesData> = new Map();
  accounts: { id: number, title: string }[] = [];
  accountArrayMap: any[];
  accountArrayWithChangesMap: any[];
  filteredAccountArrayMap: any[];
  installedServiceUpdatePermission: Permission | undefined;
  accountReviewUsersPermission: Permission | undefined;
  accountReviewAssetsPermission: Permission | undefined;
  loggedInUser: User;
  showOnlyAccountsWithChanges: boolean = false;
  staticFilterInUse: string = 'all';
  staticFilterOptions: {id: number, label: string, count: number, deletedCount?: number}[] = [];
  selectedFilterOption: {id: number, label: string} = { id: 0, label:'All'};
  priceTierOptions: any[] = [];
  syncData: IntegrationLastSync[] = [];
  syncDataLoading: boolean = false;
  lastSyncNormalizedData: {title: string; date: Date; status: string}[];
  totalCpu: number;
  totalRamInMB: number;
  openServiceFilteredDetails: InstalledServiceDetail[] = [];
  saasProtectionIsEnabled: boolean = true;
  showOnlyChanges: boolean = false;

  staticFilter$ = new Subject<{id: number, label: string, count: number}>();
  showChangedSelection = 'all';
  showChanged$ = new Subject<string>();
  staticServiceIds: number[] = [];
  openDetailsKey: string = '';
  userServices: any = {};
  userServiceLoading: number | undefined = undefined;

  constructor(private serviceService: ServicesService,
              private userService: UsersService,
              private assetResourceService: AssetResourcesService,
              private dattoLicenseService: DattoLicensesService,
              private accountsService: AccountsService,
              private destroyRef: DestroyRef) {
  }

  get openServiceDetailsHasChanges(): boolean {
    return this.openMetricDetails?.some((row: any) => row.attributes['reviewed'] == false)
  }

  get openServiceDetailsHasMarkedForDelete(): boolean {
    return this.openMetricDetails?.some((row: any) => row.attributes['mark_for_deletion'] == true)
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    this.installedServiceUpdatePermission = this.userService.findPermission('Digcore::InstalledService', 'ticketing/operator/v1/installed_services', 'update');
    this.accountReviewUsersPermission = this.userService.findPermission('Digcore::Account', 'ticketing/operator/v1/accounts', 'review_users');
    this.accountReviewAssetsPermission = this.userService.findPermission('Digcore::Account', 'ticketing/operator/v1/accounts', 'review_assets');
    this.prepareAccountServicesData();

    this.staticFilter$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: {id: number, label: string, count: number}) => {
          this.selectedFilterOption = value;
          this.filterDetails()
        },
        error:(error) => { console.log(error); },
        complete:() => {},
      })

    this.showChanged$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: string) => {
          this.showChangedSelection = value;
          this.filterDetails()
        },
        error:(error) => { console.log(error); },
        complete:() => {},
      })
  }

  getLogoByServiceId(id: number | undefined) {
    if(id){
      const service = this.services.find((s) => s.id == id);
      if(service){
        return service.getLogo();
      }
    }

    return '';
  }

  getLogoByService(service: Service | undefined): string{
    const logo = service ? service.getLogo() : '';

    return logo ? logo?.includes('https://') ? logo : '../../../' + logo : '';
  }

  getManagedUsersAccountData(docCount?: number) {
    const installedService: any = {
      id: 10001,
      title: this.managedUsersService.attributes.title,
      reviewed: true,
      service: this.managedUsersService,
      detailMetrics: this.getDetailsMetrics(this.managedUsersService.id).map(metric => {
        return {
          id: metric.id,
          title: metric.getTitle(),
          metric_group: metric.attributes.metric_group ?? '',
          value: []
        }
      })
    }

    return installedService;
  }

  getManagedEndpointsAccountData(docCount?: number) {
    const installedService: any = {
      id: 10002,
      title: this.managedEndpointsService.attributes.title,
      reviewed: true,
      service: this.managedEndpointsService,
      detailMetrics: this.getDetailsMetrics(this.managedEndpointsService.id).map(metric => {
        return {
          id: metric.id,
          title: metric.getTitle(),
          metric_group: metric.attributes.metric_group ?? '',
          value: []
        }
      })
    }

    return installedService;
  }

  getSaasProtectionAccountData() {
    const installedService: any = {
      id: 10003,
      title: this.saasProtectionService.attributes.title,
      reviewed: true,
      service: this.saasProtectionService,
      detailMetrics: this.getDetailsMetrics(this.saasProtectionService.id).map(metric => {
        return {
          id: metric.id,
          title: metric.getTitle(),
          metric_group: metric.attributes.metric_group ?? '',
          value: []
        }
      })
    }

    return installedService;
  }

  getServiceMetricTitle(str: string): string {
    return ServiceMetric.getTitleFromString(str);
  }

  getDetailsMetrics(serviceId: number | undefined) {
    return this.serviceService.getDetailsMetrics(serviceId);
  }

  getCountMetricValue(installedService: InstalledService, serviceMetric: ServiceMetric) {
    return this.serviceService.getCountMetricValue(installedService, serviceMetric, this.installedServiceValues);
  }

  showMetricDetails(installedServiceId: number, service: Service, metricId: number, accountId: number) {
    this.staticFilterInUse = '';
    this.showOnlyChanges = false;
    this.selectedFilterOption = { id: 0, label: 'All' };
    this.staticFilterOptions = [];
    const metric = this.serviceMetrics.find(serviceMetric => serviceMetric.id == metricId);
    this.openDetailsMetricId = metric?.id || undefined;
    this.openDetailsInstalledServiceId = installedServiceId;
    this.openDetailsServiceId = this.services.find((serviceItem: Service) => serviceItem.id = service.id)?.id;

    this.openDetailsKey = accountId+':'+service.id+':'+installedServiceId+':'+metricId;

    this.detailsLoading = true;
    this.openMetricAccountId = accountId;
    if (service.attributes.system_key == 'digcore-users') { // managed users (deskware users)
      this.getUsersStaticFilterOptions();
      this.staticFilterInUse = 'price_tier';
      return this.getAccountManagedUsers(accountId);
    }
    if (service.attributes.system_key == 'ticketing-assets') { // managed endpoints (assets)
      this.staticFilterInUse = 'type';
      this.staticFilterOptions = [
        new StaticFilterOption(0, 'All', 0 ),
        new StaticFilterOption(15, 'Workstation', 0 ),
        new StaticFilterOption(52, 'Server', 0 )
      ];
      return this.getAccountManagedEndpoints(accountId);
    }
    if(service.attributes.system_key == 'digcore-datto-license') { // datto licenses
      this.staticFilterInUse = 'status';
      this.staticFilterOptions = [
        new StaticFilterOption(0, 'All', 0 ),
        new StaticFilterOption(1, 'Active', 0 ),
        new StaticFilterOption(2, 'Archived', 0 )
      ];
      return this.getAccountSaasProtectionSeats(accountId);
    }

    this.serviceService.getInstalledServiceDetailsIncludeDeleted(installedServiceId, metricId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.openMetricDetails = response.data.map((row: any) => new InstalledServiceDetail(row, response.included));
          this.openServiceFilteredDetails = this.openMetricDetails ? [...this.openMetricDetails] : [];

          const openInstalledService = this.installedServices.find((installedService: InstalledService) => installedService.id == this.openDetailsInstalledServiceId)
          if(openInstalledService && this.openDetailsInstalledServiceId){
            const openService = this.services.find((service: Service) => service.id == openInstalledService.relationships?.service?.id);
            if (openService) {

              const serviceMetric = this.serviceMetrics.find(sm => sm.id == this.openDetailsMetricId);
              const data: any[] = this.openMetricDetails?.map((row: any, index: number) => {
                if(serviceMetric?.attributes.title?.includes('VMware vCenter: Datastores')){
                  row.attributes.col8 = row.attributes.col5 - row.attributes.col4;
                }
                return {
                  id: index,
                  ...row
                }
              }) ?? [];

              if(serviceMetric?.attributes.title == 'VMware vCenter: Virtual Machines'){
                this.totalCpu = 0;
                this.totalRamInMB = 0;
                data.forEach((row: any) => {
                  this.totalCpu += parseInt(row.attributes.col5);
                  this.totalRamInMB += parseInt(row.attributes.col1);
                })
              }
            }
          }

          if (this.openMetricDetails?.length) {
            this.setColumnsAndKeys();
          }
          this.detailsLoading = false;
        }
      })
  }

  hideMetricDetails() {
    this.openDetailsKey = '';
    this.openDetailsMetricId = undefined;
    this.openDetailsMetricId = undefined;
    this.openDetailsInstalledServiceId = undefined;
    this.openMetricDetails = [];
    this.openServiceFilteredDetails = [];
    this.openMetricAccountId = undefined;
    this.openDetailsServiceId = undefined;
  }

  toggleMetricDetails(installedServiceId: number, service: Service, metricId: number, accountId: number) {
    if (this.openDetailsMetricId == metricId && this.openDetailsInstalledServiceId == installedServiceId && this.openMetricAccountId == accountId) {
      this.hideMetricDetails();
    } else {
      if(service.attributes.external_identifier || this.staticServiceIds.includes(service.id)) {
        this.showMetricDetails(installedServiceId, service, metricId, accountId);
      }
    }
  }

  displayValue(row: DataItem, attr: string, purpose: string = '') {
    if(this.openDetailsServiceId && this.openDetailsMetricId){
      const service = this.services.find(s => s.id == this.openDetailsServiceId)
      const serviceMetric = this.serviceMetrics.find(sm => sm.id == this.openDetailsMetricId);

      return service && serviceMetric ? this.serviceService.displayValue(row, attr, purpose, serviceMetric, service, this.openDetailsInstalledServiceId) : '';
    } else {
      return '';
    }
  }

  accountHasInstalledService(accountId: number, installedServiceId: number | undefined, metricId: number | undefined) {
    const item = this.accountServicesList.get(accountId);
    const installedService = item?.installedServices.find((serviceData: any) => serviceData.id == installedServiceId && serviceData.detailMetrics.map((x: any) => x.id).includes(metricId))
    const detailsMetric = installedService?.detailMetrics.find((metric: any) => metric.id == metricId);
    this.openMetricTitle = detailsMetric?.title;

    return !!detailsMetric?.id;
  }

  searchAccounts(): void {
    let accountsArray = this.showOnlyAccountsWithChanges ? this.accountArrayWithChangesMap : this.accountArrayMap;
    if (this.accountSearchInput == '') {
      this.filteredAccountArrayMap = accountsArray.slice().sort((a: any,b: any) => { return a.title > b.title ? 1 : -1 });
    } else {
      this.filteredAccountArrayMap = accountsArray.filter((accountData: any) => accountData[1]?.title.toLowerCase().includes(this.accountSearchInput.toLowerCase())).sort((a: any,b: any) => { return a.title > b.title ? 1 : -1 });
    }
  }

  canShowAcknowledgeButton(installedService: AccountDataInstalledService) {
    let result = false;
    switch(installedService.title){
      case this.managedUsersService.attributes.title:
        result = !!this.accountReviewUsersPermission;
        break;
      case this.managedEndpointsService.attributes.title:
        result = !!this.accountReviewAssetsPermission;
        break;
      default:
        result = !!this.installedServiceUpdatePermission;
        break;
    }

    return result;
  }

  updateInstalledService(accountId: number, installedServiceId: number) {
    this.loading = true;
    const accountRow = this.accountServicesList.get(accountId);
    const accountInstalledService = accountRow?.installedServices.find((row: any) => row.id == installedServiceId);
    let payload;
    if (accountInstalledService) {
      switch(installedServiceId){
        case 10001:
        //case this.managedUsersService.id:
          payload = this.prepareAccountUsersReviewedPayload(accountId);
          this.userService.setAccountUsersAsReviewed(payload).subscribe({
            next: () => {
              if (accountInstalledService) {
                accountInstalledService.reviewed = true;
              }
              this.loading = false;
            }
          })
          break;
        case 10002:
        //case this.managedEndpointsService.id:
          payload = this.prepareAccountAssetsReviewedPayload(accountId);
          this.assetResourceService.setAccountAssetsAsReviewed(payload).subscribe({
            next: () => {
              const accountRow = this.accountServicesList.get(accountId);
              if (accountRow?.installedServices) {
                const accountInstalledService = accountRow.installedServices.find((row: any) => row.id == installedServiceId);
                if (accountInstalledService) {
                  accountInstalledService.reviewed = true;
                }
              }
              this.loading = false;
            }
          })
          break;
        case 10003:
        //case this.saasProtectionService.id:
          payload = this.prepareAccountDattoLicensesReviewedPayload(accountId);
          this.dattoLicenseService.setAccountDattoLicensesAsReviewed(payload).subscribe({
            next: () => {
              const accountRow = this.accountServicesList.get(accountId);
              if (accountRow?.installedServices) {
                const accountInstalledService = accountRow.installedServices.find((row: any) => row.id == installedServiceId);
                if (accountInstalledService) {
                  accountInstalledService.reviewed = true;
                }
              }
              this.loading = false;
            }
          })
          break;
        default:
          payload = this.prepareUpdateInstalledServicePayload(installedServiceId);
          this.serviceService.updateInstalledService(payload).subscribe({
            next: () => {
              const accountRow = this.accountServicesList.get(accountId);
              if (accountRow?.installedServices) {
                const accountInstalledService = accountRow.installedServices.find((row: any) => row.id == installedServiceId);
                if (accountInstalledService) {
                  accountInstalledService.reviewed = true;
                }
              }
              this.loading = false;
            },
            error: () => {
              this.loading = false;
            }
          })
          break;
      }
    }
  }

  toggleAccountServices(accountId: number) {
    this.userServices = {};
    const item = this.accountServicesList.get(accountId);
    if (item) {
      item.open = !item.open;
      if (item.open && !item.dataFetched) {
        this.getAccountInstalledServicesMetricsCount(accountId);
      }
    }
  }

  toggleRequiresReview(){
    this.filteredAccountArrayMap = this.showOnlyAccountsWithChanges ? [...this.accountArrayWithChangesMap] : [...this.accountArrayMap];
  }

  applyStaticFilter(filterOption: {id: number, label: string, count: number}) {
    this.staticFilter$.next(filterOption);
  }

  getUnreviewedServices(installedServices: AccountDataInstalledService[]) {
    return installedServices.filter((x: any) => !x.reviewed)?.length ?? 0;
  }

  exportToExcel(){
    if(!this.openMetricAccountId || !this.openDetailsInstalledServiceId){ return; }

    const service = this.services.find(s => s.id == this.openDetailsServiceId)
    const serviceMetric = this.serviceMetrics.find(sm => sm.id == this.openDetailsMetricId);

    const de: any = serviceMetric?.attributes.details_explanation;
    let d = Array.isArray(de) ? de : de ? JSON.parse(de) : [];
    const columns: any = {};
    d.forEach((col: any) => {
      if(col.key) columns[col.column] = col.label;
    })

    const serviceTitle: string = service ? service.getTitle() : '';
    const exportData = this.openServiceFilteredDetails?.map((item: any) => {
      const retObj: any = {}
      Object.keys(item.attributes).forEach(column => {
        if (columns[column]) {
          retObj[columns[column]] = this.displayValue(item, column, 'export');
        }
      });

      return retObj
    })

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, serviceTitle);
    const filename = 'export-data.xlsx';
    XLSX.writeFile(wb, filename);
  }

  getCountMetricValueFromDetailMetric(detailMetric: any, installedService: any) {
    return detailMetric?.count ?? '';
  }

  filterDetails() {
    this.detailsLoading = true;
    let openServiceFilteredDetails = [];
    if(!this.selectedFilterOption || this.selectedFilterOption?.id == 0){
      openServiceFilteredDetails = this.openMetricDetails ? [...this.openMetricDetails] : [];
    }else {
      const column: ServiceMetricColumn | undefined = this.getColFromAttribute(this.staticFilterInUse);
      if (column) {
        const filtered = this.openMetricDetails?.filter((item: any) => {
          return item['attributes'][column.column] == this.selectedFilterOption.label;
        }) ?? [];
        openServiceFilteredDetails = [...filtered];
      }else{
        openServiceFilteredDetails = this.openMetricDetails ? [...this.openMetricDetails] : [];
      }
    }

    switch(this.showChangedSelection) {
      case 'all':
      this.openServiceFilteredDetails = [...openServiceFilteredDetails];
        break;
      case 'changes':
        this.openServiceFilteredDetails = [...openServiceFilteredDetails.filter((row:any) => row.attributes.reviewed == false)];
        break;
      case 'mark-for-deletion':
        this.openServiceFilteredDetails = [...openServiceFilteredDetails.filter((row:any) => row.attributes.reviewed == true && row.attributes.mark_for_deletion == true)];
        break;
      default:
        break;
    }

    setTimeout(() => this.detailsLoading = false, 10);
  }

  showAll() {
    this.showChanged$.next('all');
  }

  showOnlyChanged() {
    this.showChanged$.next('changes');
  }

  showOnlyMarkedForDelete() {
    this.showChanged$.next('mark-for-deletion');
  }

  getUserServices(id: number) {
    if(this.userServices[id]) return;

    this.userServiceLoading = id;
    this.serviceService.getServiceSubscriptions(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.userServices[id] = response.data.map((item: any) => item.attributes.title);
          this.userServiceLoading = undefined;
        }
      });
  }

  private prepareUpdateInstalledServicePayload(installedServiceId: number) {
    const dtoData = {
      id: installedServiceId,
      type: 'installed_services',
      attributes: {
        reviewed: true
      }
    }

    return new InstalledServiceUpdateDto(dtoData);
  }

  private prepareAccountUsersReviewedPayload(accountId: number) {
    return new AccountReviewUsersDto(accountId);
  }

  private prepareAccountAssetsReviewedPayload(accountId: number) {
    return new AccountReviewUsersDto(accountId);
  }

  private prepareAccountDattoLicensesReviewedPayload(accountId: number) {
    return new AccountReviewDattoLicensesDto(accountId);
  }

  private getAccountInstalledServicesMetricsCount(accountId: number) {
    const item = this.accountServicesList.get(accountId);
    if (!item) return;

    item.loading = true;
    const accountInstalledServiceIds: number[] = [];
    const serviceMetricIds: number[] = [];
    this.installedServices.forEach(installedService => {
      if (installedService.relationships?.account?.id == accountId) {
        accountInstalledServiceIds.push(installedService.id);
      }
    })
    item.installedServices.forEach((row: any) => {
      if(!this.staticServiceIds.includes(row.service.id)){
        row.detailMetrics?.forEach((metric: any) => {
          serviceMetricIds.push(metric.id)
        })
      }
    })

    const requests = [
      this.accountsService.getAccount(accountId),
      this.assetResourceService.getManagedEndpointsPerAccount([
        { "key": "kaseya_detail.external_identifier", "operator": ["exist"], "value": ["-"] },
        { "key": "accounts.id", "operator": ["eq"], "value": [accountId] }
      ]),
      this.dattoLicenseService.getDattoLicensesCount(accountId)
    ]
    let installedServiceIds: number[] = [];
    let installedAdditionalServiceIds: number[] = [];
    let requestedInstalledServiceDetailsCount = false;
    let requestedInstalledServiceDetails = false;

    if (accountInstalledServiceIds.length > 0 && serviceMetricIds.length > 0) {
      const accountServicesData = this.accountServicesList.get(accountId);
      if(accountServicesData) {
        accountServicesData.installedServices.forEach((installedService: any) => {
          if(installedService.service?.attributes?.external_identifier){
            installedServiceIds.push(installedService.id);
          }else{
            // remove this after the permission has been added (keep only the else part)
            const service = this.services.find((service: Service) => service.id == installedService.service?.id);
            if(service?.attributes?.external_identifier){
              installedServiceIds.push(installedService.id);
            }else {
              if (!service?.attributes.system_key) {
                installedAdditionalServiceIds.push(installedService.id);
              }
            }
          }
        })
      }
      //console.log(installedServiceIds, installedAdditionalServiceIds, serviceMetricIds);

      // request the count for all integrated services
      if(installedServiceIds.length) {
        requests.push(this.serviceService.getInstalledServiceDetailsCount(installedServiceIds, serviceMetricIds));
        requestedInstalledServiceDetailsCount = true;
      }

      // request the installed service details for all the additional services
      if(installedAdditionalServiceIds.length) {
        requests.push(this.serviceService.getAllInstalledServiceDetails(installedAdditionalServiceIds, serviceMetricIds));
        requestedInstalledServiceDetails = true;
      }
    }
    forkJoin(requests)
      .subscribe((result: any) => {
        item.services_notes = result[0].data.attributes?.empist_services_notes ?? null;

        //this.setReviewedForManagedUsersAndEndpoints(accountId);
        const managedEndpointsResponse = result[1];
        const docCount = managedEndpointsResponse.meta.aggregations.accounts[accountId]?.doc_count ?? 0;
        this.setManagedEndpointsCountMetric(accountId, docCount);

        if (result[2]) {
          const dattoServicesResponse = result[2];
          const docCount = dattoServicesResponse.meta.total_count ?? 0;
          if(this.saasProtectionIsEnabled) this.setSaasProtectionCountMetric(accountId, docCount);
        }

        if (requestedInstalledServiceDetailsCount) {
          const installedServiceDetailsResponse = result[3];
          if(installedServiceDetailsResponse?.data?.length) {
            installedServiceDetailsResponse?.data[0].attributes?.data?.forEach((row: any) => {
              const installedServiceRow = item.installedServices.find((serviceData: any) => serviceData.id == row.installed_service_id && serviceData.detailMetrics.map((x: any) => x.id).includes(row.service_metric_id));
              //console.log('row',row.installed_service_id, row.service_metric_id)
              //console.log('installedServices', item.installedServices)
              //console.log('installedServiceRow', installedServiceRow)

              if (installedServiceRow?.detailMetrics) {
                const detailMetric = installedServiceRow.detailMetrics.find((metric: any) => metric.id == row.service_metric_id);
                if (detailMetric) {
                  detailMetric.count = row.count;
                }
              }
            })
          }
        }

        //get installed service details for all the additional services
        if(requestedInstalledServiceDetails){
          const installedServiceDetailsResponse = requestedInstalledServiceDetailsCount ? result[4] : result[3];
          if(installedServiceDetailsResponse?.data?.length) {
            installedServiceDetailsResponse?.data.forEach((row: any) => {
              const installedServiceRow = item.installedServices.find((metric: any) => metric.id == row.relationships?.installed_service?.data?.id);
              if (installedServiceRow?.detailMetrics) {
                const countMetric = installedServiceRow.detailMetrics.find((metric: any) => metric.id == row.relationships?.service_metric?.data?.id);
                if (countMetric) {
                  countMetric.count = row.attributes.col1;
                }
              }
            })
          }
        }
        this.hideZeroCountInstalledServicesForAccount(accountId);
        this.sortAccountServices(accountId);

        item.loading = false;
        item.dataFetched = true;
      })
  }

  private hideZeroCountInstalledServicesForAccount(accountId: number) {
    let account = this.accountServicesList.get(accountId);
    if(account?.installedServices) {
      account.installedServices = account.installedServices.filter((row: any) => {
        return !row.reviewed || (row.detailMetrics.length && row.detailMetrics.some((item: any) => item.count > 0));
      }) // hide 0 result installed services
    }
  }


  private getUsersStaticFilterOptions(): void {
    if(this.priceTierOptions.length == 0) {
      this.userService.getPriceTiers()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.priceTierOptions = response.data.map((row: any) => {
              return {
                id: row.id,
                label: row.attributes.title,
                count: undefined
              }
            });
            this.priceTierOptions.unshift(new StaticFilterOption(0, 'All', 0));
            this.staticFilterOptions = [...this.priceTierOptions];
          }
        });
    }else{
      this.staticFilterOptions = [...this.priceTierOptions];
    }
  }

  private getColFromAttribute(attribute: string) {
    const serviceMetric = this.serviceMetrics.find(sm => sm.id == this.openDetailsMetricId);
    const de: any = serviceMetric?.attributes.details_explanation;
    let d = Array.isArray(de) ? de : de ? JSON.parse(de) : [];
    const column = d ? d.find((row: any) => row.key == attribute || (attribute == 'price_tier' && row.key == 'empist_aiup_type')) : undefined;

    return column;
  }

  private setStaticMetricsCount(attribute: string) {
    this.staticFilterOptions = this.staticFilterOptions.map(option => {
      let count;
      let deletedCount;
      if(option.label == 'All'){
        count = this.openMetricDetails?.filter((row: any) => !row['attributes']['deleted_at']).length ?? 0;
        deletedCount = this.openMetricDetails?.filter((row: any) => row['attributes']['deleted_at']).length ?? 0;
      }else{
        const column = this.getColFromAttribute(attribute);
        count = this.openMetricDetails?.filter((row: any) => row['attributes'][column.column] == option.label &&  !row['attributes']['deleted_at'])?.length ?? 0;
        deletedCount = this.openMetricDetails?.filter((row: any) => row['attributes'][column.column] == option.label && row['attributes']['deleted_at'])?.length ?? 0;
      }

      return {
        ...option,
        count: count,
        deletedCount: deletedCount
      }
    })
  }

  private getAccountManagedUsers(accountId: number) {
    this.detailsLoading = true;
    this.userService.getAccountManagedUsersIncludeDeleted(accountId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.openMetricDetails = response.data.map((row: any) => new InstalledServiceDetail(row, response.included));
          this.openServiceFilteredDetails = this.openMetricDetails ? [...this.openMetricDetails] : [];
          this.setColumnsAndKeys();
          this.setStaticMetricsCount('price_tier');
        },
        error: (error) => { console.log(error); },
        complete: () => {
          this.detailsLoading = false;
        }
      })
  }

  private getAccountManagedEndpoints(accountId: number) {
    this.detailsLoading = true;
    this.assetResourceService.getAccountManagedEndpointsIncludeDeleted(accountId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          const assets = response.data.map((row: any) => new AssetResource(row, response.included))
          const normalizedResponse: any = this.normalizeAssetResourcesResponse(assets, response);
          this.openMetricDetails = normalizedResponse.data.map((row: any) => new InstalledServiceDetail(row, normalizedResponse.included));
          this.openServiceFilteredDetails = this.openMetricDetails ? [...this.openMetricDetails] : [];
          this.setColumnsAndKeys();
          this.detailsLoading = false;
          this.setStaticMetricsCount('type');
        },
        error: () => {},
        complete: () => {
          this.detailsLoading = false;
        }
      })
    }

  private getAccountSaasProtectionSeats(accountId: number) {
    this.detailsLoading = true;
    this.dattoLicenseService.getAccountDattoLicensesIncludeDeleted(accountId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.openMetricDetails = response.data.map((row: any) => new InstalledServiceDetail(row, response.included));
          this.openServiceFilteredDetails = this.openMetricDetails ? [...this.openMetricDetails] : [];
          this.setColumnsAndKeys();
          this.detailsLoading = false;
          this.setStaticMetricsCount('status');
        },
        error: () => {},
        complete: () => {
          this.detailsLoading = false;
        }
      })
  }

  private normalizeAssetResourcesResponse(assets: AssetResource[], response: any) {
    return {
      data: assets.map((row: any) => {
        return {
          id: row.id,
          type: row.type,
          attributes: {
            col1: row.attributes.title,
            col2: (row.relationships?.kaseya_detail?.id) ? row.relationships.kaseya_detail.attributes.computer_name : '',
            col3: (row.relationships?.asset_resource_type) ? row.relationships.asset_resource_type?.attributes.title : '',
            col4: (row.relationships?.contact) ? row.relationships.contact.attributes.fullname : '',
            col5: (row.relationships?.kaseya_detail?.id) ? row.relationships.kaseya_detail.attributes.os_info : '',
            col6: (row.attributes.serial_number) ? row.attributes.serial_number : '',
            col7: (row.relationships?.kaseya_detail?.attributes?.first_check_in) ? row.relationships.kaseya_detail.attributes.first_check_in : '',
            col8: (row.relationships?.kaseya_detail?.attributes?.last_check_in_time) ? row.relationships.kaseya_detail.attributes.last_check_in_time : '',
            reviewed: row.attributes.reviewed,
            deleted_at: !!row.attributes.deleted_at,
            mark_for_deletion: row.attributes.mark_for_deletion,
          }
        }
      }),
      meta: {
        ...response.meta
      }
    }
  }

  private setColumnsAndKeys(): void {
    if(this.openDetailsMetricId) {
      const serviceMetric = this.serviceMetrics.find(sm => sm.id == this.openDetailsMetricId);
      if(serviceMetric) {
        const result = serviceMetric.getColumnAndKeys();
        this.keys = result.keys;
        this.columns = result.columns;
      }
    }
  }

  private sortByAccountTitle(data: any) {
    return data.sort((a: any, b: any) => a.title.localeCompare(b.title));
  }

  private prepareAccountServicesData() {
    this.loading = true;
    const managedUsers = this.userService.getManagedUsersPerAccount([
      { "key": "active", "operator": ["eq"], "value": "true" },
      { "key": "accounts.account_type_title", "operator": ["like"], "value": "Customer" }
    ]);
    const services = this.serviceService.getServices(10000, 1);
    const installedServices = this.serviceService.getAllInstalledServices();
    const managedUsersForReview = this.userService.getManagedUsersPerAccount([
      { "key": "active", "operator": ["eq"],  "value": "true" },
      { "key": "reviewed", "operator": ["eq"],  "value": "false" },
      { "key": "accounts.account_type_title", "operator": ["like"], "value": "Customer" }
    ]);
    const managedEndpointsForReview = this.assetResourceService.getManagedEndpointsPerAccount([
      { "key": "kaseya_detail.external_identifier", "operator": ["exist"], "value": ["-"] },
      { "key": "reviewed", "operator": ["eq"],  "value": "false" }
    ]);
    const deletedManagedUsersForReview = this.userService.getManagedUsersPerAccount([
      { "key": "deleted_at", "operator": ["exist"],  "value": "-" },
      { "key": "reviewed", "operator": ["eq"],  "value": "false" },
      { "key": "accounts.account_type_title", "operator": ["like"], "value": "Customer" }
    ]);
    const deletedManagedEndpointsForReview = this.assetResourceService.getManagedEndpointsPerAccount([
      { "key": "deleted_at", "operator": ["exist"],  "value": "-" },
      { "key": "kaseya_detail.external_identifier", "operator": ["exist"], "value": ["-"] },
      { "key": "reviewed", "operator": ["eq"],  "value": "false" }
    ]);
    const saasProtectionForReview = this.dattoLicenseService.getUnreviewedDattoLicenses();
    this.syncDataLoading = true;
    /*
    this.serviceService.getLastSync().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (response: any) => {
        response.data.forEach((row: any) => {
          this.syncData.push(new IntegrationLastSync(row));
        })
      }
    });
    */

    forkJoin([managedUsers, services, installedServices, managedUsersForReview, managedEndpointsForReview, saasProtectionForReview, deletedManagedUsersForReview, deletedManagedEndpointsForReview])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: any) => {
        const managedUsersResponse = result[0];
        const servicesResponse = result[1];
        const installedServicesResponse = result[2];
        const managedUsersForReviewResponse = result[3];
        const managedEndpointsForReviewResponse = result[4];
        const saasProtectionForReviewResponse = result[5];
        const deletedManagedUsersForReviewResponse = result[6];
        const deletedManagedEndpointsForReviewResponse = result[7];

        this.setServices(servicesResponse);
        this.setInstalledServices(installedServicesResponse);
        this.setAccountServicesData(managedUsersResponse);
        this.setAccountInstalledServices(managedUsersResponse, managedUsersForReviewResponse, managedEndpointsForReviewResponse, saasProtectionForReviewResponse, deletedManagedUsersForReviewResponse, deletedManagedEndpointsForReviewResponse);
        this.loading = false;

        this.normalizeSyncData();
        this.syncDataLoading = false;
      })
  }

  private setAccountServicesData(response: any) {
    this.accounts = Object.keys(response.meta.aggregations.accounts).map((key: string) => {
      const accountId: number = +key;
      const value = response.meta.aggregations.accounts[key];
      return new AggregationData(accountId, value.title, value.doc_count);
    });

    this.accounts = this.sortByAccountTitle(this.accounts);
    this.accounts.forEach((account: { id: number, title: string }) => {
      if(account.id > 0) {
        this.accountServicesList.set(account.id, new AccountServicesData(account.id, account.title))
      }
    });
    this.accountArrayMap = Array.from(this.accountServicesList.entries());
    this.filteredAccountArrayMap = [...this.accountArrayMap];
  }

  public setServices(response: any) {
    this.services = response.data.map((bsData: any) => new Service(bsData, response.included));
    this.serviceMetrics = response.included.filter((includedObj: any) => includedObj.type == 'service_metrics')
                              .map((includedObj: any) => new ServiceMetric(includedObj, []))

    this.setStaticServices();

    this.serviceService.setServices(this.services);
    this.serviceService.setServiceMetrics(this.serviceMetrics);
  }

  private setStaticServices() {
    this.staticServiceIds = [];
    const managedUsersService = this.services.find(service => service.attributes.system_key == 'digcore-users')
    if(managedUsersService) {
      this.managedUsersService = managedUsersService;
      this.staticServiceIds.push(managedUsersService.id);
    }
    const managedEndpointsService = this.services.find(service => service.attributes.system_key == 'ticketing-assets')
    if(managedEndpointsService) {
      this.managedEndpointsService = managedEndpointsService;
      this.staticServiceIds.push(managedEndpointsService.id);
    }
    const saasProtectionService = this.services.find(service => service.attributes.system_key == 'digcore-datto-license')
    if(saasProtectionService) {
      this.saasProtectionService = saasProtectionService;
      this.staticServiceIds.push(saasProtectionService.id);
    }
  }

  private setInstalledServices(response: any) {
    this.installedServices = response.data.map((bsData: any) => new InstalledService(bsData, response.included));
  }

  private setAccountInstalledServices(managedUsersResponse: any, managedUsersForReviewResponse: any, managedEndpointsForReviewResponse: any, saasProtectionForReviewResponse: any, deletedManagedUsersForReviewResponse: any, deletedManagedEndpointsForReviewResponse: any) {
    this.setChangesForInstalledServices(managedUsersResponse);

    this.setChangesForManagedUsers(managedUsersForReviewResponse, deletedManagedUsersForReviewResponse);

    this.setChangesForManagedEndpoints(managedEndpointsForReviewResponse, deletedManagedEndpointsForReviewResponse);

    if(this.saasProtectionIsEnabled) this.setChangesForSaasProtection(saasProtectionForReviewResponse);

    this.accountArrayWithChangesMap = this.accountArrayMap.filter((accountData: any) => accountData[1].changesCount > 0);
  }

  private setChangesForManagedUsers(managedUsersForReviewResponse: any, deletedManagedUsersForReviewResponse: any){
    Object.keys(managedUsersForReviewResponse.meta.aggregations.accounts).forEach((key: string) => {
      const accountId = +key;
      if (accountId == 0) return;

      const value = managedUsersForReviewResponse.meta.aggregations.accounts[key];
      const setItem: AccountServicesData | undefined = this.accountServicesList.get(accountId);
      if(setItem){
        const managedUsersInstalledService = setItem.installedServices.find((installedService: any) => installedService.service?.attributes.system_key == 'digcore-users');
        if(managedUsersInstalledService){
          managedUsersInstalledService.reviewed = value.doc_count < 1;
        }
        setItem.changesCount += value.doc_count > 0 ? 1 : 0;
      }
    });
    Object.keys(deletedManagedUsersForReviewResponse.meta.aggregations.accounts).forEach((key: string) => {
      const accountId = +key;
      if (accountId == 0) return;

      const value = deletedManagedUsersForReviewResponse.meta.aggregations.accounts[key];
      const setItem: AccountServicesData | undefined = this.accountServicesList.get(accountId);
      if(setItem){
        const managedUsersInstalledService = setItem.installedServices.find((installedService: any) => installedService.service?.attributes.system_key == 'digcore-users');
        if(managedUsersInstalledService){
          managedUsersInstalledService.reviewed = value.doc_count < 1;
        }
        setItem.changesCount += value.doc_count > 0 ? 1 : 0;
      }
    });
  }

  private setChangesForManagedEndpoints(managedEndpointsForReviewResponse: any, deletedManagedEndpointsForReviewResponse: any){
    Object.keys(managedEndpointsForReviewResponse.meta.aggregations.accounts).forEach((key: string) => {
      const accountId = +key;
      if (accountId == 0) return;

      const value = managedEndpointsForReviewResponse.meta.aggregations.accounts[key];
      const setItem: AccountServicesData | undefined = this.accountServicesList.get(accountId);
      if(setItem){
        const managedEndpointsInstalledService = setItem.installedServices.find((installedService: any) => installedService.service?.attributes.system_key == 'ticketing-assets');
        if(managedEndpointsInstalledService){
          managedEndpointsInstalledService.reviewed = value.doc_count < 1;
        }
        setItem.changesCount += value.doc_count > 0 ? 1 : 0;
      }
    });
    Object.keys(deletedManagedEndpointsForReviewResponse.meta.aggregations.accounts).forEach((key: string) => {
      const accountId = +key;
      if (accountId == 0) return;

      const value = deletedManagedEndpointsForReviewResponse.meta.aggregations.accounts[key];
      const setItem: AccountServicesData | undefined = this.accountServicesList.get(accountId);
      if(setItem){
        const managedEndpointsInstalledService = setItem.installedServices.find((installedService: any) => installedService.service?.attributes.system_key == 'ticketing-assets');
        if(managedEndpointsInstalledService){
          managedEndpointsInstalledService.reviewed = value.doc_count < 1;
        }
        setItem.changesCount += value.doc_count > 0 ? 1 : 0;
      }
    });
  }

  private setChangesForSaasProtection(response: any){
    response.data.forEach((row: any) => {
      // const dattoLicense = new DattoLicense(row, response.included);
      // const accountId = dattoLicense.relationships?.account?.id;
      const accountId = +row.id;
      if(accountId) {
        const setItem: AccountServicesData | undefined = this.accountServicesList.get(accountId);
        if (setItem) {
          const saasProtectionInstalledService = setItem.installedServices.find((installedService: AccountDataInstalledService) => installedService.service?.attributes.system_key == 'digcore-datto-license');
          if (saasProtectionInstalledService) {
            saasProtectionInstalledService.reviewed = false;
            setItem.changesCount += 1;
          }
        }
      }
    });
  }

  private setChangesForInstalledServices(response: any){
    Object.keys(response.meta.aggregations.accounts)
      .forEach((key: string) => {
        const accountId = +key;
        if (accountId == 0) return;

        const value = response.meta.aggregations.accounts[key];
        const setItem: AccountServicesData | undefined = this.accountServicesList.get(accountId);
        if (setItem?.installedServices.length == 0) {
          setItem.installedServices = [
            this.getManagedUsersAccountData(),
            this.getManagedEndpointsAccountData(),
            this.getSaasProtectionAccountData(),
          ]
        }
        this.setManagedUsersCountMetric(accountId, value.doc_count);
        if (setItem) {
          this.installedServices.forEach((installedService: InstalledService) => {
            if (installedService.relationships?.account?.id == accountId && installedService.relationships.service) {
              const accountInstalledServiceData: AccountDataInstalledService = this.getAccountInstalledServiceData(installedService);
              setItem.installedServices.push(accountInstalledServiceData);
            }
          })
          setItem.changesCount = setItem.installedServices.filter((installedService: AccountDataInstalledService) => !installedService.reviewed)?.length ?? 0;
        }
      });
  }

  private setManagedUsersCountMetric(accountId: number, docCount: number) {
    const managedUsersInstalledService = this.accountServicesList.get(accountId)?.installedServices.find((installedService: AccountDataInstalledService) => installedService.service?.attributes.system_key == 'digcore-users');
    if (managedUsersInstalledService) {
      managedUsersInstalledService.detailMetrics[0].count = docCount;
    }
  }

  private setManagedEndpointsCountMetric(accountId: number, docCount: number) {
    const managedEndpointsInstalledService = this.accountServicesList.get(accountId)?.installedServices.find((installedService: AccountDataInstalledService) => installedService.service?.attributes.system_key == 'ticketing-assets');
    if (managedEndpointsInstalledService) {
      managedEndpointsInstalledService.detailMetrics[0].count = docCount;
    }
  }

  private setSaasProtectionCountMetric(accountId: number, docCount: number) {
    const saasProtectionInstalledService = this.accountServicesList.get(accountId)?.installedServices.find((installedService: AccountDataInstalledService) => installedService.service?.attributes.system_key == 'digcore-datto-license');
    if (saasProtectionInstalledService) {
      saasProtectionInstalledService.detailMetrics[0].count = docCount;
    }
  }

  private getAccountInstalledServiceData(installedService: installedService): AccountDataInstalledService {
    const service = this.services.find(s => s.id == installedService.relationships?.service?.id);

    return {
      id: installedService.id,
      title: service?.getTitle() ?? '',
      reviewed: installedService.attributes.reviewed,
      service: service ?? undefined,
      detailMetrics: this.getDetailsMetrics(service?.id).map(metric => {
        return {
          id: metric.id,
          title: metric.getTitle(),
          metric_group: metric.attributes.metric_group ?? '',
          value: [],
          count: 0
        }
      })
    }
  }

  private normalizeSyncData() {
    const sData: {title: string; date: Date; status: string}[] = [];

    const now = Date.now();
    const nowDate = new Date(now);
    sData.push({title: 'Managed Users', date: nowDate, status: 'Success'});

    this.syncData.forEach(item => {
      switch(item.integration){
        case 'Datto':
          sData.push({title: 'Saas Protection', date: item.completedAt, status: item.status});
          return;

        case 'ItGlue':
          sData.push({title: 'Managed Endpoints', date: item.completedAt, status: item.status});
          return;

        case 'Liongard':
          this.services?.filter(service => service.attributes.system_key && !this.staticServiceIds.includes(service.id))?.forEach(service => {
            sData.push({title: service.getTitle(), date: item.completedAt, status: item.status});
          })
          return;
      }
    })

    this.lastSyncNormalizedData = sData;
  }

  private sortAccountServices(accountId: number) {
    let account = this.accountServicesList.get(accountId);
    if(account?.installedServices) {
      account.installedServices.sort((a: any,b: any) => { return a.title > b.title ? 1 : -1 })
    }
  }

  isInstalledServiceOpen(accountId: number, installedService: AccountDataInstalledService) {
    return this.openDetailsKey == accountId+':'+installedService.service?.id+':'+installedService.id+':'+this.openDetailsMetricId;
  }
}
