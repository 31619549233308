{
  "name": "empist360-client",
  "version": "1.19.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.6",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/forms": "^19.0.6",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/router": "^19.0.6",
    "@date-fns/tz": "^1.2.0",
    "@sentry/angular": "^8.36.0",
    "angular-froala-wysiwyg": "^4.3.0",
    "crypto-js": "^4.2.0",
    "date-fns": "^4.1.0",
    "google-libphonenumber": "^3.2.38",
    "jwt-decode": "^4.0.0",
    "libphonenumber-js": "^1.11.12",
    "ng-qrcode": "^19.0.1",
    "ng-zorro-antd": "^19.0.0",
    "ngx-sortable": "^2.1.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.8.1",
    "xlsx": "^0.18.5",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.7",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@types/crypto-js": "^4.2.2",
    "@types/events": "^3.0.3",
    "@types/file-saver": "^2.0.7",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^22.8.6",
    "@types/qrcode": "^1.5.5",
    "browser-sync": "^3.0.2",
    "jasmine-core": "~4.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "karma-mocha-reporter": "^2.2.5",
    "typescript": "^5.5.4"
  }
}
