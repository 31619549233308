<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Changes</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span
          class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row class="list-content">
      <div nz-col nzSpan="24">
        <div class="list-container">
          <form nz-form
                nzLayout="vertical"
                [formGroup]="filtersFormGroup">
            <div nz-row>
              <div nz-col nzSpan="24" class="list-top">
                <h1>Changes</h1>
                <div class="sub-header">
                  <div class="sub-header-text">Stay updated with the latest features, fixes, and improvements.</div>
                  <div class="current-records">
                    <div>Showing {{ startNumber }}-{{ endNumber }} of {{ total }} changes</div>
                    <ng-container *ngFor="let filter of getFiltersByFormGroupControls(); let i = index">
                      <div *ngIf="filter.type == 'static_select' && filter.key == 'state'" class="round-border-select">
                        <nz-select [formControlName]="filter.computed_key"
                                   [nzPlaceHolder]="'Select'"
                                   nzShowSearch
                                   [nzTokenSeparators]="[',']"
                                   nzBorderless
                                   [nzBackdrop]="false"
                                   [nzShowArrow]="true"
                                   nzServerSearch
                                   (ngModelChange)="onSelect($event, filter)">
                          <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                     [nzValue]="option.value"
                                     [nzLabel]="option.doc_count ? capitalizeFirstLetter(option.label) + '('+option.doc_count+')' : capitalizeFirstLetter(option.label)"></nz-option>
                        </nz-select>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div nz-row class="filters-container" *ngIf="false">
              <div nz-col nzSpan="24">
                <!-- filters begin -->
                  <div nz-row>
                    <div nz-col nzSpan="24" nzOffset="0" class="filters">
                      <!--
                      <span class="no-filters-start-phrase" *ngIf="filtersCountFormGroup == 0">All Changes</span>
                      <span class="clear-all-filters" *ngIf="filtersCountFormGroup > 0" (click)="clearAllFilters()">Clear all</span>
                      <span class="filters-start-phrase" *ngIf="filtersCountFormGroup > 0">Show me changes that</span>
                      <div *ngFor="let filter of getFiltersByFormGroupControls(); let i = index" class="incident-filter">
                        <span class="remove-filter" (click)="removeFilterControl(filter)">
                          <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
                        </span>
                        <span class="filter-prefix">
                          {{ i > 0 ? ' and' : ''}} {{getFilterLabel(filter.computed_key, filter.computed_label) }}
                        </span>
                        <div class="filter-control">
                          <div class="filter-control-container">
                            <div *ngIf="filter.type == 'select' || filter.type == 'static_select'">
                              <nz-select [formControlName]="filter.computed_key"
                                         [nzPlaceHolder]="'Select'"
                                         nzShowSearch
                                         [nzTokenSeparators]="[',']"
                                         nzBorderless
                                         [nzBackdrop]="false"
                                         [nzShowArrow]="true"
                                         nzServerSearch
                                         (ngModelChange)="onSelect($event, filter)">
                                <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                           [nzValue]="option.value"
                                           [nzLabel]="option.doc_count ? option.label + '('+option.doc_count+')' : option.label"></nz-option>
                              </nz-select>
                            </div>
                            <div *ngIf="['text','string'].includes(filter.type)">
                              <input nz-input
                                     [formControlName]="filter.computed_key"
                                     (ngModelChange)="onSelect($event, filter)"
                                     nzBorderless
                                     placeholder="type to search"/>
                            </div>
                            <div *ngIf="filter.type == 'simplified_date'">
                              <nz-select [nzPlaceHolder]="'Select'"
                                         [formControlName]="filter.computed_key"
                                         nzBorderless
                                         [nzShowArrow]="true"
                                         [nzBackdrop]="false"
                                         (ngModelChange)="onSelect($event, filter)">
                                <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                           [nzValue]="option.value"
                                           [nzLabel]="option.label"></nz-option>
                              </nz-select>
                            </div>
                            <div *ngIf="filter.type == 'date'">
                              <nz-range-picker [nzShowTime]="false"
                                               [formControlName]="filter.computed_key"
                                               (ngModelChange)="onSelect($event, filter)"
                                               [nzAllowClear]="false">
                              </nz-range-picker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span class="add-filter-container disabled"
                            *ngIf="!addFilterEnabled">
                        <span class="tooltip" nz-tooltip [nzTooltipTitle]="'Please fill in all selected filters to add more'">
                          <span class="add-filter-label">Add filter</span>
                          <a class="add-filter">
                            <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                          </a>
                        </span>
                      </span>
                      <span class="add-filter-container"
                            nz-dropdown nzTrigger="click"
                            [nzDropdownMenu]="filtersAvailable"
                            [nzPlacement]="'bottomLeft'"
                            [nzDisabled]="!addFilterEnabled"
                            *ngIf="addFilterEnabled && availableFilterOptions.length > 0">
                          <span class="add-filter-label" *ngIf="filtersCountFormGroup == 0">Add filter</span>
                          <a class="add-filter">
                            <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                          </a>
                        </span>
                      <nz-dropdown-menu #filtersAvailable="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item *ngFor="let filter of availableFilterOptions" (click)="addFilterControl(filter.key)">
                            <span>{{ filter.label }}</span>
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                      -->
                    </div>
                  </div>
                <!-- filters end -->
              </div>
            </div>

            <ng-container *ngFor="let filter of getFiltersByFormGroupControls(); let i = index">
              <div class="input-search-filter" *ngIf="['text','string'].includes(filter.type) && filter.key == 'title'">
                <nz-input-group nzSearch
                                [nzPrefix]="prefixIcon"
                                [nzSuffix]="suffixIconButton"
                                nzSize="large"
                                class="input-search">
                  <input type="text"
                         nz-input
                         placeholder="Search for changes"
                         formControlName="title"
                         (ngModelChange)="onSelect($event, filter)"/>
                </nz-input-group>

                <ng-template #prefixIcon>
                  <span nz-icon nzType="search"></span>
                </ng-template>

                <ng-template #suffixIconButton>
                  <button nz-button nzSearch nzShape="circle" nzType="primary">
                    <span nz-icon nzType="right" (click)="getChangelogs()"></span>
                  </button>
                </ng-template>
              </div>
            </ng-container>
          </form>

          <div nz-row class="list-description" *ngIf="availableFilterOptions">
            <div nz-col nzSpan="24">
              <nz-table [nzData]="changelogs"
                        [nzFrontPagination]="false"
                        [nzLoading]="loading"
                        [nzTotal]="total"
                        [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex"
                        [nzBordered]="false"
                        [nzShowSizeChanger]="true"
                        (nzPageIndexChange)="onPageIndexChange($event)"
                        (nzPageSizeChange)="onPageSizeChange($event)">
                <thead>
                  <tr>
                    <th nzColumnKey="title"
                        [nzShowSort]="isSortable()"
                        [nzSortDirections]="['ascend', 'descend']"
                        [nzSortFn]="true"
                        [nzSortOrder]="(sort.attribute == 'title' ? sort.order : null)"
                        (nzSortOrderChange)="onSortOrderChange($event, 'title')">Title</th>
                    <th nzWidth="150px"
                        nzColumnKey="account_title"
                        [nzShowSort]="isSortable()"
                        [nzSortDirections]="['ascend', 'descend']"
                        [nzSortFn]="true"
                        [nzSortOrder]="(sort.attribute == 'account.title' ? sort.order : null)"
                        (nzSortOrderChange)="onSortOrderChange($event, 'account.title')">Account</th>
                    <th nzWidth="150px"
                        nzColumnKey="occurs_at"
                        [nzShowSort]="isSortable()"
                        [nzSortDirections]="['ascend', 'descend']"
                        [nzSortFn]="true"
                        [nzSortOrder]="(sort.attribute == 'occurs_at' ? sort.order : null)"
                        (nzSortOrderChange)="onSortOrderChange($event, 'occurs_at')">When</th>
                    <th nzWidth="150px"
                        nzColumnKey="change_risk_level"
                        [nzShowSort]="isSortable()"
                        [nzSortDirections]="['ascend', 'descend']"
                        [nzSortFn]="true"
                        [nzSortOrder]="(sort.attribute == 'change_risk_level.title' ? sort.order : null)"
                        (nzSortOrderChange)="onSortOrderChange($event, 'change_risk_level.title')">Risk Level</th>
                    <th nzWidth="150px"
                        nzColumnKey="change_status"
                        [nzShowSort]="isSortable()"
                        [nzSortDirections]="['ascend', 'descend']"
                        [nzSortFn]="true"
                        [nzSortOrder]="(sort.attribute == 'change_status.title' ? sort.order : null)"
                        (nzSortOrderChange)="onSortOrderChange($event, 'change_status.title')">Status</th>
                  </tr>
                </thead>
                <tbody>
                  @for (changelog of changelogs; track changelog.id) {
                    <tr [routerLink]="['/app', 'changes', changelog.id]">
                      <td class="title">{{ changelog.attributes.title }}</td>
                      <td>{{ changelog.relationships?.account?.attributes?.title ?? '-' }}</td>
                      <td>{{ changelog.attributes.occurs_at | date }}</td>
                      <td>{{ changelog.relationships?.change_risk_level?.attributes?.title ?? '-' }}</td>
                      <td>{{ changelog.relationships?.change_status?.attributes?.title ?? '-' }}</td>
                    </tr>
                  }
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
