import { Component, DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AccountsService } from "src/app/services/accounts.service";
import { FlowsService } from "src/app/services/flows.service";
import { Account } from "src/app/utilities/models/account/account";
import { FlowCategoryUpdateAccountsDto } from "src/app/utilities/models/dto/flowCategoryUpdateAccountsDto";
import { Flow } from "src/app/utilities/models/ticket/flow/flow";
import { FlowCategory } from "src/app/utilities/models/ticket/flowCategory/flowCategory";
import { FlowCategoryWithChildren } from "src/app/utilities/models/ticket/flowCategory/flowCategoryWithChildren";

@Component({
    selector: 'app-account-flows',
    templateUrl: './account-flows.component.html',
    styleUrls: ['./account-flows.component.scss'],
    standalone: false
})
export class AccountFlowsComponent {
  selectedAccountId: number | undefined;
  selectedFlowCategory: FlowCategory | undefined;
  account: Account | undefined = undefined;
  accounts: Account[] = [];
  flowCategories: FlowCategory[] = [];
  flows: Flow[] = [];
  accountsLoading: boolean = false;
  flowsLoading: boolean = false;
  flowCategoriesLoading: boolean = false;
  flowCategoryAccountIds: { [key: number]: number[] } = {};
  flowsByCategory: { [key: number]: Flow[] } = {};
  categoryTree: FlowCategoryWithChildren[] = [];
  accountFlowCategoryIds = new Set<number>();
  categoryIdToAdd: number;
  pathByCategory: {[key: number]: string} = {};

  constructor(private destroyRef: DestroyRef,
              private flowService: FlowsService,
              private accountService: AccountsService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

  }

  get availableCategories() {
    return this.flowCategories?.filter(category => !this.accountFlowCategoryIds.has(category.id)) ?? []
  }

  get sortedFlowCategories() {
    return this.flowCategories?.sort((a: FlowCategory, b: FlowCategory) => {
      return this.pathByCategory[a.id] > this.pathByCategory[b.id] ? 1 : -1;
    })
  }

  ngOnInit() {
    this.getAccounts();
    this.activatedRoute.params
      .subscribe({
        next: (params: Params) => {
          if(params['id']) {
            this.selectedAccountId = +params['id'];
            this.getFlowCategories();
            this.getFlows();
          }
        }
      })
  }

  selectAccount() {
    this.account = this.accounts.find(account => account.id == this.selectedAccountId);
    this.router.navigateByUrl('/app/flow-categories/account/'+this.selectedAccountId);
  }

  private getAccounts() {
    this.accountsLoading = true;
    this.accountService.getAccounts()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.accounts = response.data.map((row: any) => new Account(row, response.included));
          if(this.selectedAccountId) {
            this.account = this.accounts.find(account => account.id == this.selectedAccountId);
          }
          this.accountsLoading = false;
        },
        error: (error) => {
           console.log(error);
           this.accountsLoading = false;
         },
        complete: () => {}
      })
  }

  private getFlowCategories() {
    this.flowCategoriesLoading = true;
    this.flowService.getFlowCategoriesWithFlows()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.flowCategories = response.data.map((row: any) => new FlowCategory(row, response.included));
          this.flowCategories.forEach(flowCategory => {
            this.flowCategoryAccountIds[flowCategory.id] = flowCategory.relationships?.accounts?.map(account => account.id) ?? []
          })
          this.flowCategoriesLoading = false;
          this.setupCategoryTree();
        },
        error: (error) => {
          console.log(error);
          this.flowCategoriesLoading = false;
        },
        complete: () => {}
      })
  }

  private getFlows() {
    this.flowsLoading = true;
    this.flowsByCategory = {};
    this.flowService.getFlows()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.flows = response.data.map((row: any) => new Flow(row, response.included));
          this.flows.forEach((flow: Flow) => {
            flow.relationships?.flow_categories?.forEach((flowCategory: any) => {
              if(!this.flowsByCategory[flowCategory.id]) this.flowsByCategory[flowCategory.id] = [];
              this.flowsByCategory[flowCategory.id].push(flow);
            })
          })
          this.flowsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.flowsLoading = false;
        },
        complete: () => {}
      })
  }

  private setupCategoryTree() {
    if(!this.selectedAccountId) return [];

    return this.categoryTree = this.flowCategories.filter((flowCategory: FlowCategory) => flowCategory.attributes.parent_id == null)
      .map((flowCategory: FlowCategory) => {
        this.accountFlowCategoryIds.add(flowCategory.id);
        this.pathByCategory[flowCategory.id] = flowCategory.attributes.title;
        return {
          id: flowCategory.id,
          category: flowCategory,
          path: flowCategory.attributes.title,
          children: this.getFlowCategoryChildren(flowCategory.id, flowCategory.attributes.title)
        }
      }) ?? [];
  }

  private getFlowCategoryChildren(parentId: number, currentPath: string): FlowCategoryWithChildren[] {
    if(!this.selectedAccountId) return [];

    return this.flowCategories.filter((flowCategory: FlowCategory) => flowCategory.attributes.parent_id == parentId)
      .map((flowCategory: FlowCategory) => {
        this.accountFlowCategoryIds.add(flowCategory.id);
        const path: string = currentPath + ' > ' + flowCategory.attributes.title;
        this.pathByCategory[flowCategory.id] = path;
        return {
          id: flowCategory.id,
          category: flowCategory,
          path: path,
          children: this.getFlowCategoryChildren(flowCategory.id, path)
        }
    }) ?? []
  }

  flowCategoryIsAvailableToAccount(flowCategory: FlowCategory, accountId: number | undefined): boolean {
    if(!accountId) return false;

    const flowCategoryAccountExceptions = flowCategory.relationships?.accounts?.map(account => account.id) ?? [];
    return (flowCategory.attributes.public == true && !flowCategoryAccountExceptions?.includes(accountId)) ||
           (flowCategory.attributes.public == false && flowCategoryAccountExceptions?.includes(accountId))
  }

  getCategoryTooltip(flowCategory: FlowCategory) {
    return 'Available only to: '+ flowCategory.relationships?.accounts?.map(acc => acc.attributes.title)?.join(', ');
  }

  removeCategoryFromAccount(flowCategory: FlowCategory, accountId: number) {
    const payload: FlowCategoryUpdateAccountsDto = this.prepareRemoveAccountPayload(flowCategory, accountId);
    this.flowService.flowCategoryUpdateAccounts(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (_response: any) => {
          this.getFlowCategories();
        },
        error: (error) => { console.log(error); },
      })
  }

  addCategoryToAccount(flowCategory: FlowCategory | undefined, accountId: number | undefined) {
    if(!flowCategory || !accountId) return;

    const payload: FlowCategoryUpdateAccountsDto = this.prepareAddAccountPayload(flowCategory, accountId);
    this.flowService.flowCategoryUpdateAccounts(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (_response: any) => {
          this.getFlowCategories();
        },
        error: (error) => { console.log(error); },
      })
  }

  private prepareRemoveAccountPayload(flowCategory: FlowCategory, accountId: number) {
    const accountData = flowCategory.relationships?.accounts?.map(account => {
      return {
        id: account.id, type: account.type
      }
    }) ?? [];
    if(flowCategory.attributes.public === true){
      accountData.push({id: accountId, type: 'accounts'});
    }else{
      const accIndex = accountData.findIndex(acc => acc.id == accountId);
      accountData.splice(accIndex,1);
    }
    const payload: FlowCategoryUpdateAccountsDto = {
      data: {
        id: flowCategory.id,
        type: flowCategory.type,
        relationships: {
          accounts: {
            data: accountData
          }
        }
      }
    }

    return payload;
  }

  private prepareAddAccountPayload(flowCategory: FlowCategory, accountId: number) {
    const accountData = flowCategory.relationships?.accounts?.map(account => {
      return {
        id: account.id, type: account.type
      }
    }) ?? [];
    if(flowCategory.attributes.public === true){
      const accIndex = accountData.findIndex(acc => acc.id == accountId);
      accountData.splice(accIndex,1);
    }else{
      accountData.push({id: accountId, type: 'accounts'});
    }
    const payload: FlowCategoryUpdateAccountsDto = {
      data: {
        id: flowCategory.id,
        type: flowCategory.type,
        relationships: {
          accounts: {
            data: accountData
          }
        }
      }
    }

    return payload;
  }
}