import { Component, DestroyRef, effect, model, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip, switchMap } from 'rxjs';
import { InvoicesService } from 'src/app/services/invoices.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UsersService } from 'src/app/services/users.service';
import { AccountManagementFormGroup } from 'src/app/utilities/models/accountManagement/forms';
import { ChangeRequestCreateDto } from 'src/app/utilities/models/dto/changeRequestCreateDto';
import { Invoice } from 'src/app/utilities/models/invoice/invoice';
import { User } from 'src/app/utilities/models/user/user';

@Component({
    selector: 'account-management-billing',
    templateUrl: './billing.component.html',
    styleUrl: './billing.component.scss',
    standalone: false
})
export class AccountManagementBillingComponent implements OnInit {
  formGroup: FormGroup;
  formGroups: AccountManagementFormGroup[] = [
    {
      label: 'Billing Discrepancy',
      fields: [
        {
          label: 'Invoice Number',
          name: 'invoice_number',
          required: true,
          type: 'invoices',
        },
        {
          label: 'Amount in Question',
          name: 'amount_in_question',
          required: true,
          type: 'text',
        },
        {
          label: 'Description of Discrepancy (e.g., Incorrect Charges, Missing Credits)',
          name: 'description_of_discrepancy',
          required: true,
          type: 'text',
        },
        {
          label: 'Additional Comments',
          name: 'additional_comment',
          required: false,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'Payment Inquiry',
      fields: [
        {
          label: 'Invoice Number',
          name: 'invoice_number_2',
          required: true,
          type: 'invoices',
        },
        {
          label: 'Payment Date',
          name: 'payment_date',
          required: true,
          type: 'date',
        },
        {
          label: 'Payment Method Used (e.g., Credit Card, Bank Transfer)',
          name: 'payment_method',
          required: true,
          type: 'text',
        },
        {
          label: 'Amount Paid or Pending',
          name: 'amount_paid_or_pending',
          required: true,
          type: 'text',
        },
        {
          label: 'Comments or Additional Details',
          name: 'additional_details',
          required: false,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'Request for Payment Extension',
      fields: [
        {
          label: 'Invoice Number',
          name: 'invoice_number_3',
          required: true,
          type: 'invoices',
        },
        {
          label: 'Amount Due',
          name: 'amount_due',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for Extension Request',
          name: 'reason_for_extension',
          required: true,
          type: 'text',
        },
        {
          label: 'Comments or Additional Details',
          name: 'comments',
          required: false,
          type: 'wysiwyg',
        }
      ]
    },
    {
      label: 'General Billing Inquiry',
      fields: [
        {
          label: 'Inquiry Type (e.g., Invoice Clarification, Account Statement)',
          name: 'inquiry_type',
          required: true,
          type: 'text',
        },
        {
          label: 'Relevant Invoice Number(s)',
          multiple: true,
          name: 'invoice_numbers',
          required: true,
          type: 'invoices',
        },
        {
          label: 'Description of Inquiry',
          name: 'description_of_inquiry',
          required: true,
          type: 'text',
        }
      ]
    },
    {
      label: 'Change billing contact information',
      fields: [
        {
          label: 'Provide the new details (e.g., Name, Email and Phone)',
          name: 'new_details',
          required: true,
          type: 'text',
        },
        {
          label: 'Reason for the Change',
          name: 'reason_for_change',
          required: true,
          type: 'text',
        },
        {
          label: 'Additional Comments',
          name: 'additional_comments',
          required: false,
          type: 'wysiwyg',
        }
      ]
    }
  ];
  formGroupOptions: string[] = [];
  invoices: Invoice[] = [];
  invoiceSearchSubject$ = new BehaviorSubject<string>('');
  isFetchingInvoices: boolean = false;
  isLoaderVisible: boolean = false;
  loggedInUser: User;
  selectedFormGroupIndex = model<number>(0);
  selectedFormGroup: AccountManagementFormGroup;
  selectedInvoiceId = signal<string | undefined>(undefined);

  constructor(private destroyRef: DestroyRef,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private invoicesService: InvoicesService,
              private loaderService: LoaderService,
              private userService: UsersService)
  {
    effect(() => {
      this.initFormGroup();
    });
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });

    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.selectedInvoiceId.set(params['invoiceId']);
      }
    });

    this.formGroupOptions = this.formGroups.map((item) => item.label);
    this.setInvoiceSearchSubject();
  }

  onSearchInvoices(value: string) {
    const query = value.trim();

    if (query.length == 0 || query.length > 2) {
      this.invoiceSearchSubject$.next(query);
    }
  }

  onSubmit() {
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your request is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your request was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: ChangeRequestCreateDto = this.preparePayload();
    this.invoicesService.billingQuestion(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.formGroup.reset();
          this.loaderService.setProcessing(false);

          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
            if (response.data.attributes.code) {
              this.router
                .navigate(['/app', 'support', 'tickets', response.data.attributes.code])
                .then();
            }
          }, 2000);
        },
        error: (error) => {
          this.loaderService.setLoaderVisible(false);
          console.error(error);
        }
      });
  }

  private initFormGroup() {
    this.formGroup = new FormGroup({});

    this.selectedFormGroup = this.formGroups[this.selectedFormGroupIndex()];
    this.selectedFormGroup?.fields?.forEach((field: any) => {
      let initialValue: any = '';

      switch (field.type) {
        case 'boolean': initialValue = false; break;
        case 'date': initialValue = new Date(); break;
        case 'invoices': initialValue = undefined; break;
        case 'number': initialValue = 1; break;
        case 'text':
        case 'wysiwyg': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      this.formGroup.addControl(field.name, formControl);

      if (field.type == 'invoices' && this.selectedInvoiceId()) {
        this.invoiceSearchSubject$.next(this.selectedInvoiceId() as string);

        setTimeout(() => {
          formControl.setValue((field?.multiple) ? [this.selectedInvoiceId()] : this.selectedInvoiceId());
        }, 500);
      }
    });


    this.formGroup.updateValueAndValidity();
  }

  private setInvoiceSearchSubject() {
    this.invoiceSearchSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(() => {
          this.invoices = [];
          this.isFetchingInvoices = true;
          return this.invoicesService.get_invoices([], 20, 1, 'uniq_number', 'asc', this.invoiceSearchSubject$.getValue());
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (response: any) => {
          this.invoices = response?.data?.map((item: any) => new Invoice(item, response.included)) ?? [];
          this.isFetchingInvoices = false;
        },
        error: (error) => {
          this.isFetchingInvoices = false;
          console.log(error);
        }
      })
  }

  private preparePayload(): ChangeRequestCreateDto {
    let description = `<p>${this.selectedFormGroup.label}</p><br><ul>`;
    Object.keys(this.formGroup.value).forEach((key: string) => {
      const field = this.selectedFormGroup?.fields.find((field) => field.name == key);

      if (field) {
        let value = this.formGroup.value[key];

        if (value != null && typeof value !== undefined) {
          if (field.type == 'boolean') {
            value = (value == true) ? 'Yes' : 'No';
          }

          if (field.type == 'invoices' && field.multiple) {
            value = value.join(', ');
          }

          description += `<li><b>${field.label}</b>: ${value}</li>`;
        }
      }
    });

    const ticketCreateAttributes = {
      subject: 'Billing and Invoice Assistance',
      description,
    }

    return new ChangeRequestCreateDto(ticketCreateAttributes);
  }
}
