import { Component, DestroyRef, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PostsService } from "src/app/services/posts.service";
import { UsersService } from "src/app/services/users.service";
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { Post } from "src/app/utilities/models/post/post";

@Component({
    selector: 'app-post-list',
    templateUrl: './postList.component.html',
    styleUrls: ['./postList.component.scss'],
    standalone: false
})
export class PostListComponent implements OnInit {
  hasPostCreatePermission: Permission | undefined;
  posts: Post[];
  postsLoading:boolean = false;
  userAccountId: number | undefined;
  userAccountTitle: string | undefined;

  constructor(private postService: PostsService,
              private userService: UsersService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.postsLoading = true;
    this.userAccountId = this.userService.loggedInUser?.relationships?.account?.id;
    this.userAccountTitle = this.userService.loggedInUser?.relationships?.account?.attributes.title;
    if(this.userAccountId) {
      this.postService.getPosts(20)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.posts = response.data.map((post: any) => new Post(post, response.included));
            this.postsLoading = false;
          }
        })
    }

    this.hasPostCreatePermission = this.userService.findPermission(
      'Digcore::Post',
      'ticketing/operator/v1/posts',
      'create'
    );
  }
}