import { Component } from '@angular/core';

@Component({
    selector: 'solution',
    templateUrl: './solution.component.html',
    styleUrls: ['./solution.component.scss'],
    standalone: false
})
export class Solution {

}
