import { Component, Input, type OnInit } from '@angular/core';
import { Flow } from 'src/app/utilities/models/ticket/flow/flow';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';
import { FlowCategoryWithChildren } from 'src/app/utilities/models/ticket/flowCategory/flowCategoryWithChildren';

@Component({
    selector: 'app-flow-category-item',
    templateUrl: './flowCategoryItem.component.html',
    styleUrls: ['./flowCategoryItem.component.scss'],
    standalone: false
})
export class FlowCategoryItemComponent implements OnInit {
  @Input() flowCategory: FlowCategory;
  @Input() childFlowCategories: FlowCategoryWithChildren[];
  showFlows: boolean = false;
  flows: Flow[];

  ngOnInit(): void {
    this.flows = this.flowCategory.relationships?.flows?.map((flow) => new Flow(flow, [])) ?? [];
  }

  showCategory(id: number | undefined) {
    if(this.showFlows == true) {
      this.showFlows = false;
      return;
    }

    this.showFlows = true;
    const elem = document.querySelector('#flowCategory_'+id);
    if (elem != null) {
      setTimeout(() => {
        elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 10)
    }
  }
}
