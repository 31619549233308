import { Component, DestroyRef, OnInit } from '@angular/core';
import { User } from 'src/app/utilities/models/user/user';
import { UsersService } from 'src/app/services/users.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { UserUpdateDto } from 'src/app/utilities/models/dto/userUpdateDto';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "user-information",
    templateUrl: "./userInformation.component.html",
    styleUrls: ['./userInformation.component.scss'],
    standalone: false
})
export class UserInformationComponent implements OnInit {
  user: User;
  permission: Permission | undefined;
  userInformationForm: FormGroup;
  time_zones: string[];
  loading: boolean = false;
  fieldInEditMode: string;
  validationErrorMessage: string = '';

  constructor(private userService: UsersService,
              private configurationService: ConfigurationsService,
              private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.user = this.userService.loggedInUser;
    this.userService.userSubject.subscribe(user => {
      if (user?.id) {
        this.user = user;
      }
    });
    this.permission = this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'update');
    this.configurationService.getTimezones()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (response?.data?.length) {
            this.time_zones = Object.values(response.data[0]?.attributes?.message);
          }
        }
      });
    this.initForm();
    this.setForm();
  }

  private initForm(){
    this.userInformationForm = new FormGroup({
      'firstname': new FormControl<string>({ value: '', disabled: this.disableByProperty('firstname') }, Validators.required),
      'lastname': new FormControl<string>({ value: '', disabled: this.disableByProperty('lastname') }, Validators.required),
      'middlename': new FormControl<string>({ value: '', disabled: this.disableByProperty('middlename') }),
      'email': new FormControl<string>({ value: '', disabled: this.disableByProperty('email') }),
      'avatar': new FormControl<string>({ value: '', disabled: this.disableByProperty('avatar') }),
      'mobile_phone': new FormControl<string>({ value: '', disabled: this.disableByProperty('mobile_phone') }),
      'office_phone': new FormControl<string>({ value: '', disabled: this.disableByProperty('office_phone') }),
      'extension': new FormControl<string>({ value: '', disabled: this.disableByProperty('extension') }),
      'time_zone': new FormControl<string>({ value: '', disabled: this.disableByProperty('time_zone') }),
      'job_title': new FormControl<string>({ value: '', disabled: this.disableByProperty('job_title') }),
      'password': new FormControl<string>('' ),
      'confirm_password': new FormControl<string>( '' ),
    })
  }

  private setForm(){
    this.userInformationForm.get('firstname')?.setValue(this.user.attributes.firstname);
    this.userInformationForm.get('lastname')?.setValue(this.user.attributes.lastname);
    this.userInformationForm.get('middlename')?.setValue(this.user.attributes.middlename);
    this.userInformationForm.get('email')?.setValue(this.user.attributes.email);
    this.userInformationForm.get('avatar')?.setValue(this.user.attributes.avatar);
    this.userInformationForm.get('mobile_phone')?.setValue(this.user.attributes.mobile_phone);
    this.userInformationForm.get('office_phone')?.setValue(this.user.attributes.office_phone);
    this.userInformationForm.get('extension')?.setValue(this.user.attributes.extension);
    this.userInformationForm.get('time_zone')?.setValue(this.user.attributes.time_zone);
    this.userInformationForm.get('job_title')?.setValue(this.user.attributes.job_title);
  }

  disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  updateAttribute(attributeChanged: string){
    this.loading = true;
    const formControl = this.userInformationForm.get(attributeChanged);
    if(formControl) {
      const payload = this.prepareSinglePayload(formControl, attributeChanged);
      this.userService.updateUser(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: _ => {
            this.userService.getCurrentUser();
            this.fieldInEditMode = '';
          },
          error: (error: Error) => { console.log(error) },
          complete:() => { this.loading = false; }
        });

      return true;
    }

    return false;
  }

  setEditModeForField(fieldKey: string){
    this.fieldInEditMode = fieldKey;
  }

  private prepareSinglePayload(formControl: AbstractControl, attributeChanged: string): UserUpdateDto{
    const userUpdateAttributes = {
      [attributeChanged]: formControl.value
    }
    const userUpdateRelationships = {};

    return new UserUpdateDto(this.user.id, "users", userUpdateAttributes, userUpdateRelationships);
  }

  private preparePasswordPayload(): UserUpdateDto{
    const userUpdateAttributes = {
      password: this.userInformationForm.get('password')?.value,
      confirm_password: this.userInformationForm.get('confirm_password')?.value
    }
    const userUpdateRelationships = {};

    return new UserUpdateDto(this.user.id, "users", userUpdateAttributes, userUpdateRelationships);
  }

  updatePassword() {
    this.loading = true;
    const payload = this.preparePasswordPayload();
    this.userService.updateUser(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: _ => {
          this.userService.getCurrentUser();
          this.fieldInEditMode = '';
        },
        error: (error: Error) => { console.log(error) },
        complete:() => { this.loading = false; }
      });
  }

  setPhoneFieldData(data: any | undefined, attributeChanged: string){
    this.userInformationForm.get(attributeChanged)?.setValue(data)
  }

  isDisabled(formControlName: string){
    const value = this.userInformationForm.get(formControlName)?.value;

    return typeof value === 'undefined';
  }
}
