import { Component, DestroyRef, OnInit } from "@angular/core";
import { Conversation } from "src/app/utilities/models/ticket/conversation/conversation";
import { ConversationsService } from "src/app/services/conversations.service";
import { take } from "rxjs";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { TicketsService } from "src/app/services/tickets.service";
import { ActivatedRoute } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: 'ticket-options',
    templateUrl: './ticketOptions.component.html',
    styleUrls: ['./ticketOptions.component.scss'],
    standalone: false
})
export class TicketOptionsComponent implements OnInit {
  conversationArray: Conversation[] = [];
  activeConversationId: number;
  activeConversation: Conversation;
  selectedConversation: Conversation;
  urlFragment: string | null;
  ticket: Ticket;
  activeContent: string = 'conversations';

  constructor(private ticketService: TicketsService,
              private conversationService: ConversationsService,
              private route: ActivatedRoute,
              private destroyRef: DestroyRef) {

  }

  ngOnInit() {
    this.urlFragment = this.route.snapshot.fragment;
    this.ticketService.ticket
      .pipe(
        take(1)
      )
      .subscribe({
        next: (ticket: Ticket) => {
          this.ticket = ticket;
          this.urlFragment ? this.fetchConversations(this.urlFragment) : this.fetchConversations();
        }
      });
  }

  fetchConversations(fragment: string | null = null) {
    this.conversationArray = [];
    this.conversationService.getConversations(this.ticket.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.conversationArray = response.data.map((conversation: any) => new Conversation(conversation, response.included));
          this.conversationService.setConversations(this.conversationArray);
          if (fragment) {
            const conversation = this.getConversationFromFragment(this.conversationArray, fragment);
            this.setActiveConversation(conversation);
            if(conversation) {
              this.selectedConversation = conversation;
            }
          } else if (this.conversationArray.length) {
            this.setActiveConversation(this.conversationArray[0]);
            this.selectedConversation = this.conversationArray[0];
          }
        }
      });
  }

  setActiveConversation(selected: Conversation | null | undefined = null) {
    if (selected) {
      this.activeConversationId = selected.id;
      this.activeConversation = selected;
      this.conversationService.setActiveConversation(selected.id);
      this.selectedConversation = selected;
    }else{
      this.conversationService.setActiveConversation(undefined);
    }
  }

  getConversationFromFragment(conversationArray: Conversation[], fragment: string): Conversation | undefined {
    const conversationId = fragment.split('_')[0];
    return conversationArray.find(conversation => conversation.id == +conversationId);
  }

  setActiveContent(value: string) {
    this.activeContent = value;
    switch(value){
      case 'attachments':
        this.setActiveConversation(undefined);
        break;
      case 'conversations':
        this.setActiveConversation(this.conversationArray[0]);
        this.selectedConversation = this.conversationArray[0];
        break;
    }
  }

}