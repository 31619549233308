import {Component, DestroyRef, OnInit} from '@angular/core';
import {Incident} from "src/app/utilities/models/incident/incident";
import {IncidentsService} from "src/app/services/incidents.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ActivatedRoute, Params} from "@angular/router";
import {IncidentUpdate} from "src/app/utilities/models/incident/incidentUpdate";
import {UsersService} from "src/app/services/users.service";
import {TZDate, tzOffset} from "@date-fns/tz";
import {format} from "date-fns";

@Component({
    selector: 'app-incident-show',
    templateUrl: './incident-show.component.html',
    styleUrl: './incident-show.component.scss',
    standalone: false
})
export class IncidentShowComponent implements OnInit {
  loading: boolean = false;
  incidentUpdatesLoading: boolean = false;
  incident: Incident;
  incidentUpdates: IncidentUpdate[];
  incidentTimer: { days: number; hours: number; minutes: number; seconds: number } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  userTimezone: string | undefined;

  constructor(private incidentService: IncidentsService,
              private activatedRoute: ActivatedRoute,
              private userService: UsersService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit(): void {
    this.userTimezone = this.userTimezone = (isNaN(tzOffset((this.userService.loggedInUser.attributes?.time_zone ?? ''), new Date())))
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : this.userService.loggedInUser.attributes.time_zone;
    this.activatedRoute.params.subscribe({
        next: (params: Params) => {
          this.getIncident(params['id']);
          this.getIncidentUpdates(params['id']);
        }
      }
    );
  }

  startTimer() {
    if (this.incident.attributes.ended_at && this.incident.relationships?.incident_status?.attributes?.state == 'closed') {
      const now = new TZDate(this.incident.attributes.ended_at, this.userTimezone).getTime();
      const startTime = new TZDate(this.incident.attributes.started_at, this.userTimezone).getTime();
      const diff = now - startTime;
      this.incidentTimer = this.calculateTime(diff);
    } else {
      if(this.incident.relationships?.incident_status?.attributes?.state == 'open') {
        setInterval(() => {
          const now = new TZDate().getTime();
          const startTime = new TZDate(this.incident.attributes.started_at, this.userTimezone).getTime();
          const diff = now - startTime;
          this.incidentTimer = this.calculateTime(diff);
        }, 1000);
      }
    }
  }

  calculateTime(milliseconds: number): { days: number; hours: number; minutes: number; seconds: number } {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
      days: days,
      hours: hours % 24,
      minutes: minutes % 60,
      seconds: seconds % 60,
    };
  }

  getIncident(id: number) {
    this.loading = true;
    this.incidentService.getIncident(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.incident = new Incident(response.data, response.included);
          this.loading = false;
          this.startTimer();
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  getIncidentUpdates(id: number) {
    this.incidentUpdatesLoading = true;
    this.incidentService.getIncidentUpdates(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.incidentUpdates = response.data.map((row: any) => new IncidentUpdate(row, response.included));
          this.incidentUpdatesLoading = false;
        },
        error: () => {
          this.incidentUpdatesLoading = false;
        }
      })
  }

  showDateInUsersTimezone(date: string) {
    return format(new TZDate(date, this.userTimezone), "MMM d yyyy, HH:mm");
  }
}
