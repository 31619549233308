<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'changes']">Changes</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="changelog?.attributes?.title">{{ changelog.attributes.title }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','changes']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <div nz-row nzJustify="center" *ngIf="loading">
        <nz-spin nzSimple></nz-spin>
      </div>
    </div>
    <div nz-row *ngIf="changelog" nzJustify="center">
      <div nz-col nzSpan="24" [nzXl]="24" [nzXXl]="18">
        <div nz-row>
          <div nz-col nzSpan="16" class="changelog-title-container">
            <div class="changelog-title">
              {{ changelog.attributes.title }}
            </div>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="16" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="16" class="left-content">
            <div nz-row class="changelog-details">
              <div nz-col nzSpan="24">
                <div class="attribute">
                  <div class="attribute-label">Title</div>
                  <div class="attribute-value">{{ changelog.attributes.title }}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">When</div>
                  <div class="attribute-value">{{ showDateInUsersTimezone(changelog.attributes.occurs_at) }}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Risk Level</div>
                  <div class="attribute-value">
                    <span class="changelog-risk-level" *ngIf="changelog.relationships?.change_risk_level?.id">{{ changelog.relationships?.change_risk_level?.attributes?.title }}</span>
                    <span *ngIf="!changelog.relationships?.change_risk_level?.id">-</span>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Status</div>
                  <div class="attribute-value">
                    <span class="">{{ changelog.relationships?.change_status?.attributes?.title }}</span>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Type</div>
                  <div class="attribute-value">
                    <span class="" *ngIf="changelog.relationships?.change_type?.id">{{ changelog.relationships?.change_type?.attributes?.title }}</span>
                    <span *ngIf="!changelog.relationships?.change_type?.id">-</span>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Description</div>
                  <div class="attribute-value description" [innerHtml]="changelog.attributes.description || '-'"></div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Implementation plan</div>
                  <div class="attribute-value description" [innerHtml]="changelog.attributes.implementation_plan || '-'"></div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Test plan</div>
                  <div class="attribute-value description" [innerHtml]="changelog.attributes.test_plan || '-'"></div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Rollback plan</div>
                  <div class="attribute-value description" [innerHtml]="changelog.attributes.rollback_plan || '-'"></div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Required outcome</div>
                  <div class="attribute-value description" [innerHtml]="changelog.attributes.required_outcome || '-'"></div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Resources required</div>
                  <div class="attribute-value assignees">
                    <div *ngFor="let assignee of changelog.relationships?.assignees" class="assignee">
                      <nz-avatar [nzSize]="16" nzIcon="user" [nzSrc]="assignee.attributes.avatar"></nz-avatar>
                      {{ assignee.attributes.fullname }}
                    </div>
                    <div *ngIf="!changelog.relationships?.assignees"><span>-</span></div>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Related Tickets</div>
                  <div class="attribute-value changelog-tickets">
                    <div *ngFor="let ticket of changelog.relationships?.tickets">
                      <a [routerLink]="['/app','support','tickets',ticket.attributes.code]">{{ ticket.attributes.code }} - {{ ticket.attributes.subject }}</a>
                    </div>
                    <div *ngIf="!changelog.relationships?.tickets"><span>-</span></div>
                  </div>
                </div>
                <div class="attribute last">
                  <div class="attribute-label">Created By</div>
                  <div class="attribute-value">{{ changelog.relationships?.creator?.attributes?.fullname}}</div>
                </div>
              </div>

            </div>
          </div>
          <div nz-col nzSpan="8" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="8">
            <div class="changelog-side">
              <div class="changelog-decisions">
                <div class="changelog-decisions-label">
                  Change Decisions
                  <ng-container *ngIf="false">
                    <span nz-tooltip [nzTooltipTitle]="approvalDecisionsTemplate" nzTooltipColor="#fff">
                      <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                    </span>
                    <ng-template #approvalDecisionsTemplate>
                      <div class="approval-decisions-tooltip">
                        <div>Change requires {{ changelog.attributes.approvals_required }} approvals</div>
                        <div *ngIf="changelog.relationships?.agent_approvers?.length" class="">Agent Approvers</div>
                        <ng-container *ngFor="let agentApprover of changelog.relationships?.agent_approvers">

                        </ng-container>
                        <div *ngIf="changelog.relationships?.board_approvers?.length" class="">Board Approvers</div>
                        <div></div>
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
                <div class="decisions-required">
                  <div class="badge-approved" *ngIf="isApproved">
                    <span nz-icon nzType="check" nzTheme="outline"></span>
                    Approved
                  </div>
                  <div class="badge-pending-approval" *ngIf="!isApproved">
                    <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
                    Pending
                  </div>
                  <span class="text">Change requires <span>{{ changelog.attributes.approvals_required }} approvals</span></span>
                </div>
                <ng-container *ngFor="let approvalDecision of lastApprovalDecisions">
                  <div class="approval-decision">
                    <div>
                      <div class="approval-decision-agent">
                        <nz-avatar [nzSize]="16" nzIcon="user" [nzSrc]="approvalDecision.relationships?.agent?.attributes?.avatar ?? ''"></nz-avatar>
                        <span>{{ approvalDecision.relationships?.agent?.attributes?.fullname }}</span>
                      </div>
                      <div class="approval-decision-decision" [ngClass]="approvalDecision.attributes.decision">
                        {{ approvalDecision.attributes.decision }} the change
                      </div>
                    </div>

                    <div class="approval-decision-icon" [ngClass]="approvalDecision.attributes.decision" *ngIf="approvalDecision.attributes.decision == 'approved'"><span nz-icon nzType="check" nzTheme="outline"></span></div>
                    <div class="approval-decision-icon" [ngClass]="approvalDecision.attributes.decision" *ngIf="approvalDecision.attributes.decision == 'rejected'"><span nz-icon nzType="close" nzTheme="outline"></span></div>
                    <div class="approval-decision-date">{{ approvalDecision.attributes.created_at | dateAgo }}</div>
                  </div>
                </ng-container>
                <div *ngIf="lastApprovalDecisions.length == 0" class="approval-decision">No decisions yet</div>
              </div>
              <div class="changelog-updates">
                <div class="changelog-updates-label">Latest Updates</div>
                <div *ngIf="changelogUpdates && changelogUpdates.length == 0">No updates yet</div>
                <div class="changelog-update-items">
                  <div *ngFor="let changelogUpdate of changelogUpdates" class="changelog-update">
                    <div class="changelog-update-head">
                      <div class="changelog-update-user">
                        <nz-avatar [nzSize]="16" nzIcon="user" [nzSrc]="changelogUpdate.relationships?.user?.attributes?.avatar ?? ''"></nz-avatar>
                        <span>{{ changelogUpdate.relationships?.user?.attributes?.fullname }}</span>
                      </div>
                      <div class="changelog-update-date">
                        {{ changelogUpdate.attributes.created_at | dateAgo }}
                      </div>
                    </div>
                    <div class="changelog-update-content" [innerHTML]="changelogUpdate.attributes.description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
