import { Component, DestroyRef, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FlowsService } from 'src/app/services/flows.service';
import { UsersService } from 'src/app/services/users.service';
import { FlowUpdateData, FlowUpdateDto } from 'src/app/utilities/models/dto/flowUpdateDto';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { RelationshipData } from 'src/app/utilities/models/relationshipData';
import { Flow } from 'src/app/utilities/models/ticket/flow/flow';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';
import { FlowControlAttributes } from 'src/app/utilities/models/ticket/flowControl/flowControlAttributes';
import { FlowControl } from 'src/app/utilities/models/ticket/flowControl/flowControl';
import PermissionHelper from 'src/app/utilities/helpers/permissionHelper';
import { FlowControlUpdatePositionDto } from 'src/app/utilities/models/dto/flowControlUpdatePositionDto';
import { SortableEvent } from 'ngx-sortable';

@Component({
    selector: 'app-flow',
    templateUrl: './flow.component.html',
    styleUrls: ['./flow.component.scss'],
    standalone: false
})
export class FlowComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  permission: Permission | undefined;
  permissionFlowControlCreate: Permission | undefined;
  permissionFlowControlUpdate: Permission | undefined;
  flow: Flow;
  flowControls: FlowControl[];
  flowCategories: FlowCategory[];
  flowControlInEdit: FlowControl | undefined;
  flowControlTypes: string[] = ['input','select','date','wysiwyg'];
  showCreateFlowControlForm: boolean = false;
  platforms: {label: string, value: string}[] = [
    {label: 'Deskware', value: 'Deskware'},
    {label: 'Onspring', value: 'Onspring'},
  ];

  constructor(private userService: UsersService,
              private flowService: FlowsService,
              private destroyRef: DestroyRef,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private msg: NzMessageService) {
  }

  ngOnInit(): void {
    this.permission = this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'update');
    this.permissionFlowControlCreate = this.userService.findPermission('Ticketing::FlowControl', 'ticketing/operator/v1/flow_controls', 'create');
    this.permissionFlowControlUpdate = this.userService.findPermission('Ticketing::FlowControl', 'ticketing/operator/v1/flow_controls', 'update');

    this.initForm();
    this.getFlowCategories();

    this.activatedRoute.params
      .subscribe({
        next: (params: Params) => {
          if(params['id'] > 0) {
            this.getFlow(params['id']);
          }
        }
      }
    );
  }

  initForm() {
    this.form = new FormGroup({
      'active': new FormControl<boolean>({ value: true, disabled: PermissionHelper.disabledByProperty(this.permission, 'active') }, Validators.required ),
      'title': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'title') }, Validators.required ),
      'platform_name': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'platform_name') }, Validators.required ),
      'subject': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'subject') }),
      'label': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'label') }),
      'flow_categories': new FormControl<number[]>({ value: [], disabled: PermissionHelper.disabledByProperty(this.permission, 'flow_categories') }),
    })
  }

  getFlow(id: number) {
    this.loading = true;
    this.flowService.getFlow(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.flow = new Flow(response.data, response.included);
          this.flowControls = this.flow.relationships?.flow_controls?.map((control: any) => new FlowControl(control, [])) ?? [];
          this.setForm();
          this.loading = false;
        },
        error: (error) => {
          console.log(error);
          this.loading = false;
        },
        complete: () => {},
      })
  }

  getFlowCategories() {
    this.flowService.getFlowCategories()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.flowCategories = response.data.map((row: any)=> new FlowCategory(row, response.included));
          if(this.flow?.id){
            this.form.get('flow_categories')?.setValue(this.flow.relationships?.flow_categories?.map(category => category.id) ?? []);
          }
        },
        error: (error) => { console.log(error); },
        complete: () => {},
      })
  }

  setForm() {
    this.form.get('active')?.setValue(this.flow.attributes.active);
    this.form.get('title')?.setValue(this.flow.attributes.title);
    this.form.get('platform_name')?.setValue(this.flow.attributes.platform_name);
    this.form.get('subject')?.setValue(this.flow.attributes.subject);
    this.form.get('label')?.setValue(this.flow.attributes.label);
    this.form.get('flow_categories')?.setValue(this.flow.relationships?.flow_categories?.map(category => category.id) ?? []);
  }

  onSubmit() {
    if(!this.form.valid) {
      this.msg.error('Please fill in all required fields!', { nzDuration: 3000, nzPauseOnHover: false });
      return
    };

    this.loading = true;
    const payload: FlowUpdateDto = this.prepareUpdatePayload();
    this.flowService.updateFlow(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  setFlowControlInEdit(flowControlRel: RelationshipData<FlowControlAttributes> | undefined) {
    if(!flowControlRel) {
      this.flowControlInEdit = flowControlRel;
      return;
    }

    const flowControl = new FlowControl(flowControlRel, []);
    this.flowControlInEdit = flowControl;
    // hide the new control form
    this.setShowCreateFlowControlForm(false);
  }

  setShowCreateFlowControlForm(value: boolean) {
    this.showCreateFlowControlForm = value;
    // hide the edit form
    if(value === true) this.flowControlInEdit = undefined;
  }

  delete() {
    this.flowService.deleteFlow(this.flow.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.router.navigate(['/app', 'flows']).then(_ => false);
        },
        error: () => {},
        complete: () => {},
      })
  }

  flowControlChangedPosition(dropData: SortableEvent) {
    const flowControlId = dropData.item.id;
    const newPosition = (dropData.newIndex && dropData.newIndex > 0) ? dropData.newIndex + 1 : 1;
    if(flowControlId > 0 && newPosition > 0) {
      const payload = this.prepareUpdatePositionPayload(flowControlId, newPosition);
      this.flowService.updateFlowControlPosition(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (_response: any) => {
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
          },
          complete: () => {
            this.loading = false;
          }
        });
    }
  }

  private prepareUpdatePayload() {
    const categories = this.form.get('flow_categories')?.value.map((id: number) => {
      return {
        id: id,
        type: 'flow_categories'
      }
    }) ?? [];
    const data: FlowUpdateData = {
      id: this.flow.id,
      type: 'flows',
      attributes: {
        title: this.form.get('title')?.value,
        platform_name: this.form.get('platform_name')?.value,
        subject: this.form.get('subject')?.value,
        label: this.form.get('label')?.value,
        active: this.form.get('active')?.value,
      },
      relationships: {
        flow_categories: {
          data: categories
        }
      }
    }

    return new FlowUpdateDto(data);
  }

  private prepareUpdatePositionPayload(id: number, position: number): FlowControlUpdatePositionDto {
    const data = {
      id: id,
      type: 'flow_controls',
      attributes: {
        position: position
      }
    }

    return new FlowControlUpdatePositionDto(data);
  }
}
