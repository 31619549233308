import { Component, DestroyRef, effect, model, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { UsersService } from 'src/app/services/users.service';
import { AccountManagementFormGroup } from 'src/app/utilities/models/accountManagement/forms';
import { Ticket } from 'src/app/utilities/models/ticket/ticket';
import { User } from 'src/app/utilities/models/user/user';
import { TicketCreateDto, TicketCreateDtoRelationship } from 'src/app/utilities/models/dto/ticketCreateDto';

@Component({
    selector: 'account-management-feedback',
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss',
    standalone: false
})
export class AccountManagementFeedbackComponent implements OnInit {
  formGroup: FormGroup;
  formGroups: AccountManagementFormGroup[] = [
    {
      label: 'Service Quality',
      fields: [
        {
          label: 'Comments on Service Quality',
          name: 'service_quality',
          required: true,
          type: 'text',
        },
        {
          label: 'Suggestions for Improvement',
          name: 'suggestions',
          required: true,
          type: 'text',
        },
        {
          label: 'Ticket Number(s)',
          name: 'ticket_number',
          required: true,
          type: 'tickets',
        }
      ]
    },
    {
      label: 'Response Time',
      fields: [
        {
          label: 'Comments on Response Time',
          name: 'response_time_comments',
          required: true,
          type: 'text',
        },
        {
          label: 'Suggestions for Improvement',
          name: 'suggestions',
          required: true,
          type: 'text',
        },
        {
          label: 'Ticket Number(s)',
          name: 'ticket_number',
          required: true,
          type: 'tickets',
        }
      ]
    },
    {
      label: 'Communication',
      fields: [
        {
          label: 'Comments on Response Time',
          name: 'response_time_comments',
          required: true,
          type: 'text',
        },
        {
          label: 'Suggestions for Better Communication',
          name: 'suggestions',
          required: true,
          type: 'text',
        },
        {
          label: 'Ticket Number(s)',
          name: 'ticket_number',
          required: true,
          type: 'tickets',
        }
      ]
    },
    {
      label: 'Issue Resolution',
      fields: [
        {
          label: 'What the Issue Resolved?',
          name: 'issue_resolved',
          required: true,
          type: 'boolean',
        },
        {
          label: 'Comments on Issue Resolution',
          name: 'resolution_comments',
          required: true,
          type: 'text',
        },
        {
          label: 'Ticket Number(s)',
          name: 'ticket_number',
          required: true,
          type: 'tickets',
        },
        {
          label: 'Further Assistance Needed?',
          name: 'further_assistance',
          required: true,
          type: 'boolean',
        },
      ]
    },
    {
      label: 'Other / General',
      fields: [
        {
          label: 'General Comments on Service Delivery',
          name: 'general_comments',
          required: true,
          type: 'wysiwyg',
        }
      ]
    }
  ];
  formGroupOptions: string[] = [];
  isFetchingTickets: boolean = false;
  isLoaderVisible: boolean = false;
  loggedInUser: User;
  selectedFormGroupIndex = model<number>(0);
  selectedFormGroup: AccountManagementFormGroup;
  tickets: Ticket[] = [];
  ticketSearchSubject$ = new BehaviorSubject<string>('');

  constructor(private destroyRef: DestroyRef,
              private router: Router,
              private loaderService: LoaderService,
              private ticketService: TicketsService,
              private userService: UsersService)
  {
    effect(() => {
      this.initFormGroup();
    });
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });

    this.formGroupOptions = this.formGroups.map((item) => item.label);
    this.setTicketSearchSubject();
  }

  onSearchTickets(value: string) {
    const query = value.trim();

    if (query.length == 0 || query.length > 2) {
      this.ticketSearchSubject$.next(query);
    }
  }

  onSubmit() {
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your request is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your request was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: TicketCreateDto = this.preparePayload();
    this.ticketService.createTicket(payload).subscribe({
      next: (response: any) => {
        this.formGroup.reset();
        this.loaderService.setProcessing(false);

        setTimeout(() => {
          this.loaderService.setLoaderVisible(false);
          if (response.data.attributes.code) {
            this.router
              .navigate(['/app', 'support', 'tickets', response.data.attributes.code])
              .then();
          }
        }, 2000);
      }
    });
  }

  private initFormGroup() {
    this.formGroup = new FormGroup({});

    this.selectedFormGroup = this.formGroups[this.selectedFormGroupIndex()];
    this.selectedFormGroup?.fields?.forEach((field: any) => {
      let initialValue: any = '';

      switch (field.type) {
        case 'boolean': initialValue = false; break;
        case 'date': initialValue = new Date(); break;
        case 'number': initialValue = 1; break;
        case 'tickets': initialValue = []; break;
        case 'text':
        case 'wysiwyg': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      this.formGroup.addControl(field.name, formControl);
    });

    this.formGroup.updateValueAndValidity();
  }

  private setTicketSearchSubject() {
    this.ticketSearchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        switchMap(() => {
          this.tickets = [];
          this.isFetchingTickets = true;
          return this.ticketService.getTickets({
            include: '',
            query: {
              q: this.ticketSearchSubject$.getValue(),
              searchFields: ['code','subject','stripped_description']
            },
            reportParams: [
              { key: 'statuses.state', value: ['in_progress'], operator: ['in'] },
              { key: 'merged_into', value: ['false'], operator: ['eq'] }
            ]
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (response: any) => {
          this.tickets = response?.data?.map((item: any) => new Ticket(item, response.included)) ?? [];
          this.isFetchingTickets = false;
        },
        error: (error) => {
          this.isFetchingTickets = false;
          console.log(error);
        }
      })
  }

  private prepareData(type: string, valueId: number) {
    return { data: { id: valueId, type: type }};
  }

  private preparePayload(): TicketCreateDto {
    const subject = 'Share Feedback';
    let description = `<p>${this.selectedFormGroup.label}</p><br><ul>`;
    Object.keys(this.formGroup.value).forEach((key: string) => {
      const field = this.selectedFormGroup?.fields.find((field) => field.name == key);

      if (field) {
        let value = this.formGroup.value[key];

        if (value != null && typeof value !== undefined) {
          if (field.type == 'boolean') {
            value = (value == true) ? 'Yes' : 'No';
          }

          if (field.type == 'tickets' && field.multiple) {
            value = value.join(', ');
          }

          description += `<li><b>${field.label}</b>: ${value}</li>`;
        }
      }
    });
    description += '<ul>';

    const ticketCreateAttributes = { subject, description };
    const ticketCreateRelationships: TicketCreateDtoRelationship = {
      account: this.prepareData('accounts', this.loggedInUser!.relationships!.account!.id),
      requester: this.prepareData('users', this.loggedInUser.id),
    };

    return new TicketCreateDto('tickets', ticketCreateAttributes, ticketCreateRelationships);
  }
}
