import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UsersService } from 'src/app/services/users.service';
import { Permission } from 'src/app/utilities/models/permissions/permission';

interface ICard {
  icon: string;
  description: string;
  title: string;
}

@Component({
    selector: 'app-account-management',
    templateUrl: './account-management.component.html',
    styleUrl: './account-management.component.scss',
    standalone: false
})
export class AccountManagementComponent implements OnInit {
  hasTicketChangeStatusPermission: Permission | undefined;
  hasUsersIndexPermission: Permission | undefined;

  billingCard: ICard = {
    icon: 'dw:billing',
    title: 'Billing and Invoice Assistance',
    description: 'Need help with a billing or invoicing matter? We’re here to assist you. Please use the form below to submit your inquiry, and our team will respond promptly to resolve any questions or concerns regarding your account. Your satisfaction is our priority.',
  }

  contractsCard: ICard = {
    icon: 'dw:contractManagement',
    title: 'Contract Management',
    description: 'Have questions or requests related to your contract? Our team is here to help. Use the form below to manage your contract needs, including renewals, modifications, or clarifications. We’re committed to ensuring that your contract aligns with your business requirements.',
  }

  feedbackCard: ICard = {
    icon: 'dw:feedback',
    title: 'Share Feedback',
    description: 'Your input helps us continually improve our services. Please take a moment to share your feedback. Whether it’s about service quality, communication, or response time, we want to hear from you. Your insights are crucial in helping us serve you better.',
  }

  issuesCard: ICard = {
    icon: 'dw:issueResolution',
    title: 'Issue Resolution and Management',
    description: 'We are dedicated to providing effective support and managing incidents swiftly. Use the form below to request assistance with support coordination, escalate an issue, or follow up on a resolved incident. Our team is committed to ensuring a thorough and efficient process.',
  }

  managePeopleCard: ICard = {
    icon: '',
    title: 'Manage People',
    description: 'Manage the people in your organization. You can add new people, assign roles, update details, and control permissions to ensure everyone has the right access and tools they need.',
  }

  returnCard: ICard = {
    icon: 'dw:returnAnOrder',
    title: 'Return an Order',
    description: 'Need to return an item? We’re here to assist you with the process. Please fill out the form below to initiate a return, and our team will guide you through the necessary steps to complete it smoothly.',
  }

  upgradesCard: ICard = {
    icon: 'dw:upgrades',
    title: 'Upgrades',
    description: 'When your business needs new hardware, updated software, or additional services to optimize performance, you’re in the right place. Please use the form below to request upgrades that will help improve your operations. Your Account Manager will review your request and get in touch to discuss the best solutions to meet your needs.',
  }

  constructor(private modal: NzModalService,
              private userService: UsersService) {}

  ngOnInit() {
    this.hasTicketChangeStatusPermission = this.userService.findPermission(
      'Ticketing::Ticket',
      'ticketing/operator/v1/tickets',
      'change_status'
    );
    this.hasUsersIndexPermission = this.userService.findPermission(
      'Digcore::User',
      'ticketing/operator/v1/users',
      'index'
    );
  }

  onShowMoreInfo(card: ICard, e: Event) {
    e.stopPropagation();

    this.modal.create({
      nzCancelText: null,
      nzCentered: true,
      nzClosable: false,
      nzContent: card.description,
      nzTitle: card.title,
    });
  }
}
