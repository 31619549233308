import { Component } from "@angular/core";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { UsersService } from "src/app/services/users.service";
import { User } from "src/app/utilities/models/user/user";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-solution',
    templateUrl: './solution.component.html',
    styleUrls: ['./solution.component.scss'],
    standalone: false
})
export class SolutionComponent {
  portsPermission: Permission | undefined;
  invoicesPermission: Permission | undefined;
  assetResourcesPermission: Permission | undefined;
  installedServicePermission: Permission | undefined;
  installedServiceCreatePermission: Permission | undefined;
  flowCreatePermission: Permission | undefined;
  serviceHistoriesPermission: Permission | undefined;
  meetingsPermission: Permission | undefined;
  incidentIndexPermission: Permission | undefined;
  changelogIndexPermission: Permission | undefined;
  usersPermission: Permission | undefined;
  loggedInUser: User;
  comingSoonFeatures: string[] = environment.production ? [] : [];
  userRoleKey: string = '';
  userType: string = '';

  constructor(private userService: UsersService) {}

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    this.userRoleKey = this.loggedInUser?.relationships?.role?.attributes?.system_key ?? '';
    this.userType = this.loggedInUser?.attributes?.type ?? '';
    this.portsPermission = this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'index');
    this.invoicesPermission = this.userService.findPermission('Digcore::Invoice', 'ticketing/operator/v1/invoices', 'index');
    this.assetResourcesPermission = this.userService.findPermission('Ticketing::AssetResource', 'ticketing/operator/v1/asset_resources', 'index');
    this.installedServicePermission = this.userService.findPermission('Digcore::InstalledService', 'ticketing/operator/v1/installed_services', 'index');
    this.installedServiceCreatePermission = this.userService.findPermission('Digcore::InstalledService', 'ticketing/operator/v1/installed_services', 'create');
    this.flowCreatePermission = this.userService.findPermission('Ticketing::Flow', 'ticketing/operator/v1/flows', 'create');
    this.serviceHistoriesPermission = this.userService.findPermission('Digcore::ServiceHistory', 'ticketing/operator/v1/service_histories', 'index');
    this.meetingsPermission = this.userService.findPermission(
      'Ticketing::Meeting',
      'ticketing/operator/v1/meetings',
      'index'
    );
    this.incidentIndexPermission = this.userService.findPermission('Ticketing::Incident', 'ticketing/operator/v1/incidents', 'index');
    this.changelogIndexPermission = this.userService.findPermission('Ticketing::ChangeLog', 'ticketing/operator/v1/change_logs', 'index');
    this.usersPermission = this.userService.findPermission(
      'Digcore::User',
      'ticketing/operator/v1/users',
      'index'
    );
  }
}
