import { Component, DestroyRef, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { take } from 'rxjs';
import { AccountsService } from 'src/app/services/accounts.service';
import { FlowsService } from 'src/app/services/flows.service';
import { UploadsService } from 'src/app/services/uploads.service';
import { UsersService } from 'src/app/services/users.service';
import PermissionHelper from 'src/app/utilities/helpers/permissionHelper';
import { Account } from 'src/app/utilities/models/account/account';
import { FlowCategoryCreateData, FlowCategoryCreateDto } from 'src/app/utilities/models/dto/flowCategoryCreateDto';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';
import { Upload } from 'src/app/utilities/models/upload/upload';

@Component({
    selector: 'app-flow-category-new',
    templateUrl: './flow-category-new.component.html',
    styleUrls: ['./flow-category-new.component.scss'],
    standalone: false
})
export class FlowCategoryNewComponent implements OnInit {
  form: FormGroup;
  isUploading = false;
  loading: boolean = false;
  upload: Upload;
  permission: Permission | undefined;
  fileList: NzUploadFile[] = [];
  createdFlowCategoryId: number;
  accounts: Account[] = [];
  accountsLoading: boolean = false;
  flowCategoriesLoading:boolean = false;
  flowCategories: FlowCategory[];

  constructor(private userService: UsersService,
              private uploadService: UploadsService,
              private flowService: FlowsService,
              private accountService: AccountsService,
              private router: Router,
              private destroyRef: DestroyRef,
              private msg: NzMessageService) {

  }

  ngOnInit(): void {
    this.permission = this.userService.findPermission('ticketing::FlowCategory', 'ticketing/operator/v1/flow_categories', 'create');
    this.initForm();
    this.getFlowCategories();
  }

  onSubmit() {
    if(!this.form.valid) return;

    this.loading = true;
    const payload: FlowCategoryCreateDto = this.prepareCreatePayload();

    this.flowService.createFlowCategory(payload)
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          //this.router.navigate(['/app/flow-categories/'+response.data.id]).then( _ => {});
          this.createdFlowCategoryId = response.data.id;
          if(this.fileList.length) {
            this.handleUpload();
          }else{
            this.router.navigate(['/app/flow-categories/'+this.createdFlowCategoryId]).then( _ => {});
          }
        },
        error:(err) => {
          this.loading = false;
          this.msg.error(err?.error?.errors[0]?.detail, { nzDuration: 3000, nzPauseOnHover: false });
        }
      });
  }

  initForm() {
    this.form = new FormGroup({
      'title': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'title') }, Validators.required ),
      'description': new FormControl<string>({ value: '', disabled: PermissionHelper.disabledByProperty(this.permission, 'description') } ),
      'parent_id': new FormControl<number | null>({ value: null, disabled: PermissionHelper.disabledByProperty(this.permission, 'parent_id') } ),
      'public': new FormControl<boolean>({ value: true, disabled: PermissionHelper.disabledByProperty(this.permission, 'public') } ),
      'accounts': new FormControl<number[]>({ value: [], disabled: PermissionHelper.disabledByProperty(this.permission, 'accounts') } ),
    })
  }

  onBeforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    //this.handleUpload();

    return false;
  };

  accountSelectOpenChange(open: boolean) {
    if(open && !this.accounts?.length) this.getAccounts();
  }

  getAccounts() {
    this.accountsLoading = true;
    if(this.accountService.accounts.length > 0) {
      this.accounts = this.accountService.accounts;
      this.accountsLoading = false;
      return;
    }
    this.accountService.getAccounts()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.accounts = response.data.map((row: any) => new Account(row, response.included));
          this.accountService.accounts = this.accounts;
          this.accountsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.accountsLoading = false;
        },
        complete: () => {

        },
      })
  }

  private prepareCreatePayload(): FlowCategoryCreateDto {
    const data: FlowCategoryCreateData = {
      attributes: {
        title: this.form.get('title')?.value,
        description: this.form.get('description')?.value,
        public: this.form.get('public')?.value,
        parent_id: this.form.get('parent_id')?.value ?? null,
      },
      relationships: {},
      type: 'flow_categories'
    }
    if(this.upload?.id){
      data.relationships.uploads = {
        data: [{
          id: this.upload.id,
          type: "uploads"
        }]
      }
    }
    const accountSelection = this.form.get('accounts')?.value.map((accountId: number) => {
      return {
        id: accountId,
        type: 'accounts'
      }
    }) ?? [];

    if(accountSelection.length > 0) {
      data.relationships.accounts = {
        data: accountSelection
      }
    }

    return new FlowCategoryCreateDto(data);
  }

  private handleUpload() {
    if (this.fileList.length) {
      this.isUploading = true;
      const payload = this.prepareUploadPayload();

      this.uploadService.create(payload).pipe(take(1))
        .subscribe({
          next: (response: any) => {
            this.fileList = [];
            this.msg.success('Image was uploaded successfully', { nzDuration: 5000, nzPauseOnHover: true });
            this.upload = new Upload(response.data, response.included);
            this.router.navigate(['/app/flow-categories/'+this.createdFlowCategoryId]).then( _ => {});
          },
          error: () => {
            this.msg.success('There was an error, please try again', { nzDuration: 5000, nzPauseOnHover: true });
            this.isUploading = false;
          },
          complete: () => {
            this.isUploading = false;
          }
        });
    }
  }

  private prepareUploadPayload(){
    const payload = new FormData();

    payload.append('used_for', 'featured_image_url');
    payload.append('uploadable_type', 'Ticketing::FlowCategory');
    payload.append('uploadable_id', this.createdFlowCategoryId.toString());

    // this WILL NOT work: formData.append('uploaded_file', this.fileList[0]);
    this.fileList.forEach((file: any) => {
      payload.append('uploaded_file', file);
    });

    return payload;
  }

  private getFlowCategories() {
    this.flowCategoriesLoading = true;
    this.flowService.getFlowCategories()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next:(response: any) => {
        this.flowCategories = response.data.map( (row: any) => {
          return new FlowCategory(row, response.included);
        });
        this.flowCategoriesLoading = true;
      },
      error: (error) => {
        console.log(error);
        this.flowCategoriesLoading = false;
      }
    });
  }
}
