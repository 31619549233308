import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ExtraEmailsService } from 'src/app/services/extraEmails.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UsersService } from 'src/app/services/users.service';
import { ExtraEmailCreateDto } from 'src/app/utilities/models/dto/extraEmailCreateDto';
import { ExtraEmailUpdateDto } from 'src/app/utilities/models/dto/extraEmailUpdateDto';
import { ExtraEmail } from 'src/app/utilities/models/extraEmail/extraEmail';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { User } from 'src/app/utilities/models/user/user';

@Component({
    selector: 'extra-emails',
    templateUrl: './extra-emails.component.html',
    styleUrl: './extra-emails.component.scss',
    standalone: false
})
export class ExtraEmailsComponent implements OnInit, OnChanges {
  @Input({ required: true }) user: User;
  @Output() extraEmailsUpdated = new EventEmitter<boolean>();

  extraEmails: ExtraEmail[];
  hasCreatePermission: Permission | undefined;
  hasDeletePermission: Permission | undefined;
  hasUpdatePermission: Permission | undefined;
  hasSetPrimaryPermission: Permission | undefined;
  isAddingEmail: boolean = false;
  newEmail: string = '';
  loggedInUser: User;

  constructor(private destroyRef: DestroyRef,
              private message: NzMessageService,
              private extraEmailsService: ExtraEmailsService,
              private loaderService: LoaderService,
              private userService: UsersService) {}

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    this.getPermissions();
  }

  ngOnChanges() {
    this.extraEmails = this.user.relationships?.extra_emails?.map((item: any) => new ExtraEmail(item, [])) ?? [];
  }

  onCancelNewEmail() {
    this.isAddingEmail = false;
    this.newEmail = '';
  }

  onDeletePrimaryEmail(extraEmailId: number | string) {
    if (!extraEmailId) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Deleting email address…');
    this.loaderService.setLoadedText('The extra email address was deleted!');
    this.extraEmailsService.delete(extraEmailId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.loaderService.setProcessing(false);
          this.extraEmailsUpdated.emit(true);

          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
          }, 3000);
        },
        error: (error) => {
          this.loaderService.setLoaderVisible(false);
          this.message.error(error?.error?.errors[0]?.detail, { nzDuration: 4000, nzPauseOnHover: false });
          console.log(error);
        }
      })
  }

  onSaveNewEmail() {
    if (this.isEmailValid()) {
      this.loaderService.setProcessing(true);
      this.loaderService.setLoaderVisible(true);
      this.loaderService.setLoadingText('Adding extra email address…');
      this.loaderService.setLoadedText('An extra email address was added!');

      const payload = this.prepareCreatePayload();
      this.extraEmailsService.create(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.loaderService.setProcessing(false);

            if (response?.data?.id) {
              this.isAddingEmail = false;
              this.newEmail = '';
              this.extraEmailsUpdated.emit(true);

              setTimeout(() => {
                this.loaderService.setLoaderVisible(false);
              }, 3000);
            }
          },
          error: (error) => {
            this.loaderService.setLoaderVisible(false);
            this.message.error(error?.error?.errors[0]?.detail, { nzDuration: 4000, nzPauseOnHover: false });
            console.log(error);
          }
        })
    }
  }

  onSetPrimaryEmail(extraEmailId: number | string) {
    if (!extraEmailId) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Updating user…');
    this.loaderService.setLoadedText('The user was updated!');

    this.userService.setPrimaryEmail(this.user.id, extraEmailId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.loaderService.setProcessing(false);
          this.extraEmailsUpdated.emit(true);

          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
          }, 3000);
        },
        error: (error) => {
          this.loaderService.setLoaderVisible(false);
          this.message.error(error?.error?.errors[0]?.detail, { nzDuration: 4000, nzPauseOnHover: false });
          console.log(error);
        }
      })
  }

  onUpdateExtraEmail(extraEmail: ExtraEmail) {
    if (!extraEmail) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Updating user…');
    this.loaderService.setLoadedText('The user was updated!');

    const payload = this.prepareUpdatePayload(extraEmail.attributes.email);
    this.extraEmailsService.update(extraEmail.id, payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.loaderService.setProcessing(false);
          this.extraEmailsUpdated.emit(true);

          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
          }, 3000);
        },
        error: (error) => {
          this.loaderService.setLoaderVisible(false);
          this.message.error(error?.error?.errors[0]?.detail, { nzDuration: 4000, nzPauseOnHover: false });
          console.log(error);
        }
      })
  }

  private getPermissions() {
    this.hasCreatePermission = this.userService.findPermission(
      'Digcore::ExtraEmail',
      'ticketing/operator/v1/extra_emails',
      'create'
    );

    this.hasDeletePermission = this.userService.findPermission(
      'Digcore::ExtraEmail',
      'ticketing/operator/v1/extra_emails',
      'destroy'
    );

    this.hasUpdatePermission = this.userService.findPermission(
      'Digcore::ExtraEmail',
      'ticketing/operator/v1/extra_emails',
      'update'
    );

    this.hasSetPrimaryPermission = this.userService.findPermission(
      'Digcore::User',
      'ticketing/operator/v1/users',
      'swap_email'
    );
  }

  private isEmailValid(): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(this.newEmail.trim());
  }

  private prepareCreatePayload(): ExtraEmailCreateDto {
    return new ExtraEmailCreateDto(this.newEmail.trim(), this.user.id);
  }

  private prepareUpdatePayload(email: string): ExtraEmailUpdateDto {
    return new ExtraEmailUpdateDto(email.trim());
  }
}
