import { Component } from '@angular/core';

@Component({
    selector: 'instantSupport',
    templateUrl: './instantSupport.component.html',
    styleUrls: ['./instantSupport.component.scss'],
    standalone: false
})
export class InstantSupport {

}
