import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccountsService } from 'src/app/services/accounts.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UsersService } from 'src/app/services/users.service';
import { UserCreateDto } from 'src/app/utilities/models/dto/userCreateDto';

interface IFormField {
  label: string;
  name: string;
  required: boolean;
  type: 'number' | 'phone' | 'selector' | 'text';
  hasRequestedOptions?: boolean;
  multiple?: boolean;
  options?: {
    label: string;
    value: number | string;
  }[];
  value?: any;
}

@Component({
    selector: 'app-user-create',
    templateUrl: './people-create.component.html',
    styleUrl: './people-create.component.scss',
    standalone: false
})
export class PeopleCreateComponent implements OnInit {
  fields: IFormField[] = [
    {
      label: 'Account',
      name: 'account',
      options: [],
      hasRequestedOptions: false,
      required: true,
      type: 'selector',
    },
    {
      label: 'First name',
      name: 'firstname',
      required: true,
      type: 'text',
    },
    {
      label: 'Last name',
      name: 'lastname',
      required: true,
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      required: true,
      type: 'text',
    },
    {
      label: 'Job title',
      name: 'job_title',
      required: false,
      type: 'text',
    },
    {
      label: 'Type',
      name: 'price_tier',
      options: [],
      hasRequestedOptions: false,
      required: true,
      type: 'selector',
    },
    {
      label: 'Office phone',
      name: 'office_phone',
      required: false,
      type: 'phone',
      value: '',
    },
    {
      label: 'Mobile phone',
      name: 'mobile_phone',
      required: false,
      type: 'phone',
      value: '',
    }
  ];
  formGroup: FormGroup = new FormGroup({});
  isLoaderVisible: boolean = false;
  isLoadingOptions: string | undefined;

  constructor(private destroyRef: DestroyRef,
              private router: Router,
              private message: NzMessageService,
              private accountsService: AccountsService,
              private loaderService: LoaderService,
              private usersService: UsersService) {}

  ngOnInit() {
    this.observeLoaderService();
    this.initForm();
    this.getAccounts();
  }

  onSelectOpenChange(visibility: boolean, field: string) {
    if (!visibility) return;

    const formField = this.fields.find((f) => f.name == field);

    if (formField?.hasRequestedOptions) return;

    if (field === 'account') {
      this.getAccounts();
    }

    if (field === 'price_tier') {
      this.getPriceTiers();
    }
  }

  onSetPhoneNumber(phone: string | null, field: string) {
    this.formGroup.get(field)?.setValue(phone, { emitEvent: false });
  }

  onSubmit() {
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('A new user is being created.');
    this.loaderService.setLoadedText('The user was created!');

    const payload: UserCreateDto = this.prepareUserPayload();
    this.usersService.createUser(payload)
    .subscribe({
      next: (response: any) => {
        this.formGroup.reset();
        this.loaderService.setProcessing(false);

          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);

            if (response.data.id) {
              this.router.navigate(['/app', 'people', response.data.id]).then();
            }
          }, 3000);
        },
        error: (error) => {
          this.loaderService.setLoaderVisible(false);
          this.message.error(error?.error?.errors[0]?.detail, { nzDuration: 4000, nzPauseOnHover: false });
        }
      });
  }

  private getAccounts() {
    this.isLoadingOptions = 'account';
    this.accountsService.getAccounts()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          const accountsField = this.fields.find((field) => field.name == 'account');
          const formField = this.formGroup.get('account');

          if (accountsField && formField) {
            accountsField.hasRequestedOptions = true;
            accountsField.options = response.data?.map((row: any) => {
              return {
                label: row.attributes.title,
                value: row.id,
              }
            }) ?? [];

            if (accountsField?.options?.length == 1) {
              formField.setValue(accountsField.options[0].value, { emitEvent: false });
            }
          }

          this.isLoadingOptions = undefined;
        },
        error: (error) => {
          this.isLoadingOptions = undefined;
          console.log(error);
        },
      });
  }

  private getPriceTiers() {
    this.isLoadingOptions = 'price_tier';
    this.usersService.getPriceTiers()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          const priceTiersField = this.fields.find((field) => field.name == 'price_tier');
          const formField = this.formGroup.get('price_tier');

          if (priceTiersField && formField) {
            priceTiersField.hasRequestedOptions = true;
            priceTiersField.options = response.data?.map((row: any) => {
              return {
                label: row.attributes.title,
                value: row.id,
              }
            }) ?? [];

            if (priceTiersField?.options?.length) {
              const defaultValue = response.data.find((opt: any) => opt.attributes.default);

              if (defaultValue) {
                formField.setValue(defaultValue.id, { emitEvent: false });
              }
            }
          }

          this.isLoadingOptions = undefined;
        },
        error: (error) => {
          this.isLoadingOptions = undefined;
          console.log(error);
        },
      });
  }

  private initForm() {
    this.fields?.forEach((field: IFormField) => {
      let initialValue: any = '';

      switch (field.type) {
        case 'number': initialValue = 1; break;
        case 'phone':
        case 'text': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      if (field.name == 'email') {
        formControl.addValidators(Validators.email);
      }

      this.formGroup.addControl(field.name, formControl);
    });

    this.formGroup.updateValueAndValidity();
  }

  private observeLoaderService() {
    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });
  }

  private prepareUserPayload(): UserCreateDto {
    return new UserCreateDto(this.formGroup.value);
  }
}
