import { Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ServicesService } from "src/app/services/services.service";
import { ActivatedRoute, Params } from "@angular/router";
import Service from "src/app/utilities/models/service/service";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { AdditionalServiceMetricComponent } from "../additional-service-metric/additional-service-metric.component";

@Component({
    selector: 'app-manual-service-metric-list',
    templateUrl: './additional-service-metric-list.component.html',
    styleUrls: ['./additional-service-metric-list.component.scss'],
    standalone: false
})
export class AdditionalServiceMetricListComponent implements OnInit {
  @Output() fetchDetailsEvent = new EventEmitter<boolean>();

  loading: boolean = false;
  serviceId: number;
  service: Service | undefined;
  serviceMetrics: ServiceMetric[] = [];
  pageIndex: number = 1;
  pageSize: number = 20;
  total: number = 0;
  totalPages: number = 0;
  id: number = 0;
  serviceMetricModal: NzModalRef;

  constructor(private serviceService: ServicesService,
              private activatedRoute: ActivatedRoute,
              private destroyRef: DestroyRef,
              private modal: NzModalService) {}


  ngOnInit() {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        if(params['id'] > 0){
          this.id = params['id'];
          this.getService(params['id']);
        }
      }
    });
  }

  removeServiceMetric(serviceMetricId: number) {
    this.serviceService.removeServiceMetric(serviceMetricId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(_response: any) => {
          //this.getService(this.id);
          this.service = this.serviceService.additionalService$.getValue();
          if(this.service) {
            this.setServiceMetrics();
            this.serviceService.setAdditionalService(this.service);
          }
        },
        error: () => {}
      })
  }

  openServiceMetricCreateModal() {
    this.serviceMetricModal = this.modal.create({
      nzClassName: 'service-metric-modal',
      nzContent: AdditionalServiceMetricComponent,
      nzFooter: null,
      nzCentered: true,
      nzWidth: '640px',
      nzData: {
        service: this.service
      },
      nzOnOk: () => this.fetchDetailsEvent.emit(true)
    });
  }

  openServiceMetricEditModal(serviceMetricId: number) {
    this.serviceMetricModal = this.modal.create({
      nzClassName: 'service-metric-modal',
      nzContent: AdditionalServiceMetricComponent,
      nzFooter: null,
      nzCentered: true,
      nzWidth: '640px',
      nzData: {
        service: this.service,
        serviceMetricId: serviceMetricId
      },
      nzOnOk: () => this.fetchDetailsEvent.emit(true)
    });
  }

  private getService(id: number) {
    this.serviceService.getService(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.service = new Service(response.data, response.included);
          this.setServiceMetrics();
          this.serviceService.setAdditionalService(this.service);
        },
        error: () => {}
      })
  }

  private setServiceMetrics() {
    this.serviceMetrics = this.service?.relationships?.service_metrics?.map((serviceMetric: any) => new ServiceMetric(serviceMetric, [])) ?? [];
  }
}
