import { Component, DestroyRef, OnInit } from '@angular/core';
import InstalledService from "src/app/utilities/models/service/installedService";
import { ServicesService } from "src/app/services/services.service";
import { UsersService } from "src/app/services/users.service";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import Service from "src/app/utilities/models/service/service";
import ServiceMetric from "src/app/utilities/models/service/serviceMetric";
import { AssetResourcesService } from "src/app/services/assetResources.service";
import { DattoLicensesService } from "src/app/services/dattoLicenses.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { InstalledServiceDetailsCount } from "src/app/utilities/models/service/InstalledServiceDetailsCount";
import InstalledServiceDetail from "src/app/utilities/models/service/installedServiceDetail";
import { forkJoin } from "rxjs";
import { Account } from 'src/app/utilities/models/account/account';
import { User } from 'src/app/utilities/models/user/user';
import { LoaderService } from "src/app/services/loader.service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { ChangeRequestCreateDto } from 'src/app/utilities/models/dto/changeRequestCreateDto';

interface InstalledServiceViewData {
  id: number;
  logo: string;
  title: string;
  description: string;
  detailMetrics: { id: number; title: string; count: number; }[];
  hasInstalledServiceDetails: boolean;
  visible: boolean;
  routePath: any[];
  account?: Account;
}

@Component({
    selector: 'app-installed-services',
    templateUrl: './installedService.component.html',
    styleUrls: ['./installedService.component.scss'],
    standalone: false
})
export class InstalledServiceComponent implements OnInit{
  services: Service[];
  serviceMetrics: ServiceMetric[];
  installedServices: InstalledService[] = [];
  loading: boolean = false;
  managedUsersCount: number = 0;
  managedEndpointsCount: number = 0;
  dattoLicensesCount: number = 0;
  managedUsersService: Service;
  managedEndpointsService: Service;
  saasProtectionService: Service;
  installedServiceDetailCounts: InstalledServiceDetailsCount[];
  staticInstalledServiceDetails: InstalledServiceDetail[];
  installedServicesViewData: InstalledServiceViewData[] = [];
  addOnServices: Service[];
  loaders: {[key:string]: boolean} = {
    "managedUsers": false,
    "managedEndpoints": false,
    "saasProtection": false ,
    "installedServices": false,
  };
  saasProtectionIsEnabled: boolean = true;
  staticServiceIds: number[] = [];
  user: User;
  shouldShowAccountInList: boolean = false;
  shouldAddAccountInRequests: boolean = false;
  selectedTabIndex: number = 0;
  loaderVisible: boolean = false;
  requestChangeForm: FormGroup;
  permission: Permission | undefined;
  editorOptions = {
    heightMin: 200,
    heightMax: 500,
    placeholderText: ''
  };

  constructor(private serviceService: ServicesService,
              private userService: UsersService,
              private assetResourceService: AssetResourcesService,
              private dattoLicenseService: DattoLicensesService,
              private destroyRef: DestroyRef,
              private loaderService: LoaderService) {}

  ngOnInit(){
    this.user = this.userService.loggedInUser;
    if (this.user) {
      this.shouldShowAccountInList = ['account_agent_role','account_admin_contact_role'].includes(this.user.relationships?.role?.attributes?.system_key ?? '') ?? false;
      this.shouldAddAccountInRequests = this.user.relationships?.role?.attributes?.user_type == 'Ticketing::Agent';
    }

    this.permission = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');

    this.requestChangeForm = this.getFormGroup();
    this.setForm();

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
        }
      });

    this.serviceService.services$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (services: Service[]) => {
          if(services.length == 0) {
            this.getServices();
          }else{
            this.services = services;
            this.setStaticServices();
            this.getServicesData();
          }
        }
      });

    this.serviceService.serviceMetrics$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (serviceMetrics: ServiceMetric[]) => {
          this.serviceMetrics = serviceMetrics;
        }
      });
  }

  get requestsLoading() {
    return this.loaders['managedUsers'] ||
           this.loaders['managedEndpoints'] ||
           this.loaders['saasProtection'] ||
           this.loaders['installedServices'];
  }

  getServices() {
    this.loading = true;
    this.serviceService.getServices(1000,1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          const services = response.data.map((bsData: any) => new Service(bsData, response.included));
          this.serviceService.setServices(services);
          const serviceMetrics = response.included?.filter((includedObj: any) => includedObj.type == 'service_metrics')
            .map((metric: any) => new ServiceMetric(metric, response.included));
          this.serviceService.setServiceMetrics(serviceMetrics);
          this.setStaticServices();
        },
        error: (_error) => {},
        complete: () => {
          this.loading = false;
        }
      })
  }

  getServicesData() {
    const accountId = this.userService.loggedInUser.relationships?.account?.id ? +this.userService.loggedInUser.relationships.account.id : undefined;
    if(accountId) {
      forkJoin([
        this.getManagedUsers(accountId),
        this.getManagedEndpoints(accountId),
        this.getDattoLicensesCount(accountId),
        this.getInstalledServices(accountId)
      ])
        .pipe()
        .subscribe({
          next: ([managedUsersResponse, managedEndpointsResponse, dattoLicensesResponse, installdeServicesResponse]: [any, any, any, any]) => {
            if(managedUsersResponse?.data?.length) {
              this.managedUsersCount = managedUsersResponse.data[0].attributes.count;
            }
            if(managedEndpointsResponse?.data?.length) {
              this.managedEndpointsCount = managedEndpointsResponse.data[0].attributes.count;
            }
            if(dattoLicensesResponse?.data?.length) {
              this.dattoLicensesCount = dattoLicensesResponse.meta.total_count ?? 0;
            }
            this.handleInstalledServicesResponse(installdeServicesResponse);
          },
          error: (error) => { console.log(error); },
          complete: () => {
            this.loaders['managedUsers'] = false;
            this.loaders['managedEndpoints'] = false;
            this.loaders['saasProtection'] = false;
          },
        })
    }
  }

  setStaticServices() {
    this.staticServiceIds = [];
    const managedUsersService = this.services.find(service => service.attributes.system_key == 'digcore-users')
    if(managedUsersService) {
      this.managedUsersService = managedUsersService;
      this.staticServiceIds.push(managedUsersService.id);
    }
    const managedEndpointsService = this.services.find(service => service.attributes.system_key == 'ticketing-assets')
    if(managedEndpointsService) {
      this.managedEndpointsService = managedEndpointsService;
      this.staticServiceIds.push(managedEndpointsService.id);
    }
    const saasProtectionService = this.services.find(service => service.attributes.system_key == 'digcore-datto-license')
    if(saasProtectionService) {
      this.saasProtectionService = saasProtectionService;
      this.staticServiceIds.push(saasProtectionService.id);
    }
  }

  getInstalledServices(accountId: number) {
    this.loaders['installedServices'] = true;
    if(this.shouldAddAccountInRequests) {
      return this.serviceService.getInstalledServices(accountId);
    }

    return this.serviceService.getInstalledServices();
  }

  handleInstalledServicesResponse(response: any): void {
    this.installedServices = response.data.map((bsData: any) => new InstalledService(bsData, response.included));
    this.getInstalledServiceDetailsCount();

    const ids: number[] = [];
    this.installedServices.forEach((is: InstalledService) => {
      if(is.relationships?.service?.id) {
        ids.push(is.relationships?.service?.id)
      }
    });
    this.addOnServices = this.services.filter((service: Service) => {
      if(service.attributes.system_key && this.serviceService.staticServiceKeys.includes(service.attributes.system_key)) {
        switch(service.attributes.system_key){
          case 'digcore-users':
            return this.managedUsersCount == 0;
          case 'ticketing-asset':
            return this.managedEndpointsCount == 0;
          case 'digcore-datto-license':
            return this.dattoLicensesCount == 0;
        }
      }

      return !ids.includes(service.id) && !service.attributes.system_key;
    });
  }

  getInstalledServiceDetailsCount() {
    const installedServiceIds: number[] = this.installedServices
      .filter((installedService: any) => installedService.attributes.external_identifier)
      .map((installedService: any) => installedService.id) ?? [];
    const staticInstalledServiceIds: number[] = this.installedServices
      .filter((installedService: any) => !installedService.attributes.external_identifier)
      .map((installedService: any) => installedService.id) ?? [];
    const serviceMetricIds: number[] = [];
    this.services?.forEach((service: Service) => {
      service.relationships?.service_metrics?.forEach((service_metric: any) => {
        serviceMetricIds.push(service_metric.id);
      });
    });

    const staticServiceMetricIds: number[] = [];
    this.services?.filter((service: Service) => !service.attributes.external_identifier)
      .forEach((service: Service) => {
        service.relationships?.service_metrics?.forEach((service_metric: any) => {
          staticServiceMetricIds.push(service_metric.id);
        });
      });


    const requests = [
      this.serviceService.getInstalledServiceDetailsCount(installedServiceIds, serviceMetricIds),
      this.serviceService.getAllInstalledServiceDetails(staticInstalledServiceIds, staticServiceMetricIds)
    ];
    forkJoin(requests)
      .subscribe({
        next: (result: any) => {
          if(result[0]){
            this.installedServiceDetailCounts = result[0].data[0]?.attributes.data.map((row: InstalledServiceDetailsCount) => row);
          }
          if(result[1]){
            if(result[1].data.length > 0){
              this.staticInstalledServiceDetails = result[1].data.map((row: any) => new InstalledServiceDetail(row, result[1].included));
            }
          }
        },
        error: () => {
        },
        complete: () => {
          this.loaders['installedServices'] = false;
          this.setSortedInstalledServices();
        }
      })
  }

  getCountForInstalledService(installedService: InstalledService, serviceMetric: ServiceMetric): number {
    return this.installedServiceDetailCounts?.find((installedServiceDetailsCount: InstalledServiceDetailsCount) => {
      return (installedService.id == installedServiceDetailsCount.installed_service_id) && (serviceMetric.id == installedServiceDetailsCount.service_metric_id)
    })?.count ?? 0;
  }

  getCountFromInstalledServiceDetail(installedService: InstalledService, serviceMetric: ServiceMetric) {
    return this.staticInstalledServiceDetails?.find(item => item.relationships?.installed_service?.id == installedService.id && item.relationships?.service_metric?.id == serviceMetric.id)?.attributes.col1 ?? 0;
  }

  getManagedUsers(accountId?: number) {
    this.loaders['managedUsers'] = true;
    let reportArr: ReportParam[] = [];

    if(this.shouldAddAccountInRequests && accountId) {
      reportArr.push({"key":"accounts.id","operator": ["eq"],"value":[accountId]});
    }
    reportArr.push({"key":"active","operator":["eq"],"value":"true"});

    return this.userService.getAccountUsersDocumentCount(reportArr);
  }

  getManagedEndpoints(accountId?: number) {
    this.loaders['managedEndpoints'] = true;
    let reportArr: ReportParam[] = [];
    if(this.shouldAddAccountInRequests && accountId) {
      reportArr.push({"key":"accounts.id","operator": ["eq"],"value":[accountId]});
    }
    reportArr.push({"key":"kaseya_detail.external_identifier","operator":["exist"],"value":["-"]});
    //reportArr.push({"key":"active","operator":["eq"],"value":"true"});

    return this.assetResourceService.getAssetResourcesDocumentCount(reportArr);
  }

  getDattoLicensesCount(accountId?: number) {
    this.loaders['saasProtection'] = true;
    if(this.shouldAddAccountInRequests && accountId) {
      return this.dattoLicenseService.getDattoLicensesCount(accountId);
    }

    return this.dattoLicenseService.getDattoLicensesCount();
  }

  getCountMetrics(installedService: InstalledService): ServiceMetric[] {
    const service = this.getServiceFromInstalledService(installedService);

    return service ? this.serviceService.getCountMetrics(service.id) : [];
  }

  getDetailMetrics(installedService: InstalledService): ServiceMetric[] {
    const service = this.getServiceFromInstalledService(installedService);

    return service ? this.serviceService.getDetailsMetrics(service.id) : [];
  }

  getFirstDetailsMetrics(serviceId: number | undefined, installedService: InstalledService | undefined) {
    return this.serviceService.getFirstDetailsMetrics(serviceId, installedService);
  }

  getTitleFromInstalledService(installedService: InstalledService): string {
    const service = this.getServiceFromInstalledService(installedService);

    return service ? service.getTitle() : '';
  }

  getServiceDescriptionFromInstalledService(installedService: InstalledService): string {
    const service = this.getServiceFromInstalledService(installedService);

    return service ? service.getDescription() : '';
  }

  getLogoByService(service: Service): string{
    const logo = service ? service.getLogo() : '';

    return logo ? logo?.includes('https://') ? logo : '../../../' + logo : '';
  }

  getLogoByInstalledService(installedService: InstalledService) {
    const service = this.getServiceFromInstalledService(installedService);

    return service ? service.getLogo() : '';
  }

  getServiceFromInstalledService(installedService: InstalledService) {
    const serviceId = installedService.relationships?.service?.id;
    if(serviceId) {
      return this.services.find((s) => s.id == serviceId);
    }
    return undefined;
  }

  serviceHasDetails(installedService: InstalledService) {
    const service = this.getServiceFromInstalledService(installedService);

    return (service?.attributes.external_identifier || (service && this.staticServiceIds.includes(service.id)));
  }

  shouldShowInstalledService(installedService: InstalledService) {
    let showInstalledService = false;
    const metrics = this.getDetailMetrics(installedService);
    if(this.serviceHasDetails(installedService)){
      metrics.forEach(metric => {
        if(this.getCountForInstalledService(installedService, metric) > 0){
          showInstalledService = true;
        }
      })
    }else{
      metrics.forEach(metric => {
        if(this.getCountFromInstalledServiceDetail(installedService, metric) > 0){
          showInstalledService = true;
        }
      })
    }

    return showInstalledService;
  }

  onSubmit(){
    if(!this.requestChangeForm.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Your ticket is being created.');
    this.loaderService.setLoadingSecondaryText('');
    this.loaderService.setLoadedText('Your ticket was created!');
    this.loaderService.setLoadedSecondaryText('');

    const payload: ChangeRequestCreateDto = this.prepareChangeRequestPayload();
    this.serviceService.changeRequest(payload).subscribe({
      next: (response: any) => {
        this.requestChangeForm.reset();
        this.loaderService.setProcessing(false);
        if(response.data.id){
          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);
          }, 2000)
        }
      }
    });
  }

  private disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }

  private getFormGroup(): FormGroup {
    return new FormGroup({
      'subject': new FormControl<string>({ value: '', disabled: this.disableByProperty('subject') }, Validators.required),
      'description': new FormControl<string>({ value: '', disabled: this.disableByProperty('description') }, Validators.required),
      'account': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('account_id') }, Validators.required),
      'requester': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('requester_id') }, Validators.required),
    });
  }

  private setForm(){
    this.requestChangeForm.get('subject')?.setValue('Change Request');
    const requesterId = this.user?.id;
    this.requestChangeForm.get('requester')?.setValue(requesterId);
    if(this.user?.relationships?.account){
      const accountId = this.user.relationships.account.id;
      this.requestChangeForm.get('account')?.setValue(accountId);
    }
  }

  private getRoutePath(installedService: InstalledService) {
    const serviceId:number = installedService.relationships?.service?.id ?? 0;
    const service = this.services.find((service: Service) => service.id == serviceId);
    if(service?.attributes.system_key || (service && this.staticServiceIds.includes(service.id))){
      return ['/app','services', installedService.relationships?.service?.id, installedService.id, 'details', this.getFirstDetailsMetrics(installedService.relationships?.service?.id, installedService)];
    }else{
      if(service && !this.staticServiceIds.includes(service.id)){
        const metric = service.relationships?.service_metrics?.find(service_metric => service_metric.attributes.metric_type == 'detail');
        if(metric)
          return ['/app','services', installedService.relationships?.service?.id, installedService.id, 'details', metric.id];
        else
          return ['/app','services']
      }
    }


    return ['/app','services']
  }

  private setSortedInstalledServices() {
    const sortedServices: InstalledServiceViewData[] = this.installedServices.map((installedService: InstalledService) => {
      const detailMetrics = this.getDetailMetrics(installedService);
      const installedServiceData: InstalledServiceViewData = {
        id: installedService.id,
        logo: this.getLogoByInstalledService(installedService) ?? '',
        title: this.getTitleFromInstalledService(installedService) ?? '',
        description: this.getServiceDescriptionFromInstalledService(installedService) ?? '',
        detailMetrics: detailMetrics.map(metric => {
          const count = this.serviceHasDetails(installedService) ? this.getCountForInstalledService(installedService, metric) : this.getCountFromInstalledServiceDetail(installedService, metric);
          return {
            id: metric.id,
            title: metric.getTitle(),
            count: count ?? 0
          }
        }) ?? [],
        hasInstalledServiceDetails: !!this.serviceHasDetails(installedService),
        visible: true, // this.shouldShowInstalledService(installedService) ?? true,
        routePath: this.getRoutePath(installedService)
      }
      if(installedService.relationships?.account){
        installedServiceData.account = installedService.relationships.account
      }

      return installedServiceData;
    });

    if(this.saasProtectionIsEnabled && this.saasProtectionService && this.dattoLicensesCount > 0){
      const metrics = this.saasProtectionService.relationships?.service_metrics?.map(metric => {
        return { id: metric.id, title: metric.attributes.title, count: this.dattoLicensesCount }
      }) ?? [];
      const saasProtectionViewData: InstalledServiceViewData = {
        id: this.saasProtectionService.id,
        logo:this.getLogoByService(this.saasProtectionService),
        title: this.saasProtectionService.getTitle(),
        description:this.saasProtectionService.getDescription(),
        detailMetrics: metrics,
        hasInstalledServiceDetails: true,
        visible: true,
        routePath: ['/app','services', this.saasProtectionService.id, 0, 'details', metrics[0].id]
      }
      sortedServices.push(saasProtectionViewData);
    }else{
      if(this.saasProtectionIsEnabled && this.saasProtectionService) {
        this.addOnServices.push(this.saasProtectionService);
        this.sortAddOnServices();
      }
    }

    if(this.managedUsersService && this.managedUsersCount > 0){
      const metrics = this.managedUsersService.relationships?.service_metrics?.map(metric => {
        return { id: metric.id, title: metric.attributes.title, count: this.managedUsersCount }
      }) ?? [];
      const managedEnpointsViewData: InstalledServiceViewData = {
        id: this.managedUsersService.id,
        logo:this.getLogoByService(this.managedUsersService),
        title: this.managedUsersService.getTitle(),
        description: this.managedUsersService.getDescription(),
        detailMetrics: metrics,
        hasInstalledServiceDetails: true,
        visible: true,
        routePath: ['/app','services', this.managedUsersService.id, 0, 'details', metrics[0].id]
      }
      sortedServices.push(managedEnpointsViewData);
    }

    if(this.managedEndpointsService && this.managedEndpointsCount > 0){
      const metrics = this.managedEndpointsService.relationships?.service_metrics?.map(metric => {
        return { id: metric.id, title: metric.attributes.title, count: this.managedEndpointsCount }
      }) ?? [];
      const managedEnpointsViewData: InstalledServiceViewData = {
        id: this.managedEndpointsService.id,
        logo:this.getLogoByService(this.managedEndpointsService),
        title: this.managedEndpointsService.getTitle(),
        description: this.managedEndpointsService.getDescription(),
        detailMetrics: metrics,
        hasInstalledServiceDetails: true,
        visible: true,
        routePath: ['/app','services', this.managedEndpointsService.id, 0, 'details', metrics[0].id]
      }
      sortedServices.push(managedEnpointsViewData);

    }else{
      if(this.managedEndpointsService) {
        this.addOnServices.push(this.managedEndpointsService);
        this.sortAddOnServices();
      }
    }

    this.installedServicesViewData = sortedServices.sort((a: InstalledServiceViewData, b: InstalledServiceViewData) => {
      return a.title > b.title ? 1 : -1;
    });
  }

  private sortAddOnServices() {
    this.addOnServices.sort((a: Service, b: Service) => {
      return a.attributes.title > b.attributes.title ? 1 : -1;
    });
  }

  private prepareChangeRequestPayload(): ChangeRequestCreateDto{
    const ticketCreateAttributes = {
      subject: this.requestChangeForm.get('subject')?.value,
      description: this.requestChangeForm.get('description')?.value
    }

    return new ChangeRequestCreateDto(ticketCreateAttributes);
  }
}
