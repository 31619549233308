import { Component, DestroyRef, OnInit } from "@angular/core";
import { User } from "src/app/utilities/models/user/user";
import { UsersService } from "src/app/services/users.service";
import { LoaderService } from "src/app/services/loader.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "custom-loader",
    templateUrl: "./customLoader.component.html",
    styleUrls: ['./customLoader.component.scss'],
    standalone: false
})
export class CustomLoaderComponent implements OnInit {
  loggedInUser: User;
  loaderVisible: boolean = false;
  processing: boolean;
  loadingText: string;
  loadingSecondaryText: string;
  loadedText: string;
  loadedSecondaryText: string;

  constructor(private userService: UsersService,
              private loaderService: LoaderService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;

    this.loadingText = this.loaderService.loadingText;
    this.loadingSecondaryText = this.loaderService.loadingSecondaryText;
    this.loadedText = this.loaderService.loadedText;
    this.loadedSecondaryText = this.loaderService.loadedSecondaryText;
    this.processing = this.loaderService.processing;

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (loaderVisible: boolean) => {
          this.loaderVisible = loaderVisible;
        }
      })

    this.loaderService.processingSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (processing: boolean) => {
          this.processing = processing;
        }
      })
  }

  hideLoader() {
    this.loaderService.setLoaderVisible(false);
  }
}
