import { Component, DestroyRef, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BehaviorSubject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { FlowsService } from 'src/app/services/flows.service';
import { QueryParam } from 'src/app/utilities/models/parameters/queryParam/queryParam';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';

@Component({
    selector: 'app-flow-category-list',
    templateUrl: './flow-category-list.component.html',
    styleUrls: ['./flow-category-list.component.scss'],
    standalone: false
})
export class FlowCategoryListComponent implements OnInit {
  flowCategories: FlowCategory[];
  loading:boolean = false;
  pageIndex: number = 1;
  pageSize: number = 20;
  total: number = 0;
  totalPages: number = 0;
  searchString: string = '';
  currentQueryParams: QueryParam[] = [];
  searchSubject$ = new BehaviorSubject<string>('');

  constructor(private flowService: FlowsService,
    private destroyRef: DestroyRef) {
  }

  ngOnInit(): void {
    this.setSearchSubject();
    this.getFlows();
  }

  getFlows(queryParams: QueryParam[] = [], pageSize: number = this.pageSize, pageIndex: number = 1, sortField: string = 'title', sortOrder: string = 'ascend') {
    this.loading = true;
    this.flowService.getFlowCategories(queryParams, pageSize, pageIndex, sortField, sortOrder, this.searchString)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          this.flowCategories = response.data.map((row: any) => new FlowCategory(row, response.included));
          this.total = response.meta.total_count;
        },
        error:(error) => {
          console.log(error);
          this.loading = false;
        },
        complete:() => {
          this.loading = false;
        },
      });
  }


  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.getFlows(this.currentQueryParams, this.pageSize, this.pageIndex);
    this.setSearchSubject();
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.getFlows(this.currentQueryParams, this.pageSize, this.pageIndex);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'title';
    const sortOrder = (currentSort && currentSort.value) || 'ascend';
    this.getFlows(this.currentQueryParams, params.pageSize, params.pageIndex, sortField, sortOrder);
  }

  searchFlows() {
    if(this.searchString.length == 0 || this.searchString.length > 2) {
      this.searchSubject$.next(this.searchString);
    }
  }

  private setSearchSubject() {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap(async() => this.getFlows(this.currentQueryParams, this.pageSize, 1, '', ''))
      )
      .subscribe({
        next: (_data) => { }
      })
  }
}
